// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import { I18nextProvider, withTranslation } from 'react-i18next';
import { uiChangeLanguage } from "../../components/src/HelperMethonds";
import i18n from '../../components/src/LanguagConfig';
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import { getStorageData } from "../../framework/src/Utilities";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import TaskAllocator from "../../blocks/TaskAllocator/src/TaskAllocator";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import OrganisationHierarchy from "../../blocks/OrganisationHierarchy/src/OrganisationHierarchy";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import RequestManagement from "../../blocks/RequestManagement/src/RequestManagement";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import Notes from "../../blocks/Notes/src/Notes";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Notifications from "../../blocks/notifications/src/Notifications";
import DynamicContent from "../../blocks/DynamicContent/src/DynamicContent";
import LinkShare from "../../blocks/LinkShare/src/LinkShare";
import AuditTrail from "../../blocks/AuditTrail/src/AuditTrail";
import MultilevelApproval from "../../blocks/MultilevelApproval/src/MultilevelApproval";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import { ProjectTaskTrackingWithStyles as ProjectTaskTracking } from "../../blocks/ProjectTaskTracking/src/ProjectTaskTracking";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import LanguageOptions from "../../blocks/LanguageOptions/src/LanguageOptions";
import TaskGroups from "../../blocks/TaskGroups/src/TaskGroups";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import CustomisedOrderStatus from "../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus";
import TaskList from "../../blocks/TaskList/src/TaskList";
import AccountLogin from "../../blocks/mobile-account-login/src/AccountLogin";
import AccountSignUp from "../../blocks/mobile-account-registration/src/AccountSignUp"
import MainLayout from "../../blocks/ProjectTaskTracking/src/MainLayout"
import Header from "../../blocks/landingpage/src/Header";

const routeMap = {
  TaskInviteLogin: {
    component: AccountLogin,
    path: "/AccountLogin/task/:taskId",
    exact: true,
    protected:'public'
  },
  InviteLogin: {
    component: AccountLogin,
    path: "/AccountLogin/:id",
    exact: true,
    protected:'public'
  },
  AccountLogin: {
    component: AccountLogin,
    path: "/AccountLogin",
    exact: true
  },
  ProjectTaskTrackingTaskID: {
    component: ProjectTaskTrackingWithMainLayout,
    path: "/ProjectTaskTracking/task/:taskId",
    protected: true,
    exact: true
  },
  ProjectTaskTrackingBoardID: {
    component: ProjectTaskTrackingWithMainLayout,
    path: "/ProjectTaskTracking/:id",
    protected: true,
    exact: true
  },
  ProjectTaskTracking: {
    component: ProjectTaskTrackingWithMainLayout,
    path: "/ProjectTaskTracking",
    protected: true,
    exact: true
  },
  AccountSignUp: {
    component: AccountSignUp,
    path: "/AccountSignUp"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  TaskAllocator: {
    component: taskAllocatorWithMainLayout,
    path: "/TaskAllocator",
    protected: true
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  OrganisationHierarchy: {
    component: OrganisationHierarchy,
    path: "/OrganisationHierarchy"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  RequestManagement: {
    component: RequestManagement,
    path: "/RequestManagement"
  },
  PeopleManagement2: {
    component: PeopleManagement2,
    path: "/PeopleManagement2"
  },
  Notes: {
    component: Notes,
    path: "/Notes"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  DynamicContent: {
    component: DynamicContent,
    path: "/DynamicContent"
  },
  LinkShare: {
    component: LinkShare,
    path: "/LinkShare"
  },
  AuditTrail: {
    component: AuditTrail,
    path: "/AuditTrail"
  },
  MultilevelApproval: {
    component: MultilevelApproval,
    path: "/MultilevelApproval"
  },
  Settings5: {
    component: Settings5,
    path: "/Settings5"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: "/RolesPermissions"
  },
  AdminConsole: {
    component: AdminConsole,
    path: "/AdminConsole"
  },
  LanguageOptions: {
    component: LanguageOptions,
    path: "/LanguageOptions"
  },
  LandingPage: {
    component: LandingPageWithHeader,
    path: "/",
    exact: true
  },
  CustomisedOrderStatus: {
    component: CustomisedOrderStatus,
    path: "/CustomisedOrderStatus"
  },
  // Home: {
  //   component: HomeScreen,
  //   path: '/',
  //   exact: true
  // },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfileHeader,
    path: "/CustomisableUserProfiles",
    protected: true
  },
  TaskList: {
    component: TaskListMainLayout,
    path: "/TaskList/:id",
    protected: true,
    exact:true
  },
  TaskGroups: {
    component: taskGroupsWithMainLayout,
    path: "/Reports/:boardName",
    protected: true
  },
};


function taskAllocatorWithMainLayout(props) {
  return (
    <MainLayout WrappedComponent={TaskAllocator} HeaderComponent={BoardHeader}  {...props} />
  );
};

function ProjectTaskTrackingWithMainLayout(props) {
  return (
    <MainLayout WrappedComponent={ProjectTaskTracking} HeaderComponent={BoardHeader} {...props} />
  );
};

function BoardHeader(props) {
  const WrappedComponent = () => <></>;
  return (
    <Header enableSidebarDrawer={true} enableDrawer={false} WrappedComponent={WrappedComponent} whiteBackground={false} hideSiteLogo={true} {...props} />
  );
}

function LandingPageWithHeader(props) {
  const WrappedComponent = () => <></>;

  return (
    <Header enableDrawer={true} WrappedComponent={LandingPage} whiteBackground={true} hideSiteLogo={false} {...props} />
  );
}

function CustomisableUserProfileHeader(props) {
  const WrappedComponent = () => <></>;
  return (
    <Header enableDrawer={true} WrappedComponent={CustomisableUserProfiles} whiteBackground={true} hideSiteLogo={false} {...props} />
  );
}

function taskGroupsWithMainLayout(props) {
  return (
    <MainLayout WrappedComponent={TaskGroups} HeaderComponent={BoardHeader} {...props} />
  );
};



function TaskListMainLayout(props) {
  return (
    <MainLayout WrappedComponent={TaskList} HeaderComponent={BoardHeader} {...props} />
  );
};



const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  async componentDidMount() {
    const { i18n } = this.props;
    const savedLanguage = await getStorageData('language');

    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
      uiChangeLanguage(savedLanguage)
    }
  }

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <I18nextProvider i18n={i18n}>
        <View style={{ height: '100vh', width: '100vw', overflowY: "auto" }}>
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </I18nextProvider>
    );
  }
}

export default withTranslation()(App);