Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TaskList";
exports.labelBodyText = "TaskList Body";

exports.multipleFormContentType = "multipart/form-data";
exports.deleteType = "DELETE";
exports.btnExampleTitle = "CLICK ME";



exports.delete = "Yes, Delete";
exports.no = "No";
exports.homePage = "Go to Homepage";

exports.task = "task";



exports.getAllTaskListEndpoint = "bx_block_tasks/task_lists";
exports.getBoardEndpoint = "bx_block_tasks/boards";
exports.deleteTaskEndpoint = "bx_block_tasks/tasks";
exports.postTaskListEndpoint = "bx_block_tasks/task_lists";
exports.patchTaskEndpoint = "bx_block_tasks/tasks";
exports.patchMethod = "PATCH";
exports.taskEndPoint = "bx_block_tasks/tasks";
exports.dueDateEndPoint = "change_due_date";
exports.completeTask = "complete_task";


exports.commentList = "bx_block_comments/comments?task_list_id=";
exports.addCommentEndPoint = "bx_block_comments/comments";
exports.commentableType = "BxBlockTasks::Task";
exports.commentText = "Please Select the task for which you wish to add the comment";
exports.viewTaskEndPoint = "bx_block_tasks/task_lists";
exports.comment = "Comment";

exports.emptyCommentValidationMessage = "Cannot add a comment as there is no available task."
exports.taskListPdfEndPoint = "bx_block_print2/prints/generate_task_list_pdf"
// Customizable Area End