import React from "react";

// Customizable Area Start
import {
    Box, Button, Typography, Avatar,
    Divider, ButtonBase, IconButton,
    Drawer
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom"
import { logo } from './assets'
import { withTranslation } from "react-i18next";
import { isArabicLang } from "../../../components/src/HelperMethonds";
// Customizable Area End

import MainLayoutController, {
    Props,
    configJSON
} from "./MainLayoutController";

export class MainLayout extends MainLayoutController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderSiderBar = (classes: any, container: any, t: Function) => {
        const { i18n } = this.props
        return (
            <Box className={container}>
                <Box className={classes.webSiteLogoContainer}>
                    <ButtonBase onClick={() => this.handleLogoNavigate('ProjectTaskTracking')} data-test-id="logoBtn" disableRipple>
                        <img src={logo} className={classes.logoImg} width={105} />
                    </ButtonBase>
                </Box>



                <Box className={classes.sidebarBodyContainer}>
                    <Box className={classes.sidebarSecOne}>
                        {this.state.isDrawerOpen && <Box width={"100%"} padding={"0 11.7%"} boxSizing={"border-box"}>
                            {this.state.headerLinksArray.map((HeaderLink: any, index: any) => (
                                <Typography
                                    color="textSecondary"
                                    style={{ cursor: "pointer" }}
                                    data-test-id="headerLink"
                                    onClick={() =>
                                        this.handleRedirectionNewTab(
                                            HeaderLink.attributes.page_url
                                        )
                                    }
                                    key={index}
                                    variant="subtitle1"
                                >
                                    {HeaderLink.attributes.name}
                                </Typography>
                            ))}
                        </Box>}
                        <Box className={classes.sidebarSubSecOne}>
                            <Typography style={{ color: "#fff", marginTop: "25px" }} variant="h6">
                                {t('yourWorkBoardTxt')}
                            </Typography>
                            <Box className={classes.profileContainer}>
                                <Box className={classes.profilelogo}>
                                    <Avatar src={this.state.userData.avatar} className={classes.sideBarProfileLogo} />
                                </Box>
                                <Box>
                                    <Typography style={{ wordBreak: "break-word", color: "#fff", }} variant="subtitle1">
                                        {this.state.userData.full_name}
                                    </Typography>
                                    <Typography variant="caption" style={{ color: "rgba(255, 255, 255, 0.73)" }}>
                                        {this.conditionSort(
                                            isArabicLang(this.props.i18n.language),
                                            `${this.state.userData.total_boards === 1 ? this.props.t('boardsTxt') : this.props.t('boardsTxtPlural')} ${this.state.userData.total_boards}`,
                                            `${this.state.userData.total_boards} ${this.state.userData.total_boards === 1 ? this.props.t('boardsTxt') : this.props.t('boardsTxtPlural')}`,
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider className={classes.dividerRage} />
                        </Box>

                        <Box className={classes.listContainer}>
                            <Box>
                                <Box className={classes.listRage}>
                                    <Typography style={{ color: "#fff" }} variant="subtitle1">{t('reportDropDownTxt')}</Typography>
                                    <ButtonBase data-test-id="reportExpandBtn" className={this.conditionSort(this.state.collapseReport, '', classes.rotateIcon)} disableRipple onClick={this.handleCollapseExpandForReport}>
                                        {SvgIcons.downIcon}
                                    </ButtonBase>
                                </Box>
                                {!this.state.collapseReport && <Box className={classes.ReportExpandList}>
                                    {(this.state.tasklistData.data.length > 0 ? (this.state.tasklistData.data.map((data: any) => (
                                        <Typography data-test-id="reportLinks" activeClassName={classes.RequestExpandAlignIconActive} to={`/Reports/${data.id}`} key={data.id} component={NavLink} className={classes.ExpandedListText} variant="caption" color="textSecondary">{data.attributes.name}</Typography>
                                    ))) : (<Typography color="textSecondary" className={classes.ExpandedListText} variant="caption">
                                        {this.state.tasklistData.message}
                                    </Typography>))
                                    }
                                </Box>}
                            </Box>
                            <Box>
                                <Box className={classes.listRage}>
                                    <Typography style={{ color: "#fff" }} variant="subtitle1">{t('reqDropDownTxt')}</Typography>
                                    <ButtonBase data-test-id="requestExpandBtn" className={this.conditionSort(this.state.collapseRequest, '', classes.rotateIcon)} disableRipple onClick={this.handleCollapseExpandForRequest}>
                                        {SvgIcons.downIcon}
                                    </ButtonBase>
                                </Box>
                                {!this.state.collapseRequest &&
                                    (this.state.boardReqData.length > 0 ? (
                                        this.state.boardReqData.map((data: any) => (
                                            <Box key={data.id} className={classes.RequestExpandList}>
                                                <Box
                                                    className={this.conditionSort(!this.state.collapseSpecificBoardReq[data.id], classes.RequestExpandAlignIconActive, classes.RequestExpandAlignIcon)}
                                                >
                                                    <Typography className={classes.fontRegular} variant="caption" color="textSecondary">
                                                        {data.attributes.name}
                                                    </Typography>
                                                    <IconButton
                                                        data-test-id="specificReqExpandBtn"
                                                        onClick={() => this.handleCollapseExpandForSpecificReq(data.id)}
                                                        className={this.conditionSort(this.state.collapseSpecificBoardReq[data.id], classes.downArrowIconRage, classes.upArrowIconRage)}
                                                    >
                                                        {SvgIcons.downArrowIconForReq}
                                                    </IconButton>
                                                </Box>
                                                {!this.state.collapseSpecificBoardReq[data.id] && data.attributes.board_members.map((requestData: any) => (
                                                    <Box key={requestData.board_membership_id} className={classes.requestContainer}>
                                                        <Box className={classes.requestBody}>
                                                            <Typography variant="body2">
                                                                {requestData.full_name}
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                {requestData.full_phone_number}
                                                            </Typography>
                                                        </Box>
                                                        <Box className={classes.requestBtnContainer}>
                                                            <Button
                                                                data-test-id="reqAcceptBtn"
                                                                onClick={() => this.acceptBoardRequest(requestData.board_membership_id)}
                                                                className={classes.reqAcceptBtn}
                                                            >
                                                                {t('acceptBtnTxt')}
                                                            </Button>
                                                            <Button
                                                                data-test-id="reqDeclineBtn"
                                                                onClick={() => this.declineBoardRequest(requestData.board_membership_id)}
                                                                className={classes.reqDeclineBtn}
                                                            >
                                                                {t('declineBtnTxt')}
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </Box>
                                        ))
                                    ) : (
                                        <Typography style={{ marginTop: "15px" }} color="textSecondary" className={classes.ExpandedListText} variant="body1">
                                            {t('noReqFoundValidation')}
                                        </Typography>
                                    ))
                                }
                            </Box>
                        </Box>
                    </Box>

                    <Button onClick={this.handleLogout} data-test-id="logoutBtn" className={classes.logoutBtn}>{t('logoutBtnTxt')}</Button>
                </Box>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, WrappedComponent, HeaderComponent, t, i18n, ...otherProps } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box className={classes.mainContainer}>

                    {this.renderSiderBar(classes, classes.sidebarContainer, t)}

                    <Drawer
                        anchor={this.conditionSort(isArabicLang(i18n.language), 'right', 'left')}
                        data-test-id="drawerContainer"
                        open={this.state.isDrawerOpen}
                        variant="temporary"
                        onClose={() => this.handleDrawer(false)}
                        className={classes.drawerContainer}
                    >
                        {this.renderSiderBar(classes, classes.sidebarContainerDrawer, t)}
                    </Drawer>

                    <Box className={classes.topBarWrapper}>

                        <HeaderComponent {...otherProps} />

                        <Box className={classes.pageContainer}>
                            <Box className={classes.pageLayout}>
                                <WrappedComponent {...otherProps} />
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const webStyle: any = (theme: any) => ({
    drawerContainer: {
        '& .MuiDrawer-paper': {
            width: "240px"
        }
    },
    fontRegular: {
        fontFamily: 'HelveticaNeue Regular'
    },
    mainContainer: {
        height: "100vh",
        width: "100vw",
        display: "flex"
    },
    sidebarContainer: {
        height: "100%",
        width: "19%",
        flexDirection: "column",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center",
        '@media (min-width: 0px) and (max-width: 1020px)': {
            display: "none"
        }
    },
    sidebarContainerDrawer: {
        height: "100%",
        width: "100%",
        flexDirection: "column",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center",
    },
    sidebarBodyContainer: {
        height: "calc(100% - 164px)",
        overflowY: "auto",
        overflowX: "hidden",
        background: "#2283C0",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        "&::-webkit-scrollbar": {
            width: "3px"
        },
        "&::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "5px"
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    },
    webSiteLogoContainer: {
        height: "164px",
        width: "100%",
        display: 'grid',
        placeItems: "center"
    },
    sidebarSecOne: {
        width: "inherit",
        minWidth: "206px"
    },
    sidebarSubSecOne: {
        padding: "0 11.7%"
    },
    profileContainer: {
        marginTop: "15px",
        display: "flex",
        gap: "18px"
    },
    profilelogo: {
        width: "54px",
        height: "54px",
        background: "#E7E8EA",
        placeItems: "center",
        display: "grid"
    },
    sideBarProfileLogo: {
        width: "42px",
        height: "42px",
    },
    topBarWrapper: {
        height: "100vh",
        width: "81%",
        '@media (min-width: 0px) and (max-width: 1020px)': {
            width: "100%",
        }
    },
    dividerRage: {
        background: "#fff",
        marginTop: "20px"
    },
    listContainer: {
        marginTop: "25px"
    },
    listRage: {
        display: "flex",
        padding: "0 11.7%",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "20px"
    },
    RequestExpandAlignIcon: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 11.7%"
    },
    RequestExpandAlignIconActive: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 11.7%",
        background: "#8CBEDD"
    },
    ReportExpandList: {
        display: "flex",
        gap: "10px",
        flexDirection: "column",
        marginTop: "15px"
    },
    ExpandedListText: {
        fontFamily: 'HelveticaNeue Regular',
        padding: "0 11.7%",
        textDecoration: "none",
        textTransform: "capitalize",
        wordBreak: "break-word"
    },
    RequestExpandList: {
        display: "flex",
        gap: "10px",
        justifyContent: "space-between",
        flexDirection: "column",
        marginTop: "15px"
    },
    logoutBtn: {
        textTransform: "none",
        "borderRadius": "6px",
        margin: "50px 0px 25px 0px",
        "border": "1px solid #67C375",
        "background": "#67C375",
        "width": "140px",
        "height": "48px",
        "fontSize": "20px",
        "fontStyle": "normal",
        "fontWeight": "400",
        "color": "#fff",
        '&:hover': {
            "background": "#67C375",
            opacity: "0.8",
            "color": "#fff",
        }
    },
    pageContainer: {
        width: "100%",
        height: "calc(100% - 164px)",
        '@media (min-width: 0px) and (max-width: 1020px)': {
            height: "calc(100% - 78px)"
        }
    },
    pageLayout: {
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        boxSizing: "border-box",
        padding: "20px",
        "&::-webkit-scrollbar": {
            width: "3px"
        },
        "&::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "5px"
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    },
    //invitation Request
    requestContainer: {
        "minHeight": "56px",
        height: "auto",
        boxSizing: "border-box",
        margin: "0 11.7%",
        padding: "0 10px",
        "borderRadius": "8px",
        "border": "1px solid #979797",
        "background": "#FFF",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexWrap: "wrap",
        marginBottom: "16px"
    },
    requestBody: {
        display: "flex",
        marginTop: "10px",
        marginBottom: "15px",
        justifyContent: "space-between",
        alignContent: "center",
        width: "100%",
        flexWrap: "wrap"
    },
    requestBtnContainer: {
        display: "flex",
        justifyContent: "center",
        gap: "9px",
        position: "absolute",
        bottom: "-16px"
    },
    reqAcceptBtn: {
        textTransform: "none",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": "400",
        color: "#fff",
        "borderRadius": "8px",
        "border": "1px solid #67C375",
        "background": "#67C375",
        "width": "70px",
        "height": "32px",
        '&:hover': {
            "background": "#67C375",
            opacity: "0.9",
        }
    },
    reqDeclineBtn: {
        textTransform: "none",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": "400",
        color: "#67C375",
        "borderRadius": "8px",
        "border": "1px solid #67C375",
        "background": "#ECFFEF",
        "width": "70px",
        "height": "32px",
        '&:hover': {
            "background": "#ECFFEF",
            opacity: "0.9",
        }
    },
    downArrowIconRage: {
        padding: "6px"
    },
    upArrowIconRage: {
        padding: "6px",
        transform: "rotate(180deg)"
    },
    rotateIcon: {
        transform: "rotate(180deg)"
    }
});

export const theme = createTheme({
    palette: {
        error: { main: "#F00" },
        text: {
            secondary: "#fff"
        }
    },
    typography: {
        fontFamily: 'HelveticaNeue Medium',
        h4: {
            "fontSize": "28px",
            "fontStyle": "normal",
            "fontWeight": 400
        },
        h5: {
            "fontSize": "26px",
            fontFamily: 'HelveticaNeue Regular',
            "fontStyle": "normal",
            "fontWeight": 400
        },
        h6: {
            "fontSize": "20px",
            "fontStyle": "normal",
            "fontWeight": 400
        },
        subtitle1: {
            "fontSize": "18px",
            "fontStyle": "normal",
            "fontWeight": 400
        },
        caption: {
            "fontSize": "16px",
            "fontStyle": "normal",
            "fontWeight": 400
        },
        subtitle2: {
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": 400,
            color: "rgba(27, 27, 27, 0.50)"
        },
        body2: {
            "fontSize": "12px",
            "fontStyle": "normal",
            "fontWeight": 400,
        }
    },
});

export default withTranslation<MainLayout>()(withStyles(webStyle)(MainLayout));

const SvgIcons = {
    downIcon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11C21 16.5228 16.5228 21 11 21Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M7.46997 9.73999L11 13.26L14.53 9.73999" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>,
    searchIcon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 20C5.25329 20 1 15.7467 1 10.5C1 5.25329 5.25329 1 10.5 1C15.7467 1 20 5.25329 20 10.5C20 15.7467 15.7467 20 10.5 20Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M21 21L19 19" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>,
    squareDownIcon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24" fill="none">
        <path d="M14 23C19 23 21 20.8 21 15.3V8.7C21 3.2 19 1 14 1H8C3 1 1 3.2 1 8.7V15.3C1 20.8 3 23 8 23H14Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M7.46997 10.5039L11 14.3759L14.53 10.5039" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>,
    popoverEditIcon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M7.22184 1.17368H5.83921C2.38263 1.17368 1 2.55631 1 6.01289V10.1608C1 13.6174 2.38263 15 5.83921 15H9.98711C13.4437 15 14.8263 13.6174 14.8263 10.1608V8.77816" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M5.25857 7.32639C5.05117 7.53379 4.84378 7.94167 4.8023 8.23893L4.50503 10.3198C4.39442 11.0733 4.92673 11.5987 5.68027 11.495L7.76113 11.1978C8.05148 11.1563 8.45936 10.9489 8.67367 10.7415L14.1212 5.29392C15.0614 4.35373 15.5039 3.26145 14.1212 1.87882C12.7386 0.496189 11.6463 0.938631 10.7061 1.87882L5.25857 7.32639Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9.9248 2.65994C10.388 4.31219 11.6807 5.60495 13.3399 6.07505" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>,
    downArrowIconForReq: <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
        <path d="M1 1L4.53 4.52L8.06 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
}
// Customizable Area End
