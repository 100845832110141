import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  ButtonBase, Dialog, Grid,
  Chip, Badge
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { sales } from './assets'
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom"
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { withTranslation } from "react-i18next";
import { preventPaste, isArabicLang, conditionSort } from "../../../components/src/HelperMethonds";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import ProjectTaskTrackingController, {
  Props,
  configJSON,
} from "./ProjectTaskTrackingController";

export default class ProjectTaskTracking extends ProjectTaskTrackingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.boardIconUpload = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  validationSchema = (t: Function) => (
    Yup.object().shape({
      board_name: Yup.string().trim().required(t('validationBoardNameReq')).max(25, t('validationBoardNameLength')),
      board_des: Yup.string().trim().required(t('validationBoardDesReq')).max(250, t('longDesValidMsg')),
      invite_member: Yup.string().test('required', t('inviteMemberValidationMsg'), function (value) {
        const { invite_member_array } = this.parent;
        return !value || value.length === 0 || (value.length === 0 && invite_member_array.length > 0);
      })
    })
  )

  GenCreateBoard = (t: Function) => {
    const { i18n, classes } = this.props
    return (
      <Box className={classes.container}>
        <Box className={classes.headSection}>
          <AddIcon className={classes.addIcon} />
          <Box>
            <Typography variant="body1">{t('createBoardText')}</Typography>
            <Typography className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)} variant="caption">
              {t('createModalSubHeader')}
            </Typography>
          </Box>
        </Box>
        <Formik
          initialValues={{
            board_name: '',
            board_des: '',
            invite_member: '',
            invite_member_array: [],
            boardIcon: { name: '' }
          }}
          validateOnBlur={false}
          validationSchema={this.validationSchema(t)}
          onSubmit={(dataObject) => {
            this.createBoard(dataObject)
          }}
          render={({
            errors,
            touched,
            handleChange,
            handleSubmit,
            values,
            setFieldValue,
            setFieldError,
            setFieldTouched
          }) => {
            return (
              <>
                <Box className={classes.inputSection}>
                  <Box>
                    <Typography gutterBottom variant="body1">
                      {t('boardNameInputLabel')}
                      <span className={classes.reqField}>*</span>
                    </Typography>
                    <Input
                      name="board_name"
                      id="boardNameInputLabel"
                      data-test-id="txtInputName"
                      className={classes.inputRage}
                      value={values.board_name}
                      onChange={handleChange}
                      fullWidth={true}
                      placeholder={t('boardNameInputPlaceholder')}
                      disableUnderline={true}
                    />
                    {touched.board_name && errors.board_name && this.GenErrorText({ type: "error", message: errors.board_name })}
                  </Box>

                  <Box>
                    <Typography data-test-id="labelDes" gutterBottom variant="body1">
                      {t('boardDesInputLabel')}
                      <span className={classes.reqField}>*</span>
                    </Typography>
                    <Input
                      name="board_des"
                      id="board_des"
                      value={values.board_des}
                      onChange={handleChange}
                      data-test-id="txtInputcreateDes"
                      className={classes.textAreaRage}
                      fullWidth={true}
                      rows={4}
                      multiline
                      placeholder={t('boardDesPlaceholder')}
                      disableUnderline={true}
                    />
                    {errors.board_des && touched.board_des && this.GenErrorText({ type: "error", message: errors.board_des })}
                  </Box>
                  <Box>
                    <Typography gutterBottom variant="body1">{t('boardIconInputLabel')}</Typography>
                    <Input
                      data-test-id="boardIconInput"
                      className={this.conditionSort(isArabicLang(i18n.language), `${classes.inputRage} ${classes.inputRageArabicAlter}`, classes.inputRage)}
                      fullWidth={true}
                      readOnly
                      value={values.boardIcon.name}
                      placeholder={t('boardIconInputPlaceholder')}
                      disableUnderline={true}
                      endAdornment={
                        <InputAdornment position={this.conditionSort(isArabicLang(i18n.language), 'start', 'end')}>
                          <IconButton
                            data-test-id="boardIconBtn"
                            onClick={() => this.boardIconUpload.current.click()}
                            className={classes.uploadIconBtn}
                          >
                            <CloudUploadIcon style={{ color: "#fff" }} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errors.boardIcon && touched.boardIcon && this.GenErrorText({ type: "error", message: errors.boardIcon })}
                    <input
                      onChange={(e) => this.handleFileUpload(e, { setFieldValue, setFieldError, setFieldTouched })}
                      data-test-id="boardIconFileInput"
                      type="file"
                      ref={this.boardIconUpload}
                      accept="image/*"
                      style={{ display: "none" }}
                    />

                  </Box>
                  <Box>
                    <Typography data-test-id="inviteLabel" gutterBottom variant="body1">{t('inviteMembersInputLabel')}</Typography>
                    <Input
                      name="invite_member"
                      onKeyDown={(e) => this.handleInviteMember(e, values, { setFieldValue, setFieldError, setFieldTouched })}
                      onPaste={e => preventPaste(e, /^\d+$/)}
                      onChange={handleChange}
                      value={values.invite_member}
                      disableUnderline={true}
                      data-test-id="txtInputcreatemember"
                      className={classes.inputRage}
                      fullWidth={true}
                      placeholder={t('inviteMembersInputPlaceholder')}
                    />
                    {errors.invite_member && touched.invite_member && this.GenErrorText({ type: "error", message: errors.invite_member })}
                    {!errors.invite_member && !touched.invite_member && <Typography style={{ fontStyle: "italic" }} className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)} variant="subtitle1">{t('infoMessageInviteNumber')}</Typography>}
                  </Box>
                  <Box className={classes.chipContainer}>

                    {values.invite_member_array.map((data: any, index: number) => (
                      <Badge key={index} badgeContent={
                        <ButtonBase onClick={() => this.handleDeleteInputInviteMember(index, setFieldValue, values)} disableRipple className={classes.cancelChipIcon}>
                          {SvgIcons.chipCancelIcon}
                        </ButtonBase>}
                      >
                        <Chip
                          data-test-id="chipBody"
                          className={classes.chipRage}
                          label={data}
                          variant="outlined"
                          size="small" />
                      </Badge>
                    ))}

                  </Box>
                </Box>

                <Button data-test-id="formSubmitBtn" disabled={this.state.isLoading} onClick={() => this.handleSubmitForm({ setFieldError, setFieldTouched }, handleSubmit)} className={classes.createUpdateBoardBtn}>
                  {t('createBoardText')}
                </Button>
              </>
            )
          }} />
      </Box>
    )
  }

  GenEditBoard = (t: Function) => {
    const { i18n, classes } = this.props
    return (
      <Box className={this.props.classes.container}>
        <Box className={this.props.classes.headSection}>
          {SvgIcons.editHeaderIcon}

          <Box>
            <Typography variant="body1">{t('editBoardTxt')}</Typography>
            <Typography className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)} variant="caption">
              {t('editModalSubHeader')}
            </Typography>
          </Box>
        </Box>

        <Formik
          validateOnBlur={false}
          onSubmit={(dataObject) => {
            this.updateBoard(dataObject)
          }}
          initialValues={{
            invite_member: '',
            board_name: this.state.modalStats.selectedBoard?.attributes?.name || '',
            invite_member_array: this.state.modalStats.selectedBoard?.attributes?.invited_mobile_numbers || [],
            board_des: this.state.modalStats.selectedBoard?.attributes?.description || '',
            boardIcon: { name: this.state.modalStats.selectedBoard?.attributes?.icon?.name || '' }
          }}
          validationSchema={this.validationSchema(t)}
          render={({
            errors,
            touched,
            handleChange,
            handleSubmit,
            values,
            setFieldValue,
            setFieldError,
            setFieldTouched
          }) => {
            return (
              <>
                <Box className={this.props.classes.inputSection}>
                  <Box>
                    <Typography gutterBottom variant="body1">
                      {t('boardNameInputLabel')}
                      <span className={classes.reqField}>*</span>
                    </Typography>
                    <Input
                      name="board_name"
                      data-test-id="txtInput2"
                      className={this.props.classes.inputRage}
                      placeholder={t('boardNameInputPlaceholder')}
                      value={values.board_name}
                      onChange={handleChange}
                      disableUnderline={true}
                      fullWidth={true}
                    />
                    {errors.board_name && touched.board_name && this.GenErrorText({ type: "error", message: errors.board_name })}
                  </Box>

                  <Box>
                    <Typography gutterBottom variant="body1">
                      {t('boardDesInputLabel')}
                      <span className={classes.reqField}>*</span>
                    </Typography>
                    <Input
                      value={values.board_des}
                      className={this.props.classes.textAreaRage}
                      onChange={handleChange}
                      name="board_des"
                      multiline
                      data-test-id="txtInputDes"
                      rows={4}
                      placeholder={t('boardDesPlaceholder')}
                      fullWidth={true}
                      disableUnderline={true}
                    />

                    {errors.board_des && touched.board_des && this.GenErrorText({ type: "error", message: errors.board_des })}
                  </Box>

                  <Box>
                    <Typography gutterBottom variant="body1">{t('boardIconInputLabel')}</Typography>
                    <Input
                      data-test-id="editIconInput"
                      className={this.conditionSort(isArabicLang(i18n.language), `${this.props.classes.inputRage} ${this.props.classes.inputRageArabicAlter}`, this.props.classes.inputRage)}
                      readOnly
                      fullWidth={true}
                      value={values.boardIcon.name}
                      endAdornment={
                        <InputAdornment position={this.conditionSort(isArabicLang(i18n.language), 'start', 'end')}>
                          <IconButton
                            data-test-id="boardEditIconBtn"
                            className={this.props.classes.uploadIconBtn}
                            id="uploadIconBtnEdit"
                            onClick={() => this.boardIconUpload.current.click()}
                          >
                            <CloudUploadIcon style={{ color: "#fff" }} />
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder={t('boardIconInputPlaceholder')}
                      disableUnderline={true}
                    />

                    {errors.boardIcon && touched.boardIcon && this.GenErrorText({ type: "error", message: errors.boardIcon })}
                    <input
                      onChange={(e) => this.handleFileUpload(e, { setFieldValue, setFieldError, setFieldTouched })}
                      data-test-id="boardIconEditFileInput"
                      type="file"
                      style={{ display: "none" }}
                      ref={this.boardIconUpload}
                      accept="image/*"
                    />
                  </Box>

                  <Box>
                    <Typography gutterBottom variant="body1">{t('inviteMembersInputLabel')}</Typography>

                    <Input
                      onKeyDown={(e) => this.handleInviteMember(e, values, { setFieldValue, setFieldError, setFieldTouched })}
                      onChange={handleChange}
                      name="invite_member"
                      onPaste={e => preventPaste(e, /^\d+$/)}
                      placeholder={t('inviteMembersInputPlaceholder')}
                      value={values.invite_member}
                      data-test-id="txtInputMember"
                      disableUnderline={true}
                      className={this.props.classes.inputRage}
                      fullWidth={true}
                    />
                    {errors.invite_member && touched.invite_member && this.GenErrorText({ type: "error", message: errors.invite_member })}
                    {!errors.invite_member && !touched.invite_member && <Typography style={{ fontStyle: "italic" }} className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)} variant="subtitle1">{t('infoMessageInviteNumber')}</Typography>}
                  </Box>

                  <Box className={this.props.classes.chipContainer}>
                    {values.invite_member_array.map((data: any, index: number) => (
                      <Badge data-test-id="chipcancel" badgeContent={
                        <ButtonBase onClick={() => this.handleDeleteInputInviteMember(index, setFieldValue, values)} disableRipple className={this.props.classes.cancelChipIcon}>
                          {SvgIcons.chipCancelIcon}
                        </ButtonBase>}
                      >
                        <Chip
                          className={this.props.classes.chipRage}
                          label={data}
                          variant="outlined"
                          size="small" />
                      </Badge>
                    ))}
                  </Box>

                </Box>

                <Button data-test-id="formSubmitBtn2" onClick={() => this.handleSubmitForm({ setFieldError, setFieldTouched }, handleSubmit)} className={this.props.classes.createUpdateBoardBtn}>
                  {t('editModalSubmitBtnText')}
                </Button>
              </>
            )
          }} />
      </Box>
    )
  }

  GenInviteMember = (t: Function) => {
    const { i18n, classes } = this.props
    return (
      <Box className={this.props.classes.container}>
        <Box>
          <Typography gutterBottom variant="body2">{t('inviteMemberTxt')}</Typography>
          <Typography style={{ color: "#959494" }} className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)} variant="caption">
            {t('inviteMemberModalSubHeader')}
          </Typography>
        </Box>

        <Box className={this.props.classes.inputSection}>
          <Box>
            <Typography gutterBottom variant="body1">{t('viaLinkInputLabel')}</Typography>
            <Input
              data-test-id="iniviteLinkInput"
              style={{ paddingRight: "7px" }}
              classes={{ focused: this.props.classes.foucsInput }}
              className={this.conditionSort(isArabicLang(i18n.language), `${this.props.classes.inviteInputRage} ${this.props.classes.inviteInputArabicAlter}`, this.props.classes.inviteInputRage)}
              fullWidth={true}
              readOnly
              value={this.state.boardInvitationLink}
              disableUnderline={true}
              endAdornment={
                <InputAdornment position={this.conditionSort(isArabicLang(i18n.language), 'start', 'end')}>
                  <IconButton
                    data-test-id="linkCopyBtn"
                    onClick={this.handleCopyLink}
                    className={this.props.classes.copyBtn}
                  >
                    {SvgIcons.pasteIcon}
                  </IconButton>
                </InputAdornment>
              }
            />
            {this.state.isLinkCopyDone && this.GenErrorText({ type: "success", message: t('infoLinkCopied') })}
          </Box>

          <Formik
            onSubmit={(dataObject) => {
              this.inviteMemberViaWhatsapp(dataObject)
            }}
            initialValues={{
              invite_member: '',
              invite_member_array: this.state.modalStats.selectedBoard?.attributes?.invited_mobile_numbers || [],
            }}
            validateOnBlur={false}
            validationSchema={Yup.object().shape({
              invite_member: Yup.string().test('required', t('validationInviteMember'), function (value) {
                const { invite_member_array } = this.parent
                return value || invite_member_array.length > 0;
              }).test('mtCheck', t('inviteMemberValidationMsg'), function (value) {
                const { invite_member_array } = this.parent;
                return !value || value.length === 0 || (value.length === 0 && invite_member_array.length > 0);
              })
            })}
            render={({
              errors,
              handleChange,
              setFieldValue,
              handleSubmit,
              values,
              setFieldError,
              touched,
              setFieldTouched,
              isValid
            }) => {
              return (
                <>
                  <Box>
                    <Typography gutterBottom variant="body1">{t('inviteMembersInputLabel')}</Typography>
                    <Input
                      onChange={handleChange}
                      placeholder={t('inviteMembersInputPlaceholder')}
                      name="invite_member"
                      onPaste={e => preventPaste(e, /^\d+$/)}
                      onKeyDown={(e) => this.handleInviteMember(e, values, { setFieldValue, setFieldError, setFieldTouched })}
                      value={values.invite_member}
                      data-test-id="txtInputInviteMember"
                      fullWidth={true}
                      disableUnderline={true}
                      className={this.props.classes.inputRage}
                    />

                    {!errors.invite_member && !touched.invite_member && <Typography style={{ fontStyle: "italic" }} className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)} variant="subtitle1">{t('infoMessageInviteNumber')}</Typography>}

                    {errors.invite_member && touched.invite_member && this.GenErrorText({ type: "error", message: errors.invite_member })}
                  </Box>

                  <Box className={this.props.classes.chipContainer}>
                    {values.invite_member_array.map((data: any, index: number) => (
                      <Badge data-test-id="deleteInviteMemberBadge" badgeContent={
                        <ButtonBase data-test-id="deleteInviteMemberBtn" onClick={() => this.handleDeleteInputInviteMember(index, setFieldValue, values, true)} disableRipple className={this.props.classes.cancelChipIcon}>
                          {SvgIcons.chipCancelIcon}
                        </ButtonBase>}
                      >
                        <Chip
                          className={this.props.classes.chipRage}
                          label={data}
                          variant="outlined"
                          size="small" />
                      </Badge>
                    ))}
                  </Box>

                  <Button data-test-id="formInviteMember" onClick={() => handleSubmit()} className={this.props.classes.createUpdateBoardBtn}>
                    {t('inviteMemberTxt')}
                  </Button>
                </>
              )
            }} />
        </Box>

      </Box>
    )
  }

  GenSucessfullDelete = (boardType: boolean, t: Function) => {
    return (
      <>
        <CheckCircleIcon className={this.props.classes.iconRage} />

        {boardType && <Typography style={{ marginTop: "40px" }} align="center" variant="body2"
          dangerouslySetInnerHTML={{
            __html: t('successDeleteBoard', { selectedBoard: this.state.modalStats.selectedBoard?.attributes?.name })
          }}
          gutterBottom />
        }

        {!boardType && <Typography style={{ marginTop: "40px" }} align="center" variant="body2"
          dangerouslySetInnerHTML={{
            __html: t('successBoardMemberDeleted', {
              memberName: this.state.modalStats.selectedMember.full_name,
              selectedBoard: this.state.modalStats.selectedBoard?.attributes?.name
            })
          }}
          gutterBottom />
        }

        <Button onClick={() => this.handleModalAppearance('', false, {})} className={this.props.classes.goToBtn} variant="contained">
          {t('goToDashboardTxt')}
        </Button>
      </>
    )
  }

  GenConfirmDelete = (boardType: boolean, t: Function) => {
    return (
      <>
        <Box className={this.props.classes.deleteIconContainer}>
          {SvgIcons.deleteConfirmIcon}
        </Box>

        {boardType && <Typography style={{ marginTop: "40px" }} align="center" variant="body2" gutterBottom
          dangerouslySetInnerHTML={{
            __html: t('askDeleteBoard', {
              selectedBoard: this.state.modalStats.selectedBoard?.attributes?.name
            })
          }}
        />}

        {!boardType && <Typography style={{ marginTop: "40px" }} align="center" variant="body2" gutterBottom
          dangerouslySetInnerHTML={{
            __html: t('askDeleteBoardMember', {
              memberName: this.state.modalStats.selectedMember?.full_name
            })
          }}
        />}

        <Box className={this.props.classes.actionBtnContainer}>
          <Button data-test-id="btnDeleteboard" onClick={() => boardType ? this.deleteBoard() : this.deleteBoardMember(this.state.modalStats.selectedMember.board_membership_id)} className={this.props.classes.confirmDeleteBtn} variant="contained">{t('acceptDeleteBtnTxt')}</Button>

          <Button data-test-id="btnCancelDeleteboard" onClick={() => this.handleModalAppearance('', false)} className={this.props.classes.cancelDeleteBtn} variant="outlined">{t('noBtnTxt')}</Button>
        </Box>
      </>
    )
  }

  GenBoardMemberList = (t: Function) => {
    const { classes, i18n } = this.props;
    return (
      <Box className={this.props.classes.container}>
        <Box className={this.props.classes.headSection}>
          <Box className={this.props.classes.boardIcon}>
            <img src={this.state.modalStats.selectedBoard?.attributes?.icon?.url || sales} height={50} width={50} />
          </Box>

          <Box width={'84%'}>
            <Typography variant="body1">{this.state.modalStats.selectedBoard?.attributes?.name}</Typography>
            <Typography style={{ color: "#959494" }} className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)} variant="caption">
              {this.state.modalStats.selectedBoard?.attributes?.description}
            </Typography>
          </Box>
        </Box>

        <Box className={this.props.classes.listSection}>

          <Typography variant="body2">{t('boardMemberModalTitle')}</Typography>

          <Box className={this.props.classes.listContainer}>
            {this.state.modalStats.selectedBoard?.attributes?.board_members.length > 0 ? (
              this.state.modalStats.selectedBoard.attributes.board_members.map((data: any) => (
                <Box key={data.board_membership_id} className={this.props.classes.memberListRage}>
                  <Typography className={this.props.classes.textOneLineClamp} variant="h6">{data.full_name}</Typography>
                  <Button data-test-id="boardMemberDelbtn" onClick={() => this.handleModalAppearance(configJSON.GenConfirmDeleteMemberID, true, this.state.modalStats.selectedBoard, data)} className={this.props.classes.deleteMemberBtn}>{t('deleteTxt')}</Button>
                </Box>
              ))
            ) : (
              <Typography align="center" variant="h6">{t('noMemberValidation')}</Typography>
            )}
          </Box>
        </Box>
      </Box>
    )
  }

  GenErrorText = (erorrProps: any) => {
    const Icon = this.conditionSort(erorrProps.type == 'success', CheckCircleOutlineOutlinedIcon, InfoOutlinedIcon)
    return (
      <Typography
        color={this.conditionSort(erorrProps.type == 'success', 'secondary', 'error')}
        className={this.props.classes.commonErrorMessage} variant='subtitle1'>
        <span>
          <Icon
            className={this.conditionSort(erorrProps.type == 'success', '', this.props.classes.errorIcon)}
            color={this.conditionSort(erorrProps.type == 'success', 'secondary', 'error')} fontSize='small' />
        </span>
        {erorrProps.message}
      </Typography>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area start
    const { classes, t, i18n } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={conditionSort(isArabicLang(i18n.language), ArabicTheme, theme)}>
        <Loader loading={this.state.isLoading} />
        <Box>
          <Typography gutterBottom variant="h3">{t('yourBoardTxt')}</Typography>        
        </Box>
        <Box className={classes.boardContainer}>
          <ButtonBase data-test-id="btnCreateBoard" onClick={() => this.handleModalAppearance(configJSON.GenCreateBoardID, true)} className={classes.addBoardRage}>
            <Box className={classes.addboardBody}>
              <AddCircleIcon className={classes.additionIcon} />
              <Typography variant="caption">{t('createBoardText')}</Typography>
            </Box>
          </ButtonBase>

          {this.state.boardList.map((data: any) => (
            <Box className={classes.boardRageWrapper}>
              <Button to={`/TaskList/${data.id}`} component={Link} style={{ background: data.attributes.color_code.data.attributes.color_code }} className={classes.boardRage}>
                <Box className={classes.boardBody}>
                  <img src={data.attributes.icon?.url || sales} height={40} width={40} />
                  <Typography align="center" variant="caption">{data.attributes.name}</Typography>
                </Box>
              </Button>
              <this.ConditionalPanel conditionArray={[data.attributes.is_current_user_project_manager]}>
                <Box className={classes.boardActionIconConatiner}>

                  <ButtonBase onClick={() => this.handleModalAppearance(configJSON.GenBoardMemberListID, true, data)} data-test-id="btnEye" className={classes.boardActionIconRage}>
                    {SvgIcons.eyeIcon}
                  </ButtonBase>

                  <ButtonBase data-test-id="btnEdit" onClick={() => this.handleModalAppearance(configJSON.GenEditBoardID, true, data)} className={classes.boardActionIconRage}>
                    {SvgIcons.editIcon}
                  </ButtonBase>

                  <ButtonBase data-test-id="btnDelete" onClick={() => this.handleModalAppearance(configJSON.GenConfirmDeleteBoardID, true, data)} className={classes.boardActionIconRage}>
                    {SvgIcons.deleteIcon}
                  </ButtonBase>

                  <ButtonBase data-test-id="btnInvite" onClick={() => this.handleGetBoardInviteLink(data)} className={classes.boardActionIconRage}>
                    {SvgIcons.inviteIcon}
                  </ButtonBase>
                </Box>
              </this.ConditionalPanel>
            </Box>
          ))}
        </Box>

        <Dialog
          data-test-id="modalConatiner"
          fullWidth
          maxWidth="sm"
          PaperProps={{
            style: this.conditionSort(this.deleteModalTypes.includes(this.state.modalStats.type), { width: "462px", minHeight: "409px", display: "grid" }, {})
          }}
          open={this.state.modalStats.isOpen}
          onClose={() => this.handleModalAppearance('', false)}
        >
          <>
            <Loader loading={this.state.isLoading} />
            <Grid className={classes.modalContainer} justifyContent="center" container direction="column" alignItems="center">

              <this.ConditionalPanel conditionArray={[this.state.modalStats.type == configJSON.GenCreateBoardID]}>
                {this.GenCreateBoard(t)}
              </this.ConditionalPanel>

              <this.ConditionalPanel conditionArray={[this.state.modalStats.type == configJSON.GenEditBoardID]}>
                {this.GenEditBoard(t)}
              </this.ConditionalPanel>

              <this.ConditionalPanel conditionArray={[this.state.modalStats.type == configJSON.GenInviteMemberID]}>
                {this.GenInviteMember(t)}
              </this.ConditionalPanel>

              <this.ConditionalPanel conditionArray={[this.state.modalStats.type == configJSON.GenBoardMemberListID]}>
                {this.GenBoardMemberList(t)}
              </this.ConditionalPanel>

              <this.ConditionalPanel conditionArray={[this.state.modalStats.type == configJSON.GenSucessfullDeleteBoardID]}>
                {this.GenSucessfullDelete(true, t)}
              </this.ConditionalPanel>

              <this.ConditionalPanel conditionArray={[this.state.modalStats.type == configJSON.GenConfirmDeleteBoardID]}>
                {this.GenConfirmDelete(true, t)}
              </this.ConditionalPanel>

              <this.ConditionalPanel conditionArray={[this.state.modalStats.type == configJSON.GenSucessfullDeleteMemberID]}>
                {this.GenSucessfullDelete(false, t)}
              </this.ConditionalPanel>

              <this.ConditionalPanel conditionArray={[this.state.modalStats.type == configJSON.GenConfirmDeleteMemberID]}>
                {this.GenConfirmDelete(false, t)}
              </this.ConditionalPanel>

            </Grid>
          </>
        </Dialog>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle: any = (theme: any) => ({
  arabicFontRegular: {
    fontFamily: "GeezaPro Regular",
  },
  textOneLineClamp: {
    fontFamily: 'HelveticaNeue Regular',
    "display": "inline-block",
    "width": "250px",
    "whiteSpace": "nowrap",
    "overflow": "hidden !important",
    "textOverflow": "ellipsis"
  },
  boardContainer: {
    width: "100%",
    marginTop: "40px",
    flexWrap: "wrap",
    display: "flex",
    gap: "22px"
  },
  boardRageWrapper: {
    "height": "149px",
    display: "grid",
    placeItems: "center",
    position: "relative",
    marginBottom: "10px"
  },
  boardRage: {
    "width": "254px",
    display: "grid",
    textDecoration: 'none',
    textTransform: "none",
    placeItems: "center",
    color: "initial",
    "height": "149px",
    "borderRadius": "4px",
    "border": "1px solid #D4D4D4"
  },
  boardBody: {
    display: "flex",
    padding: "0 10px",
    boxSizing: "border-box",
    flexDirection: "column",
    alignItems: 'center',
    marginBottom: "30px"
  },
  addboardBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: 'center'
  },
  boardActionIconRage: {
    "width": "32px",
    "height": "32px",
    "borderRadius": "2px",
    "border": "1px solid #67C375",
    "background": "#ECFFEF",
    display: 'grid',
    placeItems: "center"
  },
  boardActionIconConatiner: {
    display: "flex",
    position: 'absolute',
    bottom: '-16px',
    gap: '8px'
  },
  addBoardRage: {
    "width": "160px",
    "height": "149px",
    marginBottom: "10px",
    "borderRadius": "4px",
    "border": "2px dashed #D4D4D4",
    "background": "#FDFDFD",
    display: "grid",
    boxSizing: "border-box",
    placeItems: "center",
  },
  additionIcon: {
    fontSize: "36px",
    color: "#67C375"
  },
  fontRegular: {
    fontFamily: 'HelveticaNeue Regular',
  },
  //Related to Dialog
  iconRage: {
    color: "#67C375",
    fontSize: "9vmax"
  },
  modalContainer: {
    padding: "25px 25px",
  },
  goToBtn: {
    "borderRadius": "6px",
    marginTop: "35px",
    "background": "#67C375",
    "height": "48px",
    width: "284px",
    color: "#FFF",
    textTransform: "none",
    "fontSize": "20px",
    "fontStyle": "normal",
    "fontWeight": "500",
    [theme.breakpoints.only("sm")]: {
      "fontSize": "13px",
      marginTop: "26px",
      "height": "36px",
      width: "200px",
    },
    '&:hover': {
      "background": "#67C375",
      opacity: "0.8"
    }
  },
  deleteIconContainer: {
    "width": "170px",
    "height": "134px",
    "borderRadius": "6px",
    "border": "1px solid #959494",
    "background": "#FFF",
    display: "grid",
    placeItems: "center"
  },
  confirmDeleteBtn: {
    "width": "153px",
    "height": "48px",
    "fontSize": "20px",
    "fontStyle": "normal",
    "fontWeight": "400",
    color: "#fff",
    textTransform: "none",
    "borderRadius": "6px",
    "border": "1px solid #67C375",
    "background": "#67C375",
    '&:hover': {
      "background": "#67C375",
      opacity: "0.8"
    }
  },
  cancelDeleteBtn: {
    "width": "153px",
    "height": "48px",
    "borderRadius": "6px",
    "border": "1px solid #67C375",
    "background": "#ECFFEF",
    "fontSize": "20px",
    "fontStyle": "normal",
    "fontWeight": "400",
    textTransform: "none",
    color: "#67C375",
  },
  actionBtnContainer: {
    display: "flex",
    direction: "ltr",
    gap: "16px",
    marginTop: "40px",
    "@media (min-width: 0px) and (max-width: 450px)": {
      flexDirection: "column"
    }
  },
  //Create board
  addIcon: {
    background: "#67C375",
    color: "#fff",
    "width": "52px",
    "height": "48px"
  },
  uploadIconBtn: {
    "width": "90px",
    "border": "1px solid #67C375",
    "height": "38px",
    "background": "#67C375",
    "borderRadius": "6px",
    '&:hover': {
      "background": "#67C375",
      opacity: "0.8"
    }
  },
  headSection: {
    display: 'flex',
    width: "100%",
    gap: "12px",
    alignItems: "center"
  },
  container: {
    width: "90%",
    minWidth: 'fit-content',
    [theme.breakpoints.only("md")]: {
      width: "100%",
    },
  },
  inputSection: {
    marginTop: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "18px"
  },
  inputRage: {
    "borderRadius": "7px",
    "border": "1px solid #F2F2F2",
    width: "100%",
    "background": "#FAFAFA",
    fontFamily: 'HelveticaNeue Regular',
    "fontSize": "18px",
    "fontStyle": "normal",
    "fontWeight": "500",
    padding: "5px 5px 5px 24px",
    boxSizing: "border-box",
    height: "48px",
    [theme.breakpoints.only("sm")]: {
      "height": "40px",
      "fontSize": "18px",
    }
  },
  inputRageArabicAlter: {
    padding: "5px 24px 5px 5px",
  },
  textAreaRage: {
    "borderRadius": "7px",
    "border": "1px solid #F2F2F2",
    fontFamily: 'HelveticaNeue Regular',
    width: "100%",
    "background": "#FAFAFA",
    "fontSize": "18px",
    "fontStyle": "normal",
    "fontWeight": "500",
    padding: "5px 24px 5px 24px",
    boxSizing: "border-box",
    height: "auto",
    [theme.breakpoints.only("sm")]: {
      "height": "40px",
      "fontSize": "18px",
    }
  },
  chipRage: {
    "borderRadius": "24px",
    "border": "1px solid #959494",
    "height": "42px",
    padding: "7px 14px",
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    color: "#959494"
  },
  cancelChipIcon: {
    "position": "absolute",
    "right": "10px",
    "top": "3px"
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px"
  },
  createUpdateBoardBtn: {
    marginTop: "20px",
    "borderRadius": "6px",
    "border": "1px solid #67C375",
    "background": "#67C375",
    width: '206px',
    height: '48px',
    textTransform: "none",
    "fontSize": "20px",
    "fontStyle": "normal",
    "fontWeight": "400",
    "color": "#fff",
    '&:hover': {
      "background": "#67C375",
      opacity: "0.8"
    }
  },
  //Invite member
  copyBtn: {
    "borderRadius": "6px",
    "border": "1px solid #67C375",
    "background": "#67C375",
    "width": "54px",
    "height": "50px",
    '&:hover': {
      "background": "#67C375",
      "opacity": "0.8"
    }
  },
  inviteInputRage: {
    "borderRadius": "7px",
    "border": "1px solid #D4D4D4",
    "background": "#FAFAFA",
    "height": "66px",
    fontFamily: 'HelveticaNeue Regular',
    "fontSize": "18px",
    "fontStyle": "normal",
    "fontWeight": "500",
    padding: "5px 24px 5px 24px",
    boxSizing: "border-box"
  },
  inviteInputArabicAlter: {
    padding: "5px 24px 5px 5px",
  },
  foucsInput: {
    "border": "1px solid black"
  },
  //Board member list
  boardIcon: {
    "borderRadius": "4px",
    display: "grid",
    placeItems: "center",
    "border": "1px solid #979797",
    "background": "rgba(238, 238, 238, 0.26)",
    "width": "72px",
    "height": "72px"
  },
  listSection: {
    marginTop: "30px"
  },
  memberListRage: {
    boxSizing: "border-box",
    "borderRadius": "7px",
    padding: "0px 14px 0px 24px",
    "border": "1px solid #D4D4D4",
    "background": "#FAFAFA",
    "height": "66px",
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "space-between"
  },
  listContainer: {
    marginTop: "24px",
    display: "flex",
    flexDirection: "column",
    gap: "16px"
  },
  deleteMemberBtn: {
    "borderRadius": "19px",
    "border": "1px solid #67C375",
    fontSize: "18px",
    "background": "#67C375",
    color: "#fff",
    textTransform: "none",
    "width": "120px",
    "height": "38px",
    '&:hover': {
      "background": "#67C375",
      opacity: "0.8"
    }
  },
  commonErrorMessage: {
    gap: "3px",
    justifyContent: "flex-start",
    display: "flex",
    alignItems: "center"
  },
  errorIcon: {
    transform: "rotate(180deg)"
  },
  reqField: {
    color: "red",
    padding: "4px",
  },
});

export const theme = createTheme({
  palette: {
    error: { main: "#F00" },
    secondary: { main: '#006827' }
  },
  typography: {
    fontFamily: 'HelveticaNeue Medium',
    h3: {
      "fontSize": "32px",
      "fontStyle": "normal",
      "fontWeight": 400
    },
    h6: {
      "fontSize": "20px",
      "fontStyle": "normal",
      "fontWeight": 400
    },
    caption: {
      "fontSize": "16px",
      "fontStyle": "normal",
      "fontWeight": 400,
      "wordBreak": "break-all"
    },
    //For Dialog
    body2: {
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 400
    },
    body1: {
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 400
    },
    subtitle1: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      '@media (min-width: 600px) and (max-width: 959.95px)': {
        "fontSize": "11px",
      }
    }
  },
});

export const ArabicTheme = createTheme({
  typography: {
    fontFamily: "GeezaPro Bold",
    h6: {
      "fontWeight": 400,
      "fontSize": "20px",
      "fontStyle": "normal"
    },
    h3: {
      "fontSize": "32px",
      "fontWeight": 400,
      "fontStyle": "normal"
    },
    caption: {
      "fontSize": "16px",
      "fontWeight": 400,
      "fontStyle": "normal",
      "wordBreak": "break-all"
    },
    //For Dialog
    body1: {
      fontStyle: 'normal',
      fontSize: '18px',
      fontWeight: 400
    },
    body2: {
      fontStyle: 'normal',
      fontSize: '22px',
      fontWeight: 400
    },
    subtitle1: {
      fontSize: '14px',
      fontWeight: 500,
      fontStyle: 'normal',
      '@media (min-width: 600px) and (max-width: 959.95px)': {
        "fontSize": "11px",
      }
    }
  },
  palette: {
    secondary: { main: '#006827' },
    error: { main: "#F00" }
  },
});

export const ProjectTaskTrackingWithStyles = withTranslation<ProjectTaskTracking>()(withStyles(webStyle)(ProjectTaskTracking));

const SvgIcons = {
  eyeIcon: <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
    <path d="M8.29208 9.76531C6.87563 9.76531 5.73102 8.60519 5.73102 7.16954C5.73102 5.73389 6.87563 4.57377 8.29208 4.57377C9.70853 4.57377 10.8531 5.73389 10.8531 7.16954C10.8531 8.60519 9.70853 9.76531 8.29208 9.76531Z" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14.8092 9.04743C15.453 8.02508 15.453 6.30665 14.8092 5.28429C13.1709 2.67402 10.8173 1.16586 8.29205 1.16586C5.76676 1.16586 3.41316 2.67402 1.77494 5.28429C1.13109 6.30665 1.13109 8.02508 1.77494 9.04743C3.41316 11.6577 5.76676 13.1659 8.29205 13.1659C10.8173 13.1659 13.1709 11.6577 14.8092 9.04743Z" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>,
  editIcon: <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path d="M7.5139 1.33953H6.13127C2.67469 1.33953 1.29205 2.72217 1.29205 6.17875V10.3266C1.29205 13.7832 2.67469 15.1659 6.13127 15.1659H10.2792C13.7357 15.1659 15.1184 13.7832 15.1184 10.3266V8.94401" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M5.55056 7.49226C5.34316 7.69965 5.13577 8.10753 5.09429 8.4048L4.79702 10.4857C4.68641 11.2392 5.21873 11.7646 5.97226 11.6609L8.05312 11.3636C8.34347 11.3222 8.75135 11.1148 8.96566 10.9074L14.4132 5.45979C15.3534 4.5196 15.7959 3.42732 14.4132 2.04468C13.0306 0.662052 11.9383 1.10449 10.9981 2.04468L5.55056 7.49226Z" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.217 2.8258C10.6802 4.47804 11.9729 5.77081 13.6321 6.2409" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>,
  deleteIcon: <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
    <path d="M13.2921 3.75285C11.0721 3.53835 8.83872 3.42785 6.61205 3.42785C5.29205 3.42785 3.97205 3.49285 2.65205 3.62285L1.29205 3.75285" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M4.95874 3.09636L5.10541 2.24486C5.21207 1.62736 5.29207 1.16586 6.41874 1.16586H8.16541C9.29207 1.16586 9.37874 1.65336 9.47874 2.25136L9.62541 3.09636" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11.8587 5.80687L11.4254 12.3524C11.3521 13.3729 11.2921 14.1659 9.43207 14.1659H5.15207C3.29207 14.1659 3.23207 13.3729 3.15874 12.3524L2.7254 5.80687" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6.17871 10.5909H8.39871" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M5.62537 7.99086H8.9587" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>,
  inviteIcon: <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
    <path d="M10.2921 12.6499H7.59241" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.9422 13.9624V11.3375" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M5.79031 6.98665C4.18401 6.93415 2.90841 5.65449 2.90841 4.07954C2.90166 2.47177 4.24475 1.16586 5.8983 1.16586C7.55184 1.16586 8.89493 2.47177 8.89493 4.07954C8.89493 5.65449 7.61259 6.93415 6.01303 6.98665C5.94554 6.98008 5.86455 6.98008 5.79031 6.98665Z" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M5.89837 14.1659C4.67002 14.1659 3.44841 13.864 2.51703 13.2603C0.883728 12.1972 0.883728 10.4647 2.51703 9.40818C4.37305 8.20071 7.41693 8.20071 9.27295 9.40818" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>,
  chipCancelIcon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path d="M21 11C21 5.5 16.5 1 11 1C5.5 1 1 5.5 1 11C1 16.5 5.5 21 11 21C16.5 21 21 16.5 21 11Z" fill="#67C375" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.16998 13.83L13.83 8.17004" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M13.83 13.83L8.16998 8.17004" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>,
  editHeaderIcon: <svg xmlns="http://www.w3.org/2000/svg" width="52" height="48" viewBox="0 0 52 48" fill="none">
    <rect x="0.5" y="0.5" width="51" height="47" rx="3.5" fill="#67C375" stroke="#67C375" />
    <path d="M23.666 12.2977H21.2958C15.3702 12.2977 13 14.668 13 20.5935V27.7042C13 33.6298 15.3702 36 21.2958 36H28.4065C34.332 36 36.7023 33.6298 36.7023 27.7042V25.334" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M20.3003 22.8452C19.9448 23.2008 19.5892 23.9 19.5181 24.4096L19.0085 27.9768C18.8189 29.2686 19.7314 30.1693 21.0232 29.9915L24.5904 29.4819C25.0882 29.4108 25.7874 29.0552 26.1548 28.6997L35.4935 19.361C37.1052 17.7493 37.8637 15.8768 35.4935 13.5066C33.1232 11.1363 31.2508 11.8948 29.639 13.5066L20.3003 22.8452Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M28.2999 14.8456C29.0939 17.678 31.3101 19.8942 34.1543 20.7001" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>,
  deleteConfirmIcon: <svg xmlns="http://www.w3.org/2000/svg" width="50" height="56" viewBox="0 0 50 56" fill="none">
    <path d="M49 11.7459C40.12 10.8549 31.1867 10.3959 22.28 10.3959C17 10.3959 11.72 10.6659 6.44 11.2059L1 11.7459" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15.6667 9.01899L16.2534 5.482C16.68 2.917 17 1 21.5067 1H28.4934C33 1 33.3467 3.025 33.7467 5.509L34.3334 9.01899" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M43.2667 20.278L41.5334 47.467C41.2401 51.706 41.0001 55 33.5601 55H16.4401C9.00006 55 8.76007 51.706 8.46673 47.467L6.7334 20.278" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M20.5467 40.15H29.4267" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18.3333 29.35H31.6666" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>,
  pasteIcon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
    <path d="M12 5C14 5 14 4 14 3C14 1 13 1 12 1H8C7 1 6 1 6 3C6 5 7 5 8 5H12Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14 3.02002C17.33 3.20002 19 4.43002 19 9.00002V15C19 19 18 21 13 21H7C2 21 1 19 1 15V9.00002C1 4.44002 2.67 3.20002 6 3.02002" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
}
// Customizable Area End
