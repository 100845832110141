import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment"
import { isTokenExpired, conditionSort, isArabicLang, handleLoader } from "../../../components/src/HelperMethonds"
// Customizable Area End 


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location?: any;
  classes?: any;
  WrappedComponent?: any;
  t: Function;
  i18n: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  taskListId: string;
  taskListSummary: any;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskGroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetTaskLIstSummaryCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      taskListId: this.props.navigation.getParam("boardName"),
      taskListSummary: [],
      isLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    this.handleTaskListSummaryData(from, message)
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };


  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    this.getData(this.props.navigation.getParam("boardName"))
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.navigation.getParam("boardName") !== prevProps.navigation.getParam("boardName")) {
      // Call the function when the URL changes
      this.getData(this.props.navigation.getParam("boardName"))
    }
  }

  openPdfFile = () => {
    window.open(`${require('../../../framework/src/config').baseURL}/${configJSON.tasklistPdfEndPoint}/${this.props.navigation.getParam("boardName")}?format=pdf&language=${this.props.i18n.language}`, '_blank');
  }

  formatDueDate = (inputDate: string) => {
    if (!inputDate) return "";
    let enDate = moment(inputDate).locale('en');
    const formattedDateEn = enDate.format('DD MMM, YYYY');
    const arabicDate = `${enDate.format("DD")} ${moment(inputDate).locale('ar').format("MMM")} ${enDate.format("YYYY")}`
    let formattedDate = conditionSort(isArabicLang(this.props.i18n.language), arabicDate, formattedDateEn)
    return formattedDate;
  };

  getData = async (id: string) => {
    handleLoader(this, true)
    const header = {
      "token": await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetTaskLIstSummaryCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/task_lists/${id}?is_report=true`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleTaskListSummaryData = (from: string, message: Message) => {
    if (this.apigetTaskLIstSummaryCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleLoader(this, false)
      if (apiResponse.data) {
        this.setState({ taskListSummary: apiResponse.data })
      } else {
        if (apiResponse.message == configJSON.noSuchTasklistFound) {
          this.props.navigation.navigate('ProjectTaskTracking')
        }
        isTokenExpired(apiResponse)
      }
    }
  }
  // Customizable Area End
}
