import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { handleLoader } from "../../../components/src/HelperMethonds";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  t: Function;
  i18n: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  popoveropen: any;
  anchorEl: any;
  Responsedata: any;
  contactData: any;
  FooterData: any;
  HeaderData: any;
  selectedOption: any;
  isDrawerOpen: boolean;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ConnectTOLandingPage)
    ];

    this.state = {
      popoveropen: false,
      anchorEl: null,
      Responsedata: [],
      contactData: "",
      FooterData: "",
      HeaderData: "",
      selectedOption: "",
      isDrawerOpen: false,
      isLoading: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.ConnectTOLandingPage)) {
      let data = message.getData(getName(MessageEnum.ShouldUpdate))
      if (data.refresh) {
        this.getlanguage()
      }
    }

    this.handleGetfooter(from, message)
    this.handleGetContact(from, message)
    this.handleResForGetLayoutContent(from, message)
    // Customizable Area End
  }

  // Customizable Area Start
  getEventApiCallId: any;
  getContactCallId: any;
  getFooterCallId: any;


  async componentDidMount() {
    super.componentDidMount();
    window.history.scrollRestoration = 'manual'
    this.getlanguage()
    this.getLayoutContent();
    this.getContctDetails();
    this.getFooterLinks();
  }



  renderAndCheckLine = (s1: string, s2?: string) => {
    const location = s1 && s2 ? `${s1}, ${s2}` : s1 || s2;
    return location ? <>{location}<br /></> : null;
  };

  getlanguage = async () => {
    let language = await getStorageData('language')
    if (language) {
      return this.setState({ selectedOption: language })
    }
    this.setState({ selectedOption: 'English' })
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleDrawer = (stats: boolean) => {
    this.setState({ isDrawerOpen: stats })
  }

  popoverhandleClose = () => {
    this.setState({ popoveropen: false });
  };

  popoverhandleOpen = (event: any) => {
    this.setState({ popoveropen: true, anchorEl: event.currentTarget });
  };

  handleRadioChange = (event: any) => {
    this.setState({ selectedOption: event.target.value });
    setStorageData('language', event.target.value)
  };

  handleRedirectionNewTab = (url: string) => {
    window.open(url, '_blank')
  }


  getLayoutContent = () => {
    handleLoader(this, true)
    const header = {
      "Content-Type": configJSON.exampleApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEventApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage/section_contents?language=${this.props.i18n.language}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getContctDetails = () => {
    handleLoader(this, true)
    const header = {
      "Content-Type": configJSON.exampleApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getContactCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage/footer_addresses?language=${this.props.i18n.language}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getFooterLinks = () => {
    handleLoader(this, true)
    const header = {
      "Content-Type": configJSON.exampleApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFooterCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage/footer_links?language=${this.props.i18n.language}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleGetfooter = (from: string, message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFooterCallId !== null &&
      this.getFooterCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      handleLoader(this, false)
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        this.setState({ FooterData: responseJson.data });
      }

    }
  }

  handleGetContact = (from: string, message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getContactCallId !== null &&
      this.getContactCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      handleLoader(this, false)
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        this.setState({ contactData: responseJson.data });
      }
    }
  }

  handleResForGetLayoutContent = (from: string, message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getEventApiCallId !== null &&
      this.getEventApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      handleLoader(this, false)
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        this.setState({ Responsedata: responseJson.data });
      }
    }
  }
  // Customizable Area End
}
