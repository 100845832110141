// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageTxtData from './LanguageTxtData';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    ...LanguageTxtData.englishTrl
                },
            },
            ar: {
                translation: {
                    ...LanguageTxtData.arabicTrl
                },
            },
        },
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        },
    });

export default i18n;
