import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

// Customizable Area Start
import { profileImg } from "./assets";
import { formatAndTranslateErrors, handleLoader } from "../../../components/src/HelperMethonds";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  t: Function;
  i18n: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  full_name: string;
  email: string;
  mobile: string;
  designation: string;
  department: string;
  isUpdate: boolean;
  userProfile: File | null;
  otpValue: string;
  modelOpen: boolean;
  previewURL: any;
  // headerProfile: any;
  enterOtp: boolean;
  countryCode: string;
  country_code: string;
  popoveropen: boolean;
  anchorEl: any;
  // language: any;
  name: string;
  isVerified: boolean;
  mobile_number: string;
  errors: {
    full_name: string;
    email: string;
    mobile: string;
    pin: string;
    avatar: string;
    color: string;
    designation: string;
    department: string;

  };
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileApiCallId: string = "";
  updateMobileApiCallId: string = "";
  updateProfileApiCallId: string = "";
  verifyOtpApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];
    // Customizable Area Start
    this.state = {
      full_name: "",
      email: "",
      mobile: "",
      name: "",
      mobile_number: "",
      designation: "",
      department: "",
      userProfile: null,
      isUpdate: false,
      otpValue: "",
      modelOpen: false,
      previewURL: profileImg,
      // headerProfile: profileImg,
      enterOtp: false,
      countryCode: "",
      country_code: "",
      popoveropen: false,
      anchorEl: null,
      // language: "English",
      isVerified: false,
      errors: {
        full_name: "",
        email: "",
        mobile: "",
        pin: "",
        avatar: "",
        color: "",
        designation: "",
        department: "",
      },
      isLoading: false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // Customizable Area Start

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getProfileApiCallId) {
          handleLoader(this, false)
          this.handleGetRequest(responseJson);
        } else if (apiRequestCallId === this.updateMobileApiCallId) {
          this.handleUpdateMobileRequest(responseJson);
        } else if (apiRequestCallId === this.updateProfileApiCallId) {
          this.handleUpdateProfileRequest(responseJson);
        } else if (apiRequestCallId === this.verifyOtpApiCallId) {
          this.handleVerfiyRequest(responseJson);
        }
      }
    }
    // Customizable Area End
  }

  handleGetRequest = (responseJson: any) => {
    if (responseJson.data) {
      if (responseJson.data.attributes) {
        const {
          full_name,
          phone_number,
          country_code,
          email,
          avatar,
          activated,
          department,
          designation,
        } = responseJson.data.attributes;

        this.setState({
          name: full_name,
          full_name: full_name,
          email: email,
          mobile: phone_number,
          mobile_number: phone_number,
          designation: designation,
          department: department,
          previewURL: avatar !== null ? avatar : profileImg,

          countryCode: country_code,
          country_code: country_code,
          isVerified: activated,
        });
      }
    }
  };

  handleVerfiyRequest = (responseJson: any) => {
    if (responseJson) {
      if (responseJson.meta) {
        if (responseJson.meta.token) {
          localStorage.setItem("authToken", responseJson.meta.token);
          this.setState({
            isVerified: true,
            isUpdate: false,
            mobile_number: responseJson.data.attributes.phone_number,
            otpValue: "",
            country_code: responseJson.data.attributes.country_code,
            enterOtp: false,
            errors: {
              ...this.state.errors,
              mobile: "",
              pin: "",
              color: "",
            },
          });
        }
      } else {
        let errorStr = formatAndTranslateErrors(responseJson.errors, this.props.t);
        let propertyName = Object.keys(responseJson.errors[0])[0]
        const updatedErrors = { ...this.state.errors, [propertyName]: errorStr };
        this.setState({ errors: updatedErrors });
      }
    }
  };

  handleUpdateProfileRequest = (responseJson: any) => {
    if (responseJson.data?.attributes) {
      this.handleModal(true);
    } else {
      let errorStr = formatAndTranslateErrors(responseJson.errors, this.props.t);
      let propertyName = Object.keys(responseJson.errors[0])[0]
      const updatedErrors = { ...this.state.errors, [propertyName]: errorStr };
      this.setState({ errors: updatedErrors });
    }
  };

  handleUpdateMobileRequest = (responseJson: any) => {
    if (responseJson) {
      if (responseJson.meta) {
        if (responseJson.meta.token) {
          localStorage.setItem("otpToken", responseJson.meta.token);
          const updatedErrors = { ...this.state.errors };
          updatedErrors.color = "green";
          updatedErrors.mobile = this.props.t('otpSentInfo')
          updatedErrors.pin = "";
          this.setState({
            enterOtp: true,
            errors: updatedErrors,
            otpValue: "",
          });
        }
      } else {
        let errorStr = formatAndTranslateErrors(responseJson.errors, this.props.t);
        const updatedAll = { mobile: errorStr };
        const updatedErrors = { ...this.state.errors, ...updatedAll };
        updatedErrors.color = "";
        this.setState({ enterOtp: false, errors: updatedErrors });
      }
    }
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getProfileData();
  }

  popoverhandleClose = () => {
    this.setState({ popoveropen: false });
  };

  popoverhandleOpen = (event: any) => {
    this.setState({ popoveropen: true, anchorEl: event.currentTarget });
  };


  handleSelectCountryCode = (e: any) => {
    this.setState({ countryCode: e.target.value });
  };

  conditionSort = (condition: any, trueResult: any, falseResult: any) => {
    return condition ? trueResult : falseResult;
  };

  preventPaste = (e: any, regex: any) => {
    const pastedText = e.clipboardData.getData("text/plain");
    if (!regex.test(pastedText)) {
      e.preventDefault();
    }
  };

  FormatError = (errors: any[]) => {
    return errors[0];
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      let url = URL.createObjectURL(files[0]);
      this.setState({
        userProfile: selectedFile,
        previewURL: url,
        errors: {
          ...this.state.errors,
          avatar: "",
        },
      });

      const fileName: any = selectedFile.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      if (
        fileExtension !== "jpeg" &&
        fileExtension !== "png" &&
        fileExtension !== "jpg"
      ) {
        this.setState({
          errors: {
            ...this.state.errors,
            avatar: this.props.t('profileImgValidMsg'),
          },
        });
      } else {
        this.setState({
          errors: {
            ...this.state.errors,
            avatar: "",
          },
        });
      }
    } else {
      const { name, value } = event.target;
      if (name === "full_name") {
        this.setState({
          full_name: value,
          errors: { ...this.state.errors, full_name: "" },
        });
        this.fullNameValidation(value);
      } else {
        this.setState((prevState) => ({
          ...prevState,
          [name]: value,
          errors: {
            ...this.state.errors,
            [name]: "",
          },
        }));
      }
    }
  };

  fullNameValidation = (value: any) => {
    if (
      !configJSON.fullNameRegex.test(value)
    ) {
      this.setState({
        errors: { ...this.state.errors, full_name: this.props.t('emptyNameValidationMsg') },
      });
    } else if (value.length > 40) {
      this.setState({
        errors: {
          ...this.state.errors,
          full_name: this.props.t('fullNamelengthMsg'),
        },
      });
    } else {
      this.setState({ errors: { ...this.state.errors, full_name: "" } });
    }
  };

  handleChangeDepartment = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
      errors: { ...this.state.errors, department: "" },
    }));

    if (value.length > 40) {
      this.setState({
        errors: {
          ...this.state.errors,
          department: this.props.t('departmentLengthMsg'),
        },
      });
    }
  };

  handleChangeDesignation = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
      errors: { ...this.state.errors, designation: "" },
    }));

    if (value.length > 40) {
      this.setState({
        errors: {
          ...this.state.errors,
          designation: this.props.t('designationLengthMsg'),
        },
      });
    }
  };

  handleUpdateStatus = () => {
    //this is update the status of isUpdate
    this.setState((prevState) => ({
      ...prevState,
      isUpdate: true,
      isVerified: false,
    }));
  };

  handleChangeOTP = (value: any) => {
    this.setState({
      otpValue: value,
    });
  };

  handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const { full_name } = this.state;
    const errors: any = {};

    // Validate full name
    if (full_name.trim() === "") {
      errors.full_name = this.props.t('fullNameReqValidMsg');
    } else if (
      !configJSON.fullNameRegex.test(
        full_name
      )
    ) {
      errors.full_name = this.props.t('emptyNameValidationMsg');
    } else if (this.state.errors.avatar) {
      errors.userProfile = this.state.errors.avatar;
    }

    if (
      Object.keys(errors).length === 0 &&
      Object.values(this.state.errors).every((error) => error === "")
    ) {
      this.updateProfileData();
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          ...errors,
        },
      });
    }
  };

  handleSendOtp = () => {
    const { mobile } = this.state;
    if (!mobile) {
      this.setState({
        errors: {
          ...this.state.errors,
          mobile: this.props.t('mobileNoReqMsg'),
        },
      });
    } else {
      this.updateProfileMobile();
    }
  };

  handleModal = (status: boolean) => {
    this.setState({ modelOpen: status });
  };

  handleVerfiy = () => {
    if (this.state.otpValue.length === 5) {
      this.verifyOtp();
      this.setState({
        errors: {
          ...this.state.errors,
          mobile: "",
        },
      });
    } else {
      const errors = { ...this.state.errors };
      errors.pin = this.props.t('invalidOtpMsg');
      this.setState({ errors });
    }
  };

  getProfileData = () => {
    handleLoader(this, true)
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProfileApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetProfile
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.profileApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  updateProfileMobile = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const httpBody = {
      updated_phone_number: `${this.state.countryCode
        .toString()
        .replace(/-/g, "")}${this.state.mobile}`,
        language: this.props.i18n.language,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateMobileApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlUpdateMobile
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.mobileUpdateApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  updateProfileData = () => {
    const headers = {
      token: localStorage.getItem("authToken"),
    };
    const { full_name, userProfile, email, designation, department } =
      this.state;

    const formData = new FormData();
    formData.append("full_name", full_name.trim());
    formData.append("email", email.trim());
    formData.append("designation", designation.trim());
    formData.append("department", department.trim());
    formData.append("language", this.props.i18n.language);
    userProfile && formData.append("avatar", userProfile);

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateProfileApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlUpdateProfile
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.mobileUpdateApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  verifyOtp = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("otpToken"),
    };

    const data = {
      pin: this.state.otpValue,
      update: true,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyOtpApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlVerifyOtp
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.verifyOtpAPiMethod
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };
  // web events

  // Customizable Area Start
  // Customizable Area End
}
