export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const calendarLogo = require("../assets/calander.png");
export const userAdd = require("../assets/group_Add user.png");
export const deleteBin = require("../assets/group_bin.png");
export const edit = require("../assets/group_Edit Icon.png");
export const check = require("../assets/group_icon.png");
export const deleteIcon = require("../assets/iconDelete.png");
export const eye = require("../assets/eye.png");
export const eye_green = require("../assets/eye_2.png");
export const printer = require("../assets/printer.png");
export const addCircle = require("../assets/addCircle.png");
export const downArrow = require("../assets/view_vector.png");
export const addCircleGreen = require("../assets/addCircleGreen.png");
export const greenCalender = require("../assets/greenCalnder.png");
export const printIcon = require("../assets/print.png");

