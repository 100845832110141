Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TaskGroups";
exports.labelBodyText = "TaskGroups Body";

exports.btnExampleTitle = "CLICK ME";
exports.tasklistPdfEndPoint = "bx_block_print2/prints/generate_task_list_report_pdf";
exports.noSuchTasklistFound = "No such task list ID found"
// Customizable Area End