import React from "react";

// Customizable Area Start
import AccountAuth from "../../mobile-account-login/src/AccountLogin.web";
// Customizable Area End

import AdditionalDetailFormController, {
    Props
} from "./AdditionalDetailFormController";

class AccountSignUp extends AdditionalDetailFormController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            <AccountAuth navigation={this.props.navigation} id={"AccountSignUp"} signUpType={true} />
        );
    }
}

// Customizable Area Start
export default AccountSignUp;
// Customizable Area End