Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.loginAPiEndPoint = "account_block/accounts/send_otp";
exports.verifyOTPAPiEndPoint = "account_block/accounts/otp_verification";
exports.signUpAPiEndPoint = "account_block/accounts";
exports.getAllTermsCondsApiEndPoint = "bx_block_terms_and_conditions/terms_and_conditions"
exports.loginAPiMethod = "POST";
exports.getApiMethod = "GET";
exports.loginApiContentType = "application/json";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.placeHolderMobile = "Mobile";
exports.labelForgotPassword = "Forgot password ?";
exports.btnTxtEmailLogin = "LOGIN WITH EMAIL";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Mobile no. not valid.";
exports.errorTitle = "Error";
exports.labelTitle = "Log in";
exports.fullNameRegex = /^(?!^\s*[\u0600-\u06FFa-zA-Z,'-]+\s*$)[\u0600-\u06FFa-zA-Z,'-]+(\s[\u0600-\u06FFa-zA-Z,'-]+)*\s*$/
// Customizable Area End
