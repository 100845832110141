Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.PatchAPiMethod = "PATCH";
exports.DeleteAPiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProjectTaskTracking";
exports.labelBodyText = "ProjectTaskTracking Body";
exports.createBoard = "bx_block_tasks/boards";
exports.boardActionEndPoint = "bx_block_tasks/boards";
exports.getUserEndPoint = "bx_block_customisableuserprofiles/customisableuserprofiles/get_profile";
exports.getBoardRequestEndPoint = "bx_block_request_management/requests"
exports.btnExampleTitle = "CLICK ME";

exports.createBoardText = "Create Board" //temp
exports.validationBoardNameReq = "Board Name is Required"
exports.validationBoardNameLength = "Board Name should not be more than 25 length"
exports.validationBoardDesReq = "Board Description is Required"
exports.boardNameInputLabel = "Board Name *"
exports.boardNameInputPlaceholder = "Enter Board Name"
exports.boardDesInputLabel = "Board Description *"
exports.boardDesPlaceholder = "Description"
exports.boardIconInputLabel = "Board Icon"
exports.boardIconInputPlaceholder = "Upload Board Icon"
exports.inviteMembersInputLabel = "Invite Members"
exports.inviteMembersInputPlaceholder = "Enter mobile to invite"
exports.infoMessageInviteNumber = `Enter mobile number with country code, excluding the "+" (e.g., 91).`

exports.editModalHeader = "You can edit board and invite peoples and assign tasks to do."
exports.editModalBtnText = "Update Board"

exports.acceptDeleteBtnTxt = "Yes, Delete"
exports.noBtnTxt = "No"

exports.GenBoardMemberListID = "GenBoardMemberList"
exports.GenEditBoardID = "GenEditBoard"
exports.GenConfirmDeleteBoardID = "GenConfirmDeleteBoard"
exports.GenCreateBoardID = "GenCreateBoard"
exports.GenInviteMemberID = "GenInviteMember"
exports.GenSucessfullDeleteBoardID = "GenSucessfullDeleteBoard"
exports.GenSucessfullDeleteMemberID = "GenSucessfullDeleteMember"
exports.GenConfirmDeleteMemberID = "GenConfirmDeleteMember"


exports.actionBoardMemberEndpoint = "bx_block_tasks/board_memberships"
exports.BoardAndTaskReqAPiEndPoint = "bx_block_tasks/board_memberships/handle_board_task_request"
// Customizable Area End