import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { parsePhoneNumber } from "awesome-phonenumber";
import {
  getStorageData,
  removeStorageData,
  setStorageData
} from "../../../framework/src/Utilities";
import { formatAndTranslateErrors, handleLoader } from "../../../components/src/HelperMethonds";
// Customizable Area End

const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  signUpType?: boolean;
  classes?: any;
  t: Function;
  i18n: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  countryCodeSelected: string;
  mobileNo: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;

  placeHolderCountryCode: string;
  placeHolderMobile: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  labelForgotPassword: string;
  btnTxtEmailLogin: string;
  otpValue: string;
  otpError: any;
  otpSentNo: string;
  mobileNoError: any;
  isModalOpen: boolean;
  full_name: string;
  fullNameError: any;
  boardID: any;
  invitationTaskId: any;
  isLoading: boolean;
  tncPopup:boolean;
  TncData:any;
  tncChecked:boolean;
  TncError:any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class MobileAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiPhoneLoginCallId: any;
  apiVerifyOTPCallID: any;
  apiSignUpOTPCallID: any;
  labelTitle: string;
  getTermsCondsListCallId:any
  private readonly errorTitle = "Error";
  // Customizable Area End      

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      countryCodeSelected: "91",
      mobileNo: "",
      password: "",
      placeHolderCountryCode: "Select Country",
      enablePasswordField: true,
      checkedRememberMe: false,

      placeHolderMobile: configJSON.placeHolderMobile,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      labelForgotPassword: configJSON.labelForgotPassword,
      btnTxtEmailLogin: configJSON.btnTxtEmailLogin,
      otpValue: "",
      otpError: {
        message: "",
        type: ""
      },
      mobileNoError: {
        message: "",
        type: ""
      },
      fullNameError: {
        message: "",
        type: ""
      },
      isModalOpen: false,
      full_name: "",
      otpSentNo: "--",
      boardID: "",
      invitationTaskId: "",
      isLoading: false,
      tncPopup:false,
      TncData:{},
      tncChecked:false,
      TncError:'',
    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.checkTaskandBoardId();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    this.getTncData();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResForVerifyOTP(from, message);
    this.handleResForSignUp(from, message);
    this.handleResForTnc(from, message);
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      if (countryCode && userName && password) {
        this.setState({
          mobileNo: userName,
          placeHolderCountryCode: "+" + countryCode,
          countryCodeSelected: countryCode,
          password: password,
          checkedRememberMe: true
        });
      }
    } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiPhoneLoginCallId !== null &&
      this.apiPhoneLoginCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apiPhoneLoginCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      handleLoader(this, false)
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);

        //Need To send Login token message to save for future call
        // this.saveLoggedInUserData(responseJson);
        // this.sendLoginSuccessMessage();
        // this.openInfoPage();
        setStorageData("otpToken", responseJson.meta.token);
        this.setState({
          otpSentNo: `${this.state.countryCodeSelected
            }${this.state.mobileNo.trim()}`,
          mobileNoError: {
            type: "success",
            message: this.props.t('otpSentSuccessMsg')
          },
          otpError: { type: "", message: "" },
          otpValue: ""
        });
      } else {
        //Check Error Response
        // this.sendLoginFailMessage();
        // this.parseApiErrorResponse(responseJson);
        const errorStr = formatAndTranslateErrors(responseJson.errors, this.props.t);
        this.setState({ mobileNoError: { type: "error", message: errorStr } });
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  checkTaskandBoardId = async () =>{
    const boardID = this.props.navigation.getParam("id");
    const invitationTaskId = this.props.navigation.getParam("taskId");
    const token = await getStorageData("authToken")
    if(token && (boardID || invitationTaskId)){
        if(boardID){
         return this.props.navigation.navigate('ProjectTaskTrackingBoardID', {id:boardID});
        }
        if(invitationTaskId){
         return this.props.navigation.navigate('ProjectTaskTrackingTaskID', {taskId:invitationTaskId});
        }
    }
    this.getBoardAndTaskIDs();
  }

  getBoardAndTaskIDs = () => {
    const boardIdFromStorage = sessionStorage.getItem("invitationBoardId");
    const taskIdFromStorage = sessionStorage.getItem("invitationTaskId");

    const boardID = this.props.navigation.getParam("id") || boardIdFromStorage;
    const invitationTaskId =
      this.props.navigation.getParam("taskId") || taskIdFromStorage;

    sessionStorage.setItem("invitationBoardId", boardID);
    sessionStorage.setItem("invitationTaskId", invitationTaskId);

    this.setState({ boardID, invitationTaskId });
  };
  getTncData = () =>{
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsCondsListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
       `${configJSON.getAllTermsCondsApiEndPoint}?type=terms_and_conditions&language=${this.props.i18n.language}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }
  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.mobileNo);

    msg.addData(
      getName(MessageEnum.LoginCountryCode),
      this.state.countryCodeSelected
    );

    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "sms");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToEmailLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doMobileLogIn(loginType?: any): boolean {
    if (loginType === false) {
      this.setState({ mobileNoError: { type: "", message: "" }, otpValue: "" });
    }

    if (this.state.mobileNo === null || this.state.mobileNo.length === 0) {
      this.setState({
        mobileNoError: { type: "error", message: this.props.t('emptyPhoneNoValidationMsg') }
      });
      return false;
    }

    if (
      !parsePhoneNumber(
        `+${this.state.countryCodeSelected.replace(
          /-/g,
          ""
        )}${this.state.mobileNo.trim()}`
      ).valid
    ) {
      this.setState({
        mobileNoError: { type: "error", message: this.props.t('invalidPhoneNoValidationMsg') }
      });
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType
    };

    const attrs = {
      full_phone_number: `${this.state.countryCodeSelected.replace(
        /-/g,
        ""
      )}${this.state.mobileNo.trim()}`,
      login: loginType || false,
      language: this.props.i18n.language
    };

    const data = {
      type: "sms_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    handleLoader(this, true)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPhoneLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  preventEType = (e: any, regex: any, allowSpace?: boolean) => {
    let allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab", "Shift"];
    allowedKeys = this.conditionSort(
      allowSpace,
      [...allowedKeys, " "],
      allowedKeys
    );

    if (
      (e.ctrlKey || e.metaKey) &&
      (e.key === "a" || e.key === "x" || e.key === "v" || e.key === "c")
    ) {
      return false;
    }

    return (
      (allowedKeys.includes(e.key) ? false : !regex.test(e.key)) &&
      e.preventDefault()
    );
  };

  preventPaste = (e: any, regex: any) => {
    const pastedText = e.clipboardData.getData("text/plain");
    if (!regex.test(pastedText)) {
      e.preventDefault();
    }
  };

  handleChangeOTP = (value: any) => {
    this.setState({
      otpValue: value,
      otpError: ""
    });
    if (this.state.mobileNoError.type == "success") {
      this.setState({
        mobileNoError: { ...this.state.mobileNoError, message: "" }
      });
    }
  };

  handleSelectCountryCode = (e: any) => {
    this.setState({ countryCodeSelected: e.target.value });
  };

  conditionSort = (condition: any, trueResult: any, falseResult: any) => {
    return condition ? trueResult : falseResult;
  };

  handleNavigate = (path: string) => {
    this.props.navigation.navigate(path);
  };

  handleNavigateAfterloggin = (Redirection: string) => {
    this.props.navigation.navigate(Redirection);
  };

  handleMobileNoInput = (e: any) => {
    this.setState({
      mobileNo: e.target.value,
      mobileNoError: { type: "", message: "" }
    });
  };

  handleFullNameInput = (e: any) => {
    this.setState({
      full_name: e.target.value,
      fullNameError: { type: "", message: "" }
    });
    if (
      !configJSON.fullNameRegex.test(
        e.target.value
      )
    ) {
      this.setState({
        fullNameError: { type: "error", message: this.props.t('emptyNameValidationMsg') }
      });
    } else {
      this.setState({ fullNameError: { type: "", message: "" } });
    }
  };

  handleModal = (status: boolean, refresh?: boolean) => {
    this.setState({ isModalOpen: status });
    if (refresh) {
      window.location.reload();
    }
  };

  handleLoginSignUp = () => {
    if (this.props.signUpType) {
      this.doSignUp();
    } else {
      this.doMobileLogIn(true);
    }
  };

  async doVerifyOTP(): Promise<boolean> {
    if (
      this.state.otpValue === null ||
      this.state.otpValue.length === 0 ||
      this.state.otpValue.length < 5
    ) {
      this.setState({
        otpError: { type: "error", message: this.props.t('emptyOtpValidationMsg') }
      });
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: await getStorageData("otpToken")
    };

    let httpBody: any = {
      pin: this.state.otpValue,
      update: false,
      language: this.props.i18n.language,
    };
    if (this.state.boardID && this.state.boardID != 'null') {
      httpBody = { ...httpBody, board_id: this.state.boardID };
    }

    if (this.state.invitationTaskId && this.state.invitationTaskId != 'null') {
      httpBody = { ...httpBody, task_id: this.state.invitationTaskId };
    }
    handleLoader(this, true)
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiVerifyOTPCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyOTPAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  doSignUp(): boolean {
    if (this.state.full_name === null || this.state.full_name.length === 0) {
      this.setState({
        fullNameError: { type: "error", message: this.props.t('invalidFullNameMsg') }
      });
      return false;
    }

    if (
      !configJSON.fullNameRegex.test(
        this.state.full_name
      )
    ) {
      this.setState({
        fullNameError: { type: "error", message: this.props.t('emptyNameValidationMsg') }
      });
      return false;
    }

    if (this.state.mobileNo === null || this.state.mobileNo.length === 0) {
      this.setState({
        mobileNoError: { type: "error", message: this.props.t('emptyPhoneNoValidationMsg') }
      });
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType
    };

    if (
      !parsePhoneNumber(
        `+${this.state.countryCodeSelected.replace(
          /-/g,
          ""
        )}${this.state.mobileNo.trim()}`
      ).valid
    ) {
      this.setState({
        mobileNoError: { message: this.props.t('invalidPhoneNoValidationMsg'), type: "error" }
      });
      return false;
    }

    const attrs = {
      full_phone_number: `${this.state.countryCodeSelected.replace(
        /-/g,
        ""
      )}${this.state.mobileNo.trim()}`,
      full_name: this.state.full_name.trim(),
      language: this.props.i18n.language
    };

    const data = {
      type: "sms_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };
    handleLoader(this, true)
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSignUpOTPCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleResForVerifyOTP = (from: string, message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiVerifyOTPCallID !== null &&
      this.apiVerifyOTPCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleLoader(this, false)
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        setStorageData("authToken", responseJson.meta.token);
        sessionStorage.clear();
        removeStorageData("otpToken");
        this.handleModal(true);
      } else {
        const errorStr = formatAndTranslateErrors(responseJson.errors, this.props.t);
        this.setState({ otpError: { type: "error", message: errorStr } });
      }
    }
  };

  handleResForSignUp = (from: string, message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiSignUpOTPCallID !== null &&
      this.apiSignUpOTPCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleLoader(this, false)
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        setStorageData("otpToken", responseJson.meta.token);
        this.setState({
          otpSentNo: `${this.state.countryCodeSelected
            }${this.state.mobileNo.trim()}`,
          mobileNoError: {
            type: "success",
            message: this.props.t('otpSentSuccessMsg')
          },
          otpValue: ""
        });
      } else {
        const errorStr = formatAndTranslateErrors(responseJson.errors, this.props.t);
        this.setState({ mobileNoError: { type: "error", message: responseJson.errors[0].full_phone_number ??  errorStr} });
      }
    }
  };
  handleResForTnc = (from: string, message: Message) =>{
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTermsCondsListCallId !== null &&
      this.getTermsCondsListCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ){
      this.getTermsCondsListCallId = null;

      let response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(!response.errors){
        this.setState({
          TncData: response.data,
        })
      }
    }
  }
  handleTncPopup = () => {
    this.setState((prevState) => ({
        tncPopup: !prevState.tncPopup
    }));
  };
  
  handleClosePopup = () =>{
    this.setState({
      tncPopup: false,
    })
  }
  // Customizable Area End
}
