import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  ButtonBase,
  Popover,
  Dialog,
  Radio,
  FormControlLabel,
  Drawer,
  IconButton,
  Avatar,
  SvgIcon,
  Tooltip
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { LandingPageLogo, globe, globeWhite } from "./assets";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MenuIcon from "@material-ui/icons/Menu";
import { withTranslation } from 'react-i18next';
import { isArabicLang, conditionSort } from "../../../components/src/HelperMethonds";
// Customizable Area Start

import HeaderController, { Props } from "./HeaderController";

export class Header extends HeaderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  GenSucessfullDeactiveAccount = (t: Function) => {
    return (
      <>
        <CheckCircleIcon
          className={this.props.classes.deactiveSuccessfulIcon}
        />

        <Typography
          style={{ marginTop: "40px" }}
          align="center"
          variant="body2"
          gutterBottom
          dangerouslySetInnerHTML={{ __html: t("successDeactiveAccountMsg") }}
        />

        <Button
          className={this.props.classes.goToBtn}
          data-test-id="btnHomePage"
          onClick={() => this.handleNavigate("LandingPage")}
          variant="contained"
        >
          {t('goToHomePageTxt')}
        </Button>
      </>
    );
  };

  GenConfirmDeactiveAccount = (t: Function) => {
    return (
      <>
        <Box className={this.props.classes.deleteIconContainer}>
          {SvgIcons.deleteConfirmIcon}
        </Box>

        <Typography
          style={{ marginTop: "40px" }}
          align="center"
          variant="body2"
          gutterBottom
          dangerouslySetInnerHTML={{ __html: t("confirmDeActiveAccount") }}
        />

        <Box className={this.props.classes.actionBtnContainer}>
          <Button
            data-test-id="btnConfirmDeactivate"
            onClick={() =>
              this.accountDeactivate()
            }
            className={this.props.classes.confirmDeleteBtn}
            variant="contained"
          >
            {t('yesDeactiveTxt')}
          </Button>
          <Button
            data-test-id="btnCancelDeactivate"
            className={this.props.classes.cancelDeleteBtn}
            onClick={() => this.handleModalAppearance("", false)}
            variant="outlined"
          >
            {t('noBtnTxt')}
          </Button>
        </Box>
      </>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      hideSiteLogo,
      enableDrawer,
      WrappedComponent,
      whiteBackground,
      enableSidebarDrawer,
      t,
      i18n,
      ...otherProps
    } = this.props;
    const { HeaderData, token } = this.state;
    // Customizable Area End 

    return (
      // Customizable Area Start
      <ThemeProvider theme={conditionSort(isArabicLang(i18n.language), ArabicTheme, theme)}>
        <Grid
          container
          className={this.conditionSort(
            enableDrawer,
            classes.container,
            classes.containerNoDrawer
          )}
        >
          <Grid
            item
            xs={12}
            style={{
              background: this.conditionSort(whiteBackground, "#fff", "#2283C0")
            }}
            className={classes.headerContainer}
          >
            <Box
              className={this.conditionSort(
                enableDrawer,
                classes.topBarStyle,
                classes.topBarStyleNoDrawer
              )}
            >
              <Box
                className={this.conditionSort(
                  enableDrawer,
                  classes.barSectionOne,
                  classes.barSectionOneNoDrawer
                )}
              >
                {!hideSiteLogo && (
                  <ButtonBase
                    data-test-id="webLogoImg"
                    disableRipple
                    onClick={() => this.handleLogoNavigate("ProjectTaskTracking")}
                  >
                    <img src={LandingPageLogo} className={classes.siteLogo} />
                  </ButtonBase>
                )}
                {HeaderData &&
                  HeaderData.data.map((HeaderLink: any, index: any) => (
                    <Typography
                      color={this.conditionSort(
                        whiteBackground,
                        "textPrimary",
                        "textSecondary"
                      )}
                      style={{ cursor: "pointer" }}
                      data-test-id="headerLink"
                      onClick={() =>
                        this.handleRedirectionNewTab(
                          HeaderLink.attributes.page_url
                        )
                      }
                      key={index}
                      variant="h4"
                    >
                      {HeaderLink.attributes.name}
                    </Typography>
                  ))}
              </Box>

              {enableDrawer && (
                <IconButton
                  data-test-id="moreIconBtn"
                  onClick={() => this.handleDrawer(true)}
                  className={classes.menuButton}
                >
                  <MenuIcon className={classes.menuButtonIcon} />
                </IconButton>
              )}

              {enableSidebarDrawer && (
                <IconButton
                  data-test-id="mainLayoutSidebarToggle"
                  onClick={this.handleDrawerMainLayout}
                  className={classes.menuButton4Siderbar}
                >
                  <MenuIcon className={classes.menuButtonIcon4Sidebar} />
                </IconButton>
              )}

              <Box className={classes.barSectionTwo}>

                <Box className={classes.alignIconMessage}>
                  <img
                    className={classes.languageImg}
                    src={this.conditionSort(whiteBackground, globe, globeWhite)}
                  />
                  <Typography
                    color={this.conditionSort(
                      whiteBackground,
                      "textPrimary",
                      "textSecondary"
                    )}
                    className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)}
                    variant="h5"
                  >
                    {t(this.state.selectedOption)}
                  </Typography>
                  <Box>
                    <ButtonBase
                      className={this.conditionSort(this.state.anchorEl.language, classes.rotateTo180Deg, '')}
                      data-test-id="popOverLangaugeBtn"
                      disableRipple
                      onClick={e => this.popoverhandleOpen(e, "language")}
                    >
                      <SvgIcon className={classes.arrowDownLangaugeRage} viewBox="0 0 22 24">
                        {this.conditionSort(
                          whiteBackground,
                          SvgIcons.arrowDownLangauge,
                          SvgIcons.arrowDownLangaugeWhite
                        )}
                      </SvgIcon>
                    </ButtonBase>
                    <Popover
                      data-test-id="popoverLanguageContainer"
                      open={Boolean(this.state.anchorEl.language)}
                      onClose={this.popoverhandleClose}
                      anchorEl={this.state.anchorEl.language}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: this.conditionSort(isArabicLang(i18n.language), 'right', 'left')
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: this.conditionSort(isArabicLang(i18n.language), 'left', 'right')
                      }}
                      className={classes.popOverMainStyle}
                    >
                      <Box className={classes.popoverStyle}>
                        {this.state.languageOptions.length && this.state.languageOptions.map((label: any) => (
                          label.attributes.active && <FormControlLabel
                            className={this.conditionSort(isArabicLang(i18n.language), classes.languageOptionArabicAlter, '')}
                            name={label.attributes.language_code}
                            key={label.id}
                            data-test-id="radioTestId"
                            control={
                              <Radio
                                color="primary"
                                value={label.attributes.language_code}
                                onClick={this.handleRadioChange}
                                classes={{ colorPrimary: classes.colorBlack }}
                                checkedIcon={
                                  <CheckCircleIcon
                                    className={classes.languageSelectedIcon}
                                  />
                                }
                                checked={this.state.selectedOption == label.attributes.language_code}
                              />
                            }
                            label={
                              <Typography
                                className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)}
                                variant="body1"
                              >
                                {t(label.attributes.language_code)}
                              </Typography>
                            }
                          />
                        ))}
                      </Box>
                    </Popover>
                  </Box>
                </Box>

                {!token && (
                  <Link to="/AccountLogin">
                    <Button
                      variant="contained"
                      data-test-id="LoginSignUp"
                      className={classes.logSignBtn}
                    >
                      {t('loginSignupBtnTxt')}
                    </Button>
                  </Link>
                )}

                {token && (
                  <Typography
                    color={this.conditionSort(
                      whiteBackground,
                      "textPrimary",
                      "textSecondary"
                    )}
                    className={classes.alignIconMessage}
                    variant="h4"
                  >
                    <Avatar
                      data-test-id="popoverProfileBtn"
                      onClick={e => this.popoverhandleOpen(e, "profile")}
                      src={this.state.userData.avatar}
                      className={classes.topBarProfilelogo}
                    />

                    <Tooltip
                      title={
                        <Typography
                          variant="h4"
                          className={classes.toolTipStyle}
                        >
                          {" "}
                          {this.state.userData.full_name}
                        </Typography>
                      }
                      enterTouchDelay={0}
                    >
                      <span className={classes.profileName}>
                        {this.state.userData.full_name}
                      </span>
                    </Tooltip>
                  </Typography>
                )}

                <Popover
                  data-test-id="popoverProfileContainer"
                  open={Boolean(this.state.anchorEl.profile)}
                  onClose={this.popoverhandleClose}
                  anchorEl={this.state.anchorEl.profile}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                  }}
                  className={classes.popOverMainStyle}
                >
                  <Box className={classes.profilePopoverContainer}>
                    <Box className={classes.popoverSecOne}>
                      <Avatar
                        src={this.state.userData.avatar}
                        className={classes.popoverProfileImg}
                      />
                      <Typography style={{ textTransform: "capitalize" }} variant="caption">
                        {this.state.userData.full_name}
                      </Typography>
                      <Typography style={{ color: "rgba(27, 27, 27, 0.50)", direction: "ltr" }} variant="subtitle2">
                        {`+${this.state.userData.country_code} ${this.state.userData.phone_number
                          }`}
                      </Typography>
                    </Box>
                    <Box className={classes.popoverSecTwo}>
                      <Typography
                        style={{ cursor: "pointer" }}
                        data-test-id="profileNavBtn"
                        onClick={() =>
                          this.handleNavigate("CustomisableUserProfiles")
                        }
                        className={this.conditionSort(isArabicLang(i18n.language), `${classes.alignIconMessage} ${classes.alignIconMessageArabicAlter}`, classes.alignIconMessage)}
                        variant="caption"
                      >
                        {SvgIcons.popoverEditIcon}
                        {t('editProfileTxt')}
                      </Typography>
                      <Button
                        data-test-id="deactiveBtn"
                        onClick={() =>
                          this.handleModalAppearance(
                            "GenConfirmDeactiveAccount",
                            true
                          )}
                        fullWidth
                        className={classes.deactiveBtn}
                      >
                        {t('deActiveBtnTxt')}
                      </Button>
                    </Box>
                  </Box>
                </Popover>
              </Box>
            </Box>

            <Drawer
              data-test-id="drawerContainer"
              anchor={this.conditionSort(isArabicLang(i18n.language), 'right', 'left')}
              open={this.state.isDrawerOpen}
              variant="temporary"
              onClose={() => this.handleDrawer(false)}
            >
              <Box className={classes.drawerContainer}>
                <Box className={classes.drawerSecOne}>
                  <Box>
                    <img onClick={() => this.handleLogoNavigate("ProjectTaskTracking")} src={LandingPageLogo} className={classes.siteLogo} />
                  </Box>
                  <br />
                  {HeaderData &&
                    HeaderData.data.map((HeaderLink: any, index: any) => (
                      <Typography
                        data-test-id="headerLink"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.handleRedirectionNewTab(
                            HeaderLink.attributes.page_url
                          )
                        }
                        variant="h4"
                        key={index}
                      >
                        {HeaderLink.attributes.name}
                      </Typography>
                    ))}
                </Box>
              </Box>
            </Drawer>
          </Grid>

          <WrappedComponent {...otherProps} />
        </Grid>

        <Dialog
          data-test-id="modalConatiner"
          fullWidth
          maxWidth="sm"
          open={this.state.modalStats.isOpen}
          onClose={() => this.handleModalAppearance("", false)}
          PaperProps={{
            style: { borderRadius: "20px", width: "509px", minHeight: "462px", display: "grid" }
          }}
        >
          <Grid
            className={classes.modalContainer}
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
          >
            <this.ConditionalPanel
              conditionArray={[
                this.state.modalStats.type == "GenConfirmDeactiveAccount"
              ]}
            >
              {this.GenConfirmDeactiveAccount(t)}
            </this.ConditionalPanel>

            <this.ConditionalPanel
              conditionArray={[
                this.state.modalStats.type == "GenSucessfullDeactiveAccount"
              ]}
            >
              {this.GenSucessfullDeactiveAccount(t)}
            </this.ConditionalPanel>
          </Grid>
        </Dialog>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle: any = (theme: any) => ({
  arabicFontRegular: {
    fontFamily: "GeezaPro Regular",
  },
  container: {
    width: "100vw",
    overflowX: "hidden"
  },
  containerNoDrawer: {
    width: "100%",
    overflowX: "hidden"
  },
  drawerContainer: {
    padding: "30px"
  },
  //Header
  headerContainer: {
    height: "164px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: "1px solid #979797",
    position: "sticky",
    top: "0",
    zIndex: "99",
    "@media (min-width: 900px) and (max-width: 1200px)": {
      height: "164px"
    },
    "@media (min-width: 0px) and (max-width: 900px)": {
      height: "78px"
    }
  },
  languageImg: {
    "@media (min-width: 0px) and (max-width: 600px)": {
      height: "20px",
      width: "20px"
    }
  },
  arrowDownLangaugeRage: {
    "@media (min-width: 0px) and (max-width: 600px)": {
      height: "20px",
      width: "20px"
    }
  },
  topBarStyle: {
    width: "88%",
    display: "flex",
    justifyContent: "space-between"
  },
  topBarStyleNoDrawer: {
    width: "95%",
    display: "flex",
    justifyContent: "space-between"
  },
  siteLogo: {
    width: "112px",
    height: "135px",
    "@media (max-width: 1500px)": {
      width: "100px",
      height: "120px"
    },
    "@media (max-width: 1400px)": {
      width: "100px",
      height: "120px"
    },
    "@media (max-width: 1300px)": {
      width: "100px",
      height: "120px"
    },
    "@media (max-width: 1200px)": {
      width: "100px",
      height: "120px"
    }
  },
  barSectionOne: {
    display: "flex",
    alignItems: "center",
    gap: "58px",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  barSectionOneNoDrawer: {
    display: "flex",
    alignItems: "center",
    gap: "50px",
    '@media (min-width: 0px) and (max-width: 1020px)': {
      display: "none"
    }
  },
  barSectionTwo: {
    display: "flex",
    alignItems: "center",
    gap: "28px"
  },
  menuButton: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "inline"
    }
  },
  menuButton4Siderbar: {
    display: "none",
    '@media (min-width: 0px) and (max-width: 1020px)': {
      display: "initial"
    }
  },
  drawerSecOne: {
    display: "flex",
    flexDirection: "column",
    gap: "20px"
  },
  menuButtonIcon: {
    fontSize: "40px"
  },
  menuButtonIcon4Sidebar: {
    fontSize: "40px",
    color: "#fff"
  },
  alignIconMessage: {
    gap: "8px",
    display: "flex",
    alignItems: "center"
  },
  alignIconMessageArabicAlter: {
    gap: "16%",
  },
  fontRegular: {
    fontFamily: "HelveticaNeue Regular"
  },
  popOverMainStyle: {
    margin: "25px 0 0 0"
  },
  popoverStyle: {
    padding: "15px",
    display: "flex",
    flexDirection: "column"
  },
  languageSelectedIcon: {
    color: "#2283C0"
  },
  colorBlack: {
    color: "black"
  },
  logSignBtn: {
    width: "240px",
    height: "51px",
    color: "#FFF",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    backgroundColor: "#2283c0",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2283c0",
      opacity: "0.8"
    },
    "@media (min-width: 900px) and (max-width: 1200px)": {
      width: "167px",
      height: "35px",
      fontSize: "14px"
    },
    "@media (min-width: 600px) and (max-width: 900px)": {
      width: "125px",
      height: "35px",
      fontSize: "13px"
    },
    "@media (min-width: 0px) and (max-width: 600px)": {
      width: "100px",
      padding: 0,
      height: "35px",
      fontSize: "13px"
    }
  },
  topBarProfilelogo: {
    width: "42px",
    cursor: "pointer",
    height: "42px"
  },
  profilePopoverContainer: {
    width: "283px",
    height: "224px",
    borderRadius: "10px",
    background: "#EFEFEF",
    padding: "22px 24px",
    boxSizing: "border-box"
  },
  popoverSecOne: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  popoverProfileImg: {
    width: "50px",
    height: "50px"
  },
  deactiveBtn: {
    marginTop: "14px",
    fontSize: "14px",
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: 400,
    color: "#fff",
    borderRadius: "6px",
    border: "1px solid #67C375",
    background: "#67C375",
    height: "34px",
    "&:hover": {
      background: "#67C375",
      opacity: "0.8"
    }
  },
  profileName: {
    width: "180px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  toolTipStyle: {
    color: "#FFF",
    textTransform: "capitalize"
  },
  //Dialog Rage
  modalContainer: {
    padding: "25px 25px"
  },
  deactiveSuccessfulIcon: {
    color: "#67C375",
    fontSize: "145px",
    "@media (min-width: 900px) and (max-width: 1200px)": {
      fontSize: "126px"
    },
    "@media (min-width: 0px) and (max-width: 900px)": {
      fontSize: "100px"
    }
  },
  actionBtnContainer: {
    display: "flex",
    direction: "ltr",
    gap: "16px",
    marginTop: "50px",
    "@media (min-width: 0px) and (max-width: 450px)": {
      flexDirection: "column"
    }
  },
  goToBtn: {
    borderRadius: "6px",
    marginTop: "35px",
    background: "#67C375",
    height: "48px",
    width: "284px",
    color: "#FFF",
    textTransform: "none",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "500",
    "&:hover": {
      background: "#67C375",
      opacity: "0.8"
    },
    "@media (min-width: 900px) and (max-width: 1200px)": {
      width: "260px",
    },
    "@media (min-width: 0px) and (max-width: 900px)": {
      width: "fit-content"
    }
  },
  deleteIconContainer: {
    width: "170px",
    height: "134px",
    borderRadius: "6px",
    border: "1px solid #959494",
    background: "#FFF",
    display: "grid",
    placeItems: "center"
  },
  confirmDeleteBtn: {
    width: "153px",
    height: "48px",
    padding: "0",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "400",
    color: "#fff",
    textTransform: "none",
    borderRadius: "6px",
    border: "1px solid #67C375",
    background: "#67C375",
    "&:hover": {
      background: "#67C375",
      opacity: "0.8"
    }
  },
  cancelDeleteBtn: {
    width: "153px",
    height: "48px",
    padding: "0",
    borderRadius: "6px",
    border: "1px solid #67C375",
    background: "#ECFFEF",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "400",
    textTransform: "none",
    color: "#67C375"
  },
  rotateTo180Deg: {
    transform: "rotate(180deg)"
  },
  languageOptionArabicAlter: {
    marginLeft: "16px",
    marginRight: "-11px"
  }
});

export const theme = createTheme({
  palette: {
    primary: { main: "#959494" },
    text: {
      primary: "#000",
      secondary: "#FFF"
    }
  },
  typography: {
    fontFamily: "HelveticaNeue Medium",
    h4: {
      fontSize: "28px",
      fontStyle: "normal",
      fontWeight: 400,
      wordBreak: "break-word"
    },
    h5: {
      fontSize: "26px",
      fontStyle: "normal",
      fontWeight: 400,
      wordBreak: "break-word",
      "@media (min-width: 0px) and (max-width: 600px)": {
        fontSize: "20px",
      }
    },
    body1: {
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 400,
      wordBreak: "break-word"
    },
    subtitle1: {
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      wordBreak: "break-word"
    },
    caption: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400
    },
    body2: {
      fontSize: "22px",
      fontStyle: "normal",
      fontWeight: 400
    }
  }
});

export const ArabicTheme = createTheme({
  palette: {
    primary: { main: "#959494" },
    text: {
      primary: "#000",
      secondary: "#FFF"
    }
  },
  typography: {
    fontFamily: "GeezaPro Bold",
    h4: {
      fontSize: "28px",
      fontStyle: "normal",
      fontWeight: 400,
      wordBreak: "break-word"
    },
    h5: {
      fontSize: "26px",
      fontStyle: "normal",
      fontWeight: 400,
      wordBreak: "break-word",
      "@media (min-width: 0px) and (max-width: 600px)": {
        fontSize: "20px",
      }
    },
    body1: {
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 400,
      wordBreak: "break-word"
    },
    subtitle1: {
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      wordBreak: "break-word"
    },
    caption: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400
    },
    body2: {
      fontSize: "22px",
      fontStyle: "normal",
      fontWeight: 400
    }
  }
});

const SvgIcons = {
  popoverEditIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.22184 1.17368H5.83921C2.38263 1.17368 1 2.55631 1 6.01289V10.1608C1 13.6174 2.38263 15 5.83921 15H9.98711C13.4437 15 14.8263 13.6174 14.8263 10.1608V8.77816"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.25857 7.32639C5.05117 7.53379 4.84378 7.94167 4.8023 8.23893L4.50503 10.3198C4.39442 11.0733 4.92673 11.5987 5.68027 11.495L7.76113 11.1978C8.05148 11.1563 8.45936 10.9489 8.67367 10.7415L14.1212 5.29392C15.0614 4.35373 15.5039 3.26145 14.1212 1.87882C12.7386 0.496189 11.6463 0.938631 10.7061 1.87882L5.25857 7.32639Z"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.9248 2.65994C10.388 4.31219 11.6807 5.60495 13.3399 6.07505"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  arrowDownLangauge: (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="group_icon">
        <path
          id="view_vector"
          d="M14 23C19 23 21 20.8 21 15.3V8.7C21 3.2 19 1 14 1H8C3 1 1 3.2 1 8.7V15.3C1 20.8 3 23 8 23H14Z"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="view_vector_2"
          d="M7.46997 10.5039L11 14.3759L14.53 10.5039"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  ),
  arrowDownLangaugeWhite: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
    >
      <path
        d="M14 23C19 23 21 20.8 21 15.3V8.7C21 3.2 19 1 14 1H8C3 1 1 3.2 1 8.7V15.3C1 20.8 3 23 8 23H14Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.46997 10.5039L11 14.3759L14.53 10.5039"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  searchIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5 20C5.25329 20 1 15.7467 1 10.5C1 5.25329 5.25329 1 10.5 1C15.7467 1 20 5.25329 20 10.5C20 15.7467 15.7467 20 10.5 20Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21 21L19 19"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  deleteConfirmIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="56"
      viewBox="0 0 50 56"
      fill="none"
    >
      <path
        d="M49 11.7459C40.12 10.8549 31.1867 10.3959 22.28 10.3959C17 10.3959 11.72 10.6659 6.44 11.2059L1 11.7459"
        stroke="#292D32"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.6667 9.01899L16.2534 5.482C16.68 2.917 17 1 21.5067 1H28.4934C33 1 33.3467 3.025 33.7467 5.509L34.3334 9.01899"
        stroke="#292D32"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M43.2667 20.278L41.5334 47.467C41.2401 51.706 41.0001 55 33.5601 55H16.4401C9.00006 55 8.76007 51.706 8.46673 47.467L6.7334 20.278"
        stroke="#292D32"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.5467 40.15H29.4267"
        stroke="#292D32"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.3333 29.35H31.6666"
        stroke="#292D32"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
};

export default withTranslation<Header>()(withStyles(webStyle)(Header));
// Customizable Area End 
