const englishTrl: any = {
    banner: 'New way to manage your <br /> tasks without any hustle',
    subBanner: 'Now you can manage your tasks on priority and <br /> never forget the delivery deadline.',
    loginBtnTxt: 'Login',
    signUpBtnTxt: 'Sign Up',
    phoneNoInputLabel: 'Mobile Number',
    getOtpBtnTxt: 'Get OTP',
    enterOtpInputTxt: 'Enter OTP',
    askStatsOtp: "Didn't receive OTP?",
    resendTxt: 'Resend',
    createAccountBtnTxt: 'Create Account',
    fullNameInputLabel: "Full Name",
    otpSentSuccessMsg: 'OTP sent successfully on your mobile number.',
    emptyPhoneNoValidationMsg: 'Please enter number',
    invalidPhoneNoValidationMsg: "The number you have entered is not valid. Please check your mobile number.",
    emptyNameValidationMsg: 'Enter Full Name',
    emptyOtpValidationMsg: 'Please enter OTP',
    invalidFullNameMsg: 'Please enter Full Name',
    //CustomisableUserProfiles
    profilelabelTitleText: 'Profile Details',
    namelabelTitleText: "Full Name",
    monilelabelTitleText: "Mobile Number ",
    verifyNowTxt: 'Verify Now',
    emaillabelTitleText: "Email Address",
    DestinationlabelTitleText: "Designation",
    DepartmentlabelTitleText: "Department",
    submitbtnTitle: "Save, Go to Dashboard",
    verifiedTxt: 'Verified',
    updatebtnTitle: 'Update',
    sendOtpBtnTxt: 'Send OTP',
    otpSentInfo: 'An OTP is sent on your registered Mobile number',
    profileImgValidMsg: 'Invalid file format. Please select a JPG,JPEG or PNG image.',
    fullNameCharValidMsg: 'Enter text character only',
    fullNamelengthMsg: 'FullName is too long, it has more than 40 characters.',
    departmentLengthMsg: 'Department is too long, it has more than 40 characters.',
    designationLengthMsg: 'Designation is too long, it has more than 40 characters.',
    fullNameReqValidMsg: 'Full name is required',
    mobileNoReqMsg: 'Mobile number is required',
    invalidOtpMsg: 'OTP is not valid ,Please enter correct OTP',
    awesomeTxt: 'Awesome!',
    profileUpdateInfoTxt: 'You have successfully updated <br/> your profile',
    goToDashboardTxt: 'Go to Dashboard',
    phoneNoTakenMsg: 'Mobile number already taken.',
    accountNotFoundMsg: 'Account not found',
    accountDeactiveMsg: 'Your account is not active. Please contact administrator to activate your account.',
    invalidOtpMsg2: 'OTP is not valid. Please enter correct OTP.',
    otpExpiredMsg: 'This OTP has expired, please request a new OTP.',
    successLoggedInMsg: 'You have logged in successfully',
    successSignUpMsg: 'You have successfully created <br /> your account',
    goToProfileBtnTxt: 'Go to Profile',
    //SideBar
    yourWorkBoardTxt: 'Your Workboard',
    boardsTxt: 'Board',
    boardsTxtPlural: 'Boards',
    reportDropDownTxt: "Report",
    reqDropDownTxt: "Request",
    selectAnyBoardMsg: 'Please select any Board',
    noReqFoundValidation: "No Request Found",
    acceptBtnTxt: "Accept",
    declineBtnTxt: "Decline",
    logoutBtnTxt: "Logout",
    //BoardPage
    yourBoardTxt: 'Your Boards',
    boardPageSubHeader: "All of your boards are visible here, You can add, edit and view your boards directly from here.",
    //CreateModal
    createBoardText: 'Create Board',
    createModalSubHeader: "You can create board and invite people and assign tasks to do.",
    validationBoardNameReq: 'Board Name is Required',
    validationBoardNameLength: 'Board name cannot exceed 25 characters.',
    boardNameInputLabel: "Board Name",
    boardNameInputPlaceholder: 'Enter Board Name',
    boardDesInputLabel: "Board Description",
    validationBoardDesReq: "Board Description is Required",
    boardDesPlaceholder: "Description",
    boardIconInputLabel: "Board Icon",
    boardIconInputPlaceholder: "Upload Board Icon",
    inviteMembersInputLabel: "Invite Members",
    inviteMembersInputPlaceholder: "Enter mobile no. to invite",
    infoMessageInviteNumber: `Enter mobile no. with country code, excluding the "+" (e.g., 966).`,
    //editBoardModal
    editBoardTxt: 'Edit Board',
    editModalSubHeader: 'You can edit board and invite people and assign tasks to do.',
    editModalSubmitBtnText: "Update Board",
    //userProfile
    invalidPhoneNoMsg: "The number you have entered is not valid. Please check your mobile number.",
    invalidEmailIdMsg: "Please enter a valid email address",
    //BoardMember
    boardMemberModalTitle: "Board Members",
    deleteTxt: 'Delete',
    noMemberValidation: "No Member Found",
    //InviteMember
    inviteMemberTxt: "Invite Members",
    inviteMemberModalSubHeader: "You can invite more members by sharing the link to your whatsapp group or sending that to whatsapp directly.",
    viaLinkInputLabel: "Via Link",
    infoLinkCopied: "Link is Copied",
    validationInviteMember: "Please enter at least one number",
    noBtnTxt: "No",
    acceptDeleteBtnTxt: "Yes, Delete",
    //ValidationMsg
    fileTypeValidation: "File {{selectedFileName}} is not in PNG, JPEG, or JPG format.",
    fileSizeValidMsg: "File {{selectedFileNamee}} exceeds the 5MB file size limit.",
    sameBoardValidMsg: "board name has already been taken",
    longDesValidMsg: "Board description cannot exceed 250 characters.",
    emptyFieldValidMsg: "can't be blank",
    invalidPhoneNumberMsg: "The number you have entered is not valid. Please check your mobile number.",
    //TopBar
    loginSignupBtnTxt: "Login/Signup",
    editProfileTxt: "Edit Profile",
    deActiveBtnTxt: "Deactivate Account",
    confirmDeActiveAccount: "Are you sure you want to deactive <br /> your account ?",
    yesDeactiveTxt: 'Yes, Deactive',
    successDeactiveAccountMsg: "Your account has been deactived <br /> successfully.",
    goToHomePageTxt: "Go to Homepage",
    noTaskListMsg: "No taskList is available",
    createTaskListTxt: "Create Task List",
    createTaskListPlaceHolder: "Enter Heading...",
    createBtnTxt: "Create",
    dismissBtnTxt: "Dismiss",
    viewAllCommentsTxt: "View All Comments",
    dueTodayTxt: "Due Today",
    dueTomorrowTxt: "Due Tomorrow",
    dueDateTxt: "Due : {{DynamicDate}}",
    dueOnTxt: "Due on :",
    noTaskValidMsg: "No Task Available",
    selectDueDateTxt: "Select Due Date",
    commentsTxt: "Comments",
    addNewCommentPlaceholder: "Add new comment...",
    taskDeleteConfirmatiomMsg: `Are you sure you want to delete<br/> "{{ selectedTask }}" task ?`,
    successDeleteTaskMsg: `You have successfully deleted "{{selectedTask}}" task!`,
    //TaskAllocator
    createTaskTxt: "Create Task",
    taskModalSubHeader: " You can create Task and invite people and assign tasks to do.",
    taskNameReqMsg: "Task Name is Required",
    taskNameMaxLengthMsg: 'Task name must not exceed 25 characters.',
    taskNameInputLabel: "Task Name",
    taskNamePlaceholderTxt: "Enter Task Name",
    taskDesInputLabel: "Task Description",
    taskDesPlaceholderTxt: "Enter Task Description",
    assignMemberInputLabel: "Assign Members",
    noMemberFoundTxt: "Member not found on this board",
    assignMemberPlaceholder: "Enter Name to Assign",
    notificationTxt: "Notification",
    dueDateTitleTxt: "Due Date",
    updateTaskTxt: "Update Task",
    editTaskTxt: "Edit Task",
    summaryTxt: "Summary",
    createdTabName: "Created",
    ongoingTabName: "Ongoing",
    OverdueTabName: "Overdue",
    CompletedTabName: "Completed",
    taskNameTxt: "Task Name",
    assignToTxt: "Assigned to",
    noDataAvailableMsg: "No data available",
    selectAtleastOneMemberMsg: "Please select atleast one member",
    printReportTxt: "Print Report",
    noCompletedTaskFoundMsg: "No completed tasks found on this Tasklist",
    completedOnTxt: "Completed on :",
    completedByTxt: "Completed by :",
    confirmationPrintPdf: `Are you sure you want to print "{{selectedTask}}" ?`,
    fullNameErrorMsg: "Enter text character only and minimum 2 words",
    tasklisyNameLengthValidMsg: "is too long (maximum is 40 characters",
    tasklistNameAlreadyExistMsg: "task list name has already been taken",
    emptyCommentValidationMessage: "Cannot add a comment as there is no available task.",
    taskSelectionCommentText: "Please Select the task for which you wish to add the comment",
    successDeleteBoard: ` You have successfully deleted <br /> "{{selectedBoard}}” Board!`,
    successBoardMemberDeleted: `{{memberName}} is deleted from <br /> the board {{selectedBoard}}!`,
    askDeleteBoard: `Are you sure you want to delete <br /> {{selectedBoard}} Board?`,
    askDeleteBoardMember: `Are you sure you want to delete <br /> member "{{memberName}}" ?`,
    errorMsgLogin: "Please complete sign up process before login.",
    membersTxt: "Members",
    taskRoleInfoMsg: "Assignee access needed to mark as incomplete",
    taskRoleInfoMsg2: "Assignee access needed to mark as complete",
    taskCompleted: "Task completed",
    ar: "العربية",
    en: "English",
    copyrightTxt: "Copyright. All Rights reserved.",
    websiteTitle: "ASHGAL-LEE",
    inviteMemberValidationMsg: "Press enter on Invite Members to add a number",
    notAssignYetTxt: "Not Assigned Yet",
    asDueDateTxt: "Due Date",
    taskListNotFound: "Tasklist not found",
    contactInfoTxt: "Contact Info",
    bycontinue:"By continuing, you accept our",
    byloginin:"By logging in, you accept our",
    tnctxt:"terms and conditions",
    acceptTncTxt:"Please accept the terms & conditions",
    UpdateEmailErrorTxt: "Please enter a valid email address.",
    CreateTaskTitleError:"Task list name must not exceed 25 characters."
}

const arabicTrl: any = {
    banner: 'طريقة جديدة لإدارة مهامك<br/> بدون أي عناء',
    subBanner: 'الآن يمكنك إدارة مهامك حسب الأولوية وعدم نسيان مواعيد التسليم',
    loginBtnTxt: 'تسجيل الدخول',
    signUpBtnTxt: 'التسجيل',
    phoneNoInputLabel: 'رقم الهاتف المحمول',
    getOtpBtnTxt: 'احصل على كلمة مرور مؤقتة',
    enterOtpInputTxt: 'أدخل كلمة المرور المؤقتة',
    askStatsOtp: 'طريقة جديدة لإدارة مهامك بدون',
    resendTxt: 'أي عناء',
    createAccountBtnTxt: 'إنشاء حساب',
    fullNameInputLabel: 'الاسم بالكامل',
    otpSentSuccessMsg: 'تم إرسال رمز التحقق بنجاح إلى رقم هاتفك المحمول',
    emptyPhoneNoValidationMsg: 'من فضلك، أدخل رقم الهاتف',
    invalidPhoneNoValidationMsg: 'الرقم الذي أدخلته غير صالح. يرجى التحقق من رقم هاتفك المحمول',
    emptyNameValidationMsg: 'أدخل الاسم الكامل',
    emptyOtpValidationMsg: 'من فضلك، أدخل رمز التحقق',
    invalidFullNameMsg: 'من فضلك، أدخل الاسم الكامل',
    //CustomisableUserProfiles
    profilelabelTitleText: 'بيانات الملف الشخصي',
    namelabelTitleText: 'الاسم بالكامل',
    monilelabelTitleText: 'رقم الهاتف المحمول',
    verifyNowTxt: 'تحقق الآن',
    emaillabelTitleText: 'عنوان البريد الالكترونى',
    DestinationlabelTitleText: 'التعيين',
    DepartmentlabelTitleText: 'قسم',
    submitbtnTitle: "حفظ، انتقل إلى لوحة التحكم",
    verifiedTxt: 'تم التحقق',
    updatebtnTitle: 'تحديث',
    sendOtpBtnTxt: 'أرسل كلمة مرور لمرة واحدة',
    otpSentInfo: 'يتم إرسال كلمة مرور لمرة واحدة على رقم هاتفك المسجل',
    profileImgValidMsg: 'صيغة الملف غير صالحة. يرجى اختيار صورة بصيغة JPG، JPEG، أو PNG',
    fullNameCharValidMsg: 'أدخل أحرف نص فقط',
    fullNamelengthMsg: 'الاسم الكامل طويل جدًا، يحتوي على أكثر من 40 حرفًا',
    departmentLengthMsg: 'التعيين طويل جدًا، يحتوي على أكثر من 40 حرفًا',
    designationLengthMsg: "القسم طويل جدًا، يحتوي على أكثر من 40 حرفًا",
    fullNameReqValidMsg: "الاسم الكامل مطلوب",
    mobileNoReqMsg: "رقم الجوال مطلوب",
    invalidOtpMsg: "الرمز غير صالح، يرجى إدخال الرمز الصحيح",
    awesomeTxt: 'مذهل',
    profileUpdateInfoTxt: "لقد قمت<br/> بتحديث ملفك التعريفي بنجاح",
    goToDashboardTxt: 'التوجه إلى لوحة التحكم',
    phoneNoTakenMsg: "رقم الجوال مأخوذ بالفعل",
    accountNotFoundMsg: 'الحساب غير مُعثَر',
    accountDeactiveMsg: "حسابك غير نشط. يرجى الاتصال بالمسؤول لتفعيل حسابك",
    invalidOtpMsg2: "الرمز غير صالح، يرجى إدخال الرمز الصحيح",
    otpExpiredMsg: "هذا الرمز المؤقت انتهى صلاحيته، يرجى طلب رمز مؤقت جديد",
    successLoggedInMsg: "لقد قمت بتسجيل الدخول بنجاح",
    successSignUpMsg: "لقد قمت بالإنشاء بنجاح<br/> الحساب الخاص بك.",
    goToProfileBtnTxt: "انتقل إلى الملف الشخصي",
    //Sidebar
    yourWorkBoardTxt: 'لوحة عملك',
    boardsTxt: 'سبورة',
    boardsTxtPlural: 'المجالس',
    reportDropDownTxt: 'تقرير',
    reqDropDownTxt: 'طلب',
    selectAnyBoardMsg: 'الرجاء اختيار أي لوحة',
    noReqFoundValidation: "لم يتم العثور على طلبات",
    acceptBtnTxt: "قبول",
    declineBtnTxt: "رفض",
    logoutBtnTxt: 'تسجيل الخروج',
    // BoardPage
    yourBoardTxt: 'المجالس الخاصة بك',
    boardPageSubHeader: 'جميع لوحاتك مرئية هنا. يمكنك إضافة وتعديل وعرض لوحاتك مباشرة من هنا',
    createBoardText: 'إنشاء لوحة',
    //CreateModal
    createModalSubHeader: 'يمكنك إنشاء لوحة ودعوة الأشخاص وتعيين المهام للقيام بها',
    validationBoardNameReq: "اسم اللوحة مطلوب",
    validationBoardNameLength: "لا يمكن أن يتجاوز اسم اللوحة 25 حرفًا",
    boardNameInputLabel: 'اسم اللوحة',
    boardNameInputPlaceholder: 'اسم اللوحة',
    boardDesInputLabel: 'وصف اللوحة',
    validationBoardDesReq: "وصف اللوحة مطلوب",
    boardDesPlaceholder: 'الوصف',
    boardIconInputLabel: "أيقونة اللوحة",
    boardIconInputPlaceholder: "تحميل أيقونة اللوحة",
    inviteMembersInputLabel: 'دعوة الأعضاء',
    inviteMembersInputPlaceholder: "أدخل رقم الجوال للدعوة",
    infoMessageInviteNumber: `أدخل رقم الهاتف مع رمز البلد، دون استخدام الرمز '+' (على سبيل المثال، 966)`,
    //editBoardModal
    editBoardTxt: "تعديل اللوحة",
    editModalSubHeader: 'يمكنك تحرير اللوحة ودعوة الأشخاص وتعيين المهام للقيام بها',
    editModalSubmitBtnText: 'تحديث اللوحة',
    invalidPhoneNoMsg: "الرقم الذي أدخلته غير صالح. يرجى التحقق من رقم هاتفك المحمول",
    invalidEmailIdMsg: "يرجى إدخال عنوان بريد إلكتروني صالح",
    //BoardMember
    boardMemberModalTitle: "أعضاء مجلس الإدارة",
    deleteTxt: "يمسح",
    noMemberValidation: "لم يتم العثور على أي عضو",
    inviteMemberTxt: "دعوة الأعضاء",
    inviteMemberModalSubHeader: " يمكنك دعوة المزيد من الأعضاء من خلال مشاركة الرابط في مجموعتك على واتساب أو إرساله إلى واتساب مباشرة",
    viaLinkInputLabel: "عبر الرابط",
    infoLinkCopied: "تم نسخ الرابط",
    validationInviteMember: "الرجاء إدخال رقم واحد على الأقل",
    noBtnTxt: "لا",
    acceptDeleteBtnTxt: "نعم، احذف",
    askDeleteBoardMember: `هل أنت متأكد أنك تريد<br/> حذف العضو "{{memberName}}"؟`,
    askDeleteBoard: `هل أنت متأكد أنك تريد حذف اللوحة <br/>{{selectedBoard}}؟`,
    successBoardMemberDeleted: `تم حذف {{memberName}} من اللوحة {{selectedBoard}}`,
    //ValidationMsg
    fileTypeValidation: "يجب أن يكون الملف {{selectedFileName}} بتنسيق PNG، JPEG، أو JPG",
    fileSizeValidMsg: "يتجاوز ملف {{selectedFileNamee}} حجم الفايل المسموح به البالغ 5 ميجابايت",
    sameBoardValidMsg: "تم اختيار اسم المجلس بالفعل.",
    longDesValidMsg: "لا يمكن أن يتجاوز وصف اللوحة 250 حرفًا",
    emptyFieldValidMsg: "لا يمكن أن يكون فارغًا",
    //TopBar
    loginSignupBtnTxt: "الدخول/التسجيل",
    editProfileTxt: "تعديل الملف الشخصي",
    deActiveBtnTxt: "تعطيل الحساب",
    confirmDeActiveAccount: "هل أنت متأكد أنك تريد تعطيل حسابك؟",
    yesDeactiveTxt: 'نعم',
    successDeactiveAccountMsg: "حسابك تم تعطيله<br/> بنجاح",
    goToHomePageTxt: "اذهب إلى الصفحة الرئيسية",
    noTaskListMsg: "لا توجد قائمة مهام متاحة",
    createTaskListTxt: "إنشاء قائمة المهام",
    createTaskListPlaceHolder: "أدخل العنوان...",
    dismissBtnTxt: "رفض",
    createBtnTxt: "إنشاء",
    viewAllCommentsTxt: "عرض جميع التعليقات",
    dueTodayTxt: "المقرر اليوم",
    dueTomorrowTxt: "غدا",
    dueDateTxt: "موعد الاستحقاق: {{DynamicDate}}",
    dueOnTxt: "مستحق في :",
    noTaskValidMsg: "لا توجد مهمة متاحة",
    selectDueDateTxt: "تحديد تاريخ النهو",
    commentsTxt: "تعليقات",
    addNewCommentPlaceholder: "إضافة تعليق جديد...",
    taskDeleteConfirmatiomMsg: `هل أنت متأكد أنك تريد حذف مهمة <br/>"{{selectedTask}}"؟`,
    successDeleteTaskMsg: `لقد تم حذف المهمة "{{selectedTask}}" بنجاح`,
    createTaskTxt: "إنشاء مهمة",
    taskModalSubHeader: "يمكنك إنشاء مهمة ودعوة الأشخاص وتعيين المهام للقيام بها",
    taskNameReqMsg: "اسم المهمة مطلوب",
    taskNameMaxLengthMsg: "يجب ألا يتجاوز اسم المهمة 25 حرفًا",
    taskNameInputLabel: "اسم المهمة",
    taskNamePlaceholderTxt: "أدخل اسم المهمة",
    taskDesInputLabel: "وصف المهمة",
    taskDesPlaceholderTxt: "أدخل وصف المهمة",
    assignMemberInputLabel: "تعيين الأعضاء",
    noMemberFoundTxt: "لم يتم العثور على العضو في هذه اللوحة",
    assignMemberPlaceholder: "أدخل الاسم للتعيين",
    notificationTxt: "إشعار",
    dueDateTitleTxt: "تاريخ الاستحقاق",
    updateTaskTxt: "تعديل المهمة",
    editTaskTxt: "تحرير المهمة",
    summaryTxt: "ملخص",
    createdTabName: "تم إنشاؤه",
    ongoingTabName: "جاري",
    OverdueTabName: "تأخر",
    CompletedTabName: "مكتمل",
    taskNameTxt: "اسم المهمة",
    assignToTxt: "مخصص ل",
    noDataAvailableMsg: "لا توجد بيانات متاحة",
    selectAtleastOneMemberMsg: "الرجاء تحديد على الأقل عضو واحد",
    printReportTxt: "اطبع تقرير",
    noCompletedTaskFoundMsg: "لا توجد مهام مكتملة في هذه قائمة المهام",
    completedOnTxt: "تم الانتهاء منه في:",
    completedByTxt: "اكتمل بواسطة :",
    invalidPhoneNumberMsg: "الرقم الذي أدخلته غير صالح. يرجى التحقق من رقم هاتفك المحمول",
    confirmationPrintPdf: `هل أنت متأكد أنك تريد طباعة "{{selectedTask}}"؟`,
    fullNameErrorMsg: "أدخل أحرف النص فقط وعلى الأقل كلمتين",
    tasklisyNameLengthValidMsg: "اسم المهمة طويل جدًا (الحد الأقصى هو 40 حرفًا)",
    tasklistNameAlreadyExistMsg: "تم اختيار اسم قائمة المهام بالفعل",
    emptyCommentValidationMessage: "لا يمكن إضافة تعليق لعدم وجود مهمة متاحة",
    taskSelectionCommentText: "الرجاء اختيار المهمة التي ترغب في إضافة تعليق لها",
    successDeleteBoard: `لقد قمت بنجاح بحذف اللوحة "{{selectedBoard}}"`,
    errorMsgLogin: "يرجى إكمال عملية التسجيل قبل تسجيل الدخول",
    membersTxt: "الأعضاء",
    taskRoleInfoMsg: "الوصول المخول للمنفذ مطلوب للتعليم كغير كامل",
    taskRoleInfoMsg2: "الوصول المخول للمنفذ مطلوب للتعليم ككامل",
    taskCompleted: "تم إكمال المهمة",
    ar: "العربية",
    en: "English",
    copyrightTxt: "حقوق الطبع. جميع الحقوق محفوظة.",
    websiteTitle: "اشغال لي",
    inviteMemberValidationMsg: "اضغط على دخول على دعوة الأعضاء لإضافة رقم",
    notAssignYetTxt: "لم يتم تعيينه بعد",
    asDueDateTxt: "تاريخ النهو",
    taskListNotFound: "لم يتم العثور على قائمة المهام",
    contactInfoTxt: "معلومات التواصل",
    bycontinue:"من خلال الاستمرار، فإنك تقبل لدينا",
    byloginin:"بتسجيل الدخول فإنك تقبل موقعنا",
    tnctxt:"الأحكام والشروط",
    acceptTncTxt:"يرجى قبول الشروط والأحكام",
    UpdateEmailErrorTxt:"يرجى إدخال عنوان بريد إلكتروني صالح",
    CreateTaskTitleError:"يجب ألا يتجاوز اسم قائمة المهام 25 حرفًا"
}

export default { englishTrl, arabicTrl }