export const firstBannerImage = require("../assets/firstBannerImg.png");
export const secondSectionImg = require("../assets/secondSectionImg.png");
export const LandingPageLogo = require("../assets/LandingPageLogo.jpg");
export const alertImage = require("../assets/alertImage.png");
export const reportImage = require("../assets/reportImage.png");
export const taskListImage = require("../assets/taskListImage.png");
export const group_icon = require("../assets/group_icon.svg");
export const globe = require("../assets/globe.svg");
export const globeWhite = require("../assets/globeWhite.png");
export const profile = require("../assets/profile.png");
