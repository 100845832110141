import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  Button,
  InputAdornment,
  Input,
  Hidden,
  Dialog,
  ButtonBase,
  Select,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { poster, logo } from "./assets";
import OtpInput from "react-otp-input";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import ClearIcon from '@material-ui/icons/Clear';
import codes from "country-calling-code";
import { withTranslation } from "react-i18next";
import { ConditionalPanel, conditionSort, isArabicLang, sortByCountryCode } from "../../../components/src/HelperMethonds";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import MobileAccountLoginController, {
  Props
} from "./MobileAccountLoginController";

export class AccountLogin extends MobileAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  GenErrorText = (erorrProps: any) => {
    const Icon = this.conditionSort(
      erorrProps.type == "success",
      CheckCircleOutlineOutlinedIcon,
      InfoOutlinedIcon
    );
    return (
      <Typography
        color={this.conditionSort(
          erorrProps.type == "success",
          "secondary",
          "error"
        )}
        className={this.props.classes.commonErrorMessage}
        variant="subtitle1"
      >
        <span>
          <Icon
            className={this.conditionSort(
              erorrProps.type == "success",
              "",
              this.props.classes.errorIcon
            )}
            color={this.conditionSort(
              erorrProps.type == "success",
              "secondary",
              "error"
            )}
            fontSize="small"
          />
        </span>
        {erorrProps.message}
      </Typography>
    );
  };

  GenCountryDropDown = (classes: Record<string, string>, containerClass: string) => (
    <Select
      data-test-id="dropdownCode"
      renderValue={data => `+${data}`}
      autoWidth
      value={this.state.countryCodeSelected}
      onChange={this.handleSelectCountryCode}
      disableUnderline
      className={containerClass}
    >
      {sortByCountryCode(codes).map((data: any) => (
        <MenuItem value={`${data.countryCodes}`}>
          <Box className={classes.optionWrapper}>
            <img
              width={36}
              height={18}
              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${data.isoCode2
                }.svg`}
            />
            <Typography style={{ direction: 'ltr', fontFamily: "HelveticaNeue Medium" }}>+{data.countryCodes}</Typography>
          </Box>
        </MenuItem>
      ))}
    </Select>
  )
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, signUpType, t, i18n } = this.props;
    // Customizable Area End
    return (
      <ThemeProvider theme={conditionSort(isArabicLang(i18n.language), ArabicTheme, theme)} >
        <Loader loading={this.state.isLoading} />
        <Grid container alignItems="stretch">
          <Hidden xsDown>
            <Grid item xs={6}>
              <Box className={classes.posterContainer}>
                <img src={poster} className={classes.posterRage} />

                <Box className={classes.bannerContainer}>
                  <Typography
                    component={"div"}
                    dangerouslySetInnerHTML={{ __html: t("banner") }}
                    className={conditionSort(isArabicLang(i18n.language), '', classes.fontBold)}
                    variant="h1"
                    align="center"
                  />

                  <Typography
                    component={"div"}
                    dangerouslySetInnerHTML={{ __html: t("subBanner") }}
                    variant="h6"
                    align="center"
                  />
                </Box>
              </Box>
            </Grid>
          </Hidden>

          <Grid item xs={12} md={6} sm={6}>
            <Grid
              className={classes.pageLayoutWrapper}
              container
              justifyContent="center"
            >
              <Box className={classes.pageContainer}>
                <ButtonBase
                  disableRipple
                  data-test-id="logoImg"
                  onClick={() => this.handleNavigate("LandingPage")}
                >
                  <img src={logo} className={classes.logoImg} />
                </ButtonBase>

                <Box className={classes.toggleBtnContainer}>
                  <Button
                    data-test-id="toggleBtnLogin"
                    onClick={() => this.handleNavigate("AccountLogin")}
                    className={this.conditionSort(
                      !signUpType,
                      classes.toggleActiveBtnRage,
                      classes.toggleBtnRage
                    )}
                  >
                    {t("loginBtnTxt")}
                  </Button>

                  <Button
                    data-test-id="toggleBtnSignUp"
                    onClick={() => this.handleNavigate("AccountSignUp")}
                    className={this.conditionSort(
                      signUpType,
                      classes.toggleActiveBtnRage,
                      classes.toggleBtnRage
                    )}
                  >
                    {t("signUpBtnTxt")}
                  </Button>
                </Box>

                <Box className={classes.fieldInputContainer}>
                  {signUpType && (
                    <Box>
                      <Typography gutterBottom variant="h5">
                        {t('fullNameInputLabel')}
                      </Typography>
                      <Input
                        classes={{ focused: classes.foucsInput }}
                        inputProps={{ maxLength: 40 }}
                        onKeyDown={e =>
                          this.preventEType(e, /^[A-Za-z\s]+$/, true)
                        }
                        style={{
                          borderColor: this.conditionSort(
                            this.state.fullNameError.type == "error",
                            "#FF1818",
                            ""
                          )
                        }}
                        data-test-id="fullNameInput"
                        onChange={e => this.handleFullNameInput(e)}
                        onPaste={e => this.preventPaste(e, /^[^0-9!@#$%^&*()_+={}[\]:;<>,.?/~`|"'\\-]+$/)}
                        className={this.conditionSort(isArabicLang(i18n.language), `${classes.inputRage} ${classes.inputRageArabicAlter}`, classes.inputRage)}
                        fullWidth={true}
                        disableUnderline={true}
                      />
                      {this.state.fullNameError.message &&
                        this.GenErrorText(this.state.fullNameError)}
                    </Box>
                  )}
                  <Box>
                    <Typography gutterBottom variant="h5">
                      {t("phoneNoInputLabel")}
                    </Typography>
                    <Input
                      classes={{ focused: classes.foucsInput }}
                      inputProps={{ maxLength: 25 }}
                      onPaste={e => this.preventPaste(e, /^\d+$/)}
                      data-test-id="phoneInput"
                      style={{
                        borderColor: this.conditionSort(
                          this.state.mobileNoError.type == "error",
                          "#FF1818",
                          ""
                        )
                      }}
                      onChange={e => this.handleMobileNoInput(e)}
                      onKeyDown={e => this.preventEType(e, /^[^A-Za-z\W_]+$/)}
                      className={this.conditionSort(isArabicLang(i18n.language), `${classes.inputRage} ${classes.inputRageArabicAlter}`, classes.inputRage)}
                      fullWidth={true}
                      disableUnderline={true}
                      endAdornment={
                        <InputAdornment position={this.conditionSort(isArabicLang(i18n.language), 'start', 'end')}>
                          <ConditionalPanel conditionArray={[isArabicLang(i18n.language)]}>
                            {this.GenCountryDropDown(classes, `${classes.dropDownRage} ${classes.dropDownRageArabicAlter}`)}
                          </ConditionalPanel>

                          <Button
                            disabled={this.conditionSort(
                              this.state.otpSentNo.trim() ==
                              `${this.state.countryCodeSelected
                              }${this.state.mobileNo.trim()}` ||
                              this.state.mobileNo.length == 0,
                              true,
                              false
                            )}
                            data-test-id="getOtpBtn"
                            onClick={() => this.handleLoginSignUp()}
                            variant="contained"
                            className={classes.getOTPBtn}
                          >
                            {t("getOtpBtnTxt")}
                          </Button>
                        </InputAdornment>
                      }
                      startAdornment={
                        <ConditionalPanel conditionArray={[!isArabicLang(i18n.language)]}>
                          {this.GenCountryDropDown(classes, classes.dropDownRage)}
                        </ConditionalPanel>
                      }
                    />
                    {this.state.mobileNoError.message &&
                      this.GenErrorText(this.state.mobileNoError)}
                  </Box>
                  
                  <Box>
                    <Typography gutterBottom variant="h5">
                      {t("enterOtpInputTxt")}
                    </Typography>
                    <OtpInput
                      data-test-id="otpInput"
                      value={this.state.otpValue}
                      onChange={this.handleChangeOTP}
                      numInputs={5}
                      renderInput={props => <input {...props} />}
                      inputStyle={this.conditionSort(
                        this.state.otpError.type == "error",
                        classes.otpInputError,
                        classes.otpInput
                      )}
                      containerStyle={classes.otpInputContainer}
                      inputType="number"
                    />
                    {this.state.otpError.message &&
                      this.GenErrorText(this.state.otpError)}
                  </Box>
                  <Box>
                  <Box style={{display:'flex',alignItems: "center"}}>
                      <Typography style={{ display: 'flex', alignItems: "center" }}>
                        {this.conditionSort(
                            isArabicLang(this.props.i18n.language),
                            signUpType ? (
                              <Typography>{this.props.t('bycontinue')} </Typography>
                            ) : (
                              <Typography>{this.props.t('byloginin')} </Typography>
                            ),
                            signUpType ? (
                                <Typography>{this.props.t('bycontinue')} </Typography>
                            ) : (
                                <Typography>{this.props.t('byloginin')} </Typography>
                            )
                        )}
                        {this.conditionSort(
                            isArabicLang(this.props.i18n.language),
                            <button className={classes.tncpopuplink} onClick={this.handleTncPopup}>{this.props.t('tnctxt')}</button>,
                            <button className={classes.tncpopuplink} onClick={this.handleTncPopup}>{this.props.t('tnctxt')}</button>
                        )}
                    </Typography>
                    </Box>
                    </Box>
                </Box>

                <Box className={classes.lastSection}>
                  {this.state.otpSentNo.trim() ==
                    `${this.state.countryCodeSelected
                    }${this.state.mobileNo.trim()}` && (
                      <Typography gutterBottom align="center" variant="body1">
                        {t("askStatsOtp")}
                        <span
                          data-test-id="resendOTPBtn"
                          onClick={() => this.doMobileLogIn(false)}
                          style={{ color: "#2283C0", cursor: "pointer" }}
                        >
                          ‎ {t("resendTxt")}
                        </span>
                      </Typography>
                    )}

                  <Button
                    disabled={this.conditionSort(
                      this.state.otpSentNo.trim() ==
                      `${this.state.countryCodeSelected
                      }${this.state.mobileNo.trim()}` &&
                      this.state.otpValue.length == 5 &&
                      this.state.otpError.type != "error",
                      false,
                      true
                    )}
                    data-test-id="loginBtn"
                    onClick={() => this.doVerifyOTP()}
                    className={classes.submitBtn}
                    variant="contained"
                  >
                    {this.conditionSort(
                      !signUpType,
                      t("loginBtnTxt"),
                      t("createAccountBtnTxt")
                    )}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={() => this.handleModal(false, true)}
          open={this.state.isModalOpen}
          PaperProps={{
            style: { borderRadius: "20px", width: "509px", height: "462px", display: "grid" }
          }}
        >
          <Grid
            className={classes.modalContainer}
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
          >
            <CheckCircleIcon className={classes.iconRage} />
            <Typography className={conditionSort(!isArabicLang(i18n.language), classes.modalHeader, classes.modalHeaderArabic)} gutterBottom>
              {t('awesomeTxt')}
            </Typography>
            {this.conditionSort(
              !signUpType,
              <Typography align="center" className={conditionSort(!isArabicLang(i18n.language), classes.modalSubText, classes.modalSubTextArabic)}>
                {t('successLoggedInMsg')}
              </Typography>,
              <Typography
                component={"div"}
                dangerouslySetInnerHTML={{ __html: t("successSignUpMsg") }}
                className={classes.modalSubText}
                align="center"
              />
            )}
            <Button
              data-test-id="modalCloseBtn"
              onClick={() =>
                this.handleNavigateAfterloggin(
                  this.conditionSort(
                    !signUpType,
                    "ProjectTaskTracking",
                    "CustomisableUserProfiles"
                  )
                )
              }
              className={classes.goToBtn}
              variant="contained"
            >
              {this.conditionSort(
                !signUpType,
                t('goToDashboardTxt'),
                t('goToProfileBtnTxt')
              )}
            </Button>
          </Grid>
        </Dialog>
        
        <Dialog
            fullWidth
            maxWidth="md"
            open={this.state.tncPopup}
            onClose={this.handleClosePopup}
          >
        <Typography variant="h4" className={conditionSort(isArabicLang(i18n.language), classes.arabicPopuptitle, classes.popuptitle)}>
          {this.state.TncData?.attributes?.title} 
          <button onClick={this.handleClosePopup} className={conditionSort(isArabicLang(i18n.language), classes.arabicClspopup, classes.clspopup)}><ClearIcon /></button>
        </Typography>
        
        <Typography variant="body1">
            <div  className={conditionSort(isArabicLang(i18n.language), classes.arabicPopupdesc, classes.popupdesc)}
                dangerouslySetInnerHTML={{__html: this.state.TncData?.attributes?.description}}
            />
          </Typography>
      </Dialog>
      </ThemeProvider>
    );
  }
}

// Customizable Area Start
export const webStyle: any = (theme: any) => ({
  fontBold: {
    fontFamily: "HelveticaNeue Bold"
  },
  posterContainer: {
    width: "100%",
    height: "100vh",
    background: "#B2CCDA"
  },
  posterRage: {
    height: "70%",
    width: "100%",
    objectFit: "cover"
  },
  bannerContainer: {
    width: "100%",
    height: "29%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly"
  },
  pageLayoutWrapper: {
    height: "100vh",
    width: "100%",
    overflow: "auto",
    alignContent:'start'
  },
  pageContainer: {
    width: "75%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 0px",
    maxHeight: "100vh"
  },
  logoImg: {
    width: "8vmax",
    "@media (min-width: 0px) and (max-width: 600px)": {
      width: "120px"
    }
  },
  fieldInputContainer: {
    marginTop: "35px",
    width: "100%",
    display: "flex",
    gap: "20px",
    flexDirection: "column",
    [theme.breakpoints.only("sm")]: {
      marginTop: "26px",
      gap: "15px"
    }
  },
  toggleActiveBtnRage: {
    width: "140px",
    height: "48px",
    minWidth: "fit-content",
    borderRadius: "6px",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "400",
    border: "1px solid #2283C0",
    background: "#2283C0",
    textTransform: "none",
    color: "#FFF",
    "&:hover": {
      background: "#2283C0",
      opacity: "0.7"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100px",
      height: "36px"
    }
  },
  toggleBtnRage: {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "400",
    minWidth: "fit-content",
    width: "140px",
    height: "48px",
    borderRadius: "6px",
    border: "1px solid #2283C0",
    textTransform: "none",
    color: "#2283C0",
    "&:hover": {
      background: "#FFF",
      opacity: "0.7"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100px",
      height: "36px"
    }
  },
  toggleBtnContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginTop: "25px",
    gap: "20px",
    [theme.breakpoints.only("sm")]: {
      marginTop: "18px"
    }
  },
  inputRage: {
    borderRadius: "7px",
    border: "1px solid #F3F3F3",
    background: "#FAFAFA",
    fontFamily: "HelveticaNeue Medium",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "400",
    padding: "5px 5px 5px 24px",
    boxSizing: "border-box",
    height: "56px",
    [theme.breakpoints.only("sm")]: {
      height: "40px",
      fontSize: "18px"
    },
    "@media (min-width: 0px) and (max-width: 600px)": {
      fontSize: "17px"
    }
  },
  inputRageArabicAlter: {
    padding: "5px 24px 5px 5px"
  },
  getOTPBtn: {
    backgroundColor: "#2283C0",
    color: "#FFF",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "400",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2283C0",
      opacity: "0.7"
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "17px",
      padding: "6px",
      height: "32px"
    },
    "@media (min-width: 0px) and (max-width: 600px)": {
      padding: "6px",
      fontSize: "15px"
    },
    "&:disabled": {
      backgroundColor: "#8CBFDE",
      color: "#FFF"
    }
  },
  commonErrorMessage: {
    gap: "3px",
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    marginTop: "8px"
  },
  otpInputContainer: {
    justifyContent: "space-between"
  },
  otpInput: {
    width: "18% !important",
    height: "56px",
    borderRadius: "4px",
    border: "1px solid #F3F3F3",
    background: "#FAFAFA",
    fontSize: "24px",
    fontFamily: "HelveticaNeue Medium",
    fontWeight: "400",
    "@media (min-width: 0px) and (max-width: 600px)": {
      height: "40px",
      fontSize: "18px"
    },
    "&:focus": {
      border: "1px solid black",
      outline: "none"
    }
  },
  otpInputError: {
    width: "18% !important",
    height: "56px",
    borderRadius: "4px",
    border: "1px solid #FF1818",
    background: "#FAFAFA",
    fontFamily: "HelveticaNeue Medium",
    fontSize: "24px",
    fontWeight: "400",
    "@media (min-width: 0px) and (max-width: 600px)": {
      height: "40px",
      fontSize: "18px"
    }
  },
  lastSection: {
    marginTop: "30px",
    [theme.breakpoints.only("sm")]: {
      marginTop: "22px"
    }
  },
  submitBtn: {
    borderRadius: "6px",
    background: "#67C375",
    height: "64px",
    width: "284px",
    color: "#FFF",
    textTransform: "none",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: "400",
    "@media (min-width: 0px) and (max-width: 600px)": {
      fontSize: "17px",
      height: "45px",
      width: "200px"
    },
    "&:disabled": {
      background: "#B3E1BA",
      color: "#FFF"
    },
    "&:hover": {
      background: "#67C375",
      opacity: "0.8"
    }
  },
  iconRage: {
    color: "#67C375",
    fontSize: "145px",
    "@media (min-width: 900px) and (max-width: 1200px)": {
      fontSize: "126px"
    },
    "@media (min-width: 0px) and (max-width: 900px)": {
      fontSize: "100px"
    }
  },
  modalContainer: {
    padding: "25px 0px"
  },
  modalHeader: {
    fontSize: "30px",
    fontStyle: "normal",
    fontFamily: "HelveticaNeue Bold",
    fontWeight: 400
  },
  modalHeaderArabic: {
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 400,
    fontFamily: "GeezaPro Bold"
  },
  modalSubText: {
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 400,
    color: "rgba(166, 166, 166, 0.50)"
  },
  modalSubTextArabic: {
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 400,
    color: "rgba(166, 166, 166, 0.50)",
    fontFamily: "GeezaPro Regular",
  },
  goToBtn: {
    borderRadius: "6px",
    marginTop: "60px",
    background: "#67C375",
    height: "48px",
    width: "284px",
    color: "#FFF",
    textTransform: "none",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "400",
    "&:hover": {
      background: "#67C375",
      opacity: "0.8"
    },
    "@media (min-width: 900px) and (max-width: 1200px)": {
      width: "260px",
    },
    "@media (min-width: 0px) and (max-width: 900px)": {
      width: "240px"
    }
  },
  foucsInput: {
    border: "1px solid black"
  },
  errorIcon: {
    transform: "rotate(180deg)"
  },
  dropDownRageArabicAlter: {
    direction: "ltr",
    marginLeft: '8px'
  },
  dropDownRage: {
    fontSize: "24px",
    fontStyle: "normal",
    fontFamily: "HelveticaNeue Medium",
    fontWeight: "400",
    "& .MuiSelect-select:focus": {
      background: "transparent"
    },
    "@media (min-width: 0px) and (max-width: 600px)": {
      fontSize: "17px"
    }
  },
  optionWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "10px"
  },
  tncpopuplink:{
    background: "transparent",
    border: "none",
    padding: "0",
    display: "inline-block",
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "HelveticaNeue Medium",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#2283C0",
    cursor: "pointer",
    marginLeft:"5px",
  },
  popuptitle:{
    padding: "20px",
    borderBottom: "1px solid #ccc",
  },
  arabicPopuptitle:{
    borderBottom: "1px solid #ccc",
    padding: "20px",
    fontFamily: "GeezaPro Regular",
  },
  popupdesc:{
    padding: "0 20px",
    fontSize: "20px",
    fontStyle: "normal",
    wordBreak: "break-word",
    fontFamily: "HelveticaNeue Regular",
    fontWeight: 400,
    lineHeight: 1.75,
    maxHeight: "560px", 
    overflowX: "auto"
  },
  arabicPopupdesc:{
    fontSize: "20px",
    padding: "0 20px",
    wordBreak: "break-word",
    fontStyle: "normal",
    fontWeight: 400,
    maxHeight: "560px", 
    lineHeight: 1.75,
    overflowX: "auto",
    fontFamily: "GeezaPro Regular",
  },
  TncError:{
    fontSize: "14px",
    fontStyle: "normal",
    fontFamily: "HelveticaNeue Medium",
    fontWeight: 400,
    lineHeight: 1.75,
    marginTop: "0",
    color: "#f00",
    paddingLeft: "43px"
  },
  clspopup:{
    float: "right",
    background: "transparent",
    border: "none",
    cursor: "pointer",
    margin: "8px 0"
  },
  arabicClspopup:{
    background: "transparent",
    float: "left",
    cursor: "pointer",
    border: "none",
    margin: "8px 0",
    fontFamily: "GeezaPro Regular",
  },
  fontRegular: {
    fontFamily: 'HelveticaNeue Regular',
  },
  arabicFontRegular: {
    fontFamily: "GeezaPro Regular",
  },
});

export const theme = createTheme({
  palette: {
    error: { main: "#F00" },
    secondary: { main: "#006827" }
  },
  typography: {
    fontFamily: "HelveticaNeue Medium",
    h1: {
      fontSize: "3vw",
      fontStyle: "normal",
      fontWeight: 400
    },
    h6: {
      fontSize: "2vmin",
      fontStyle: "normal",
      fontWeight: 400
    },
    h5: {
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      "@media (min-width: 600px) and (max-width: 959.95px)": {
        fontSize: "15px"
      }
    },
    subtitle1: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      "@media (min-width: 600px) and (max-width: 959.95px)": {
        fontSize: "11px"
      }
    },
    body1: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      "@media (min-width: 600px) and (max-width: 959.95px)": {
        fontSize: "12px"
      }
    }
  }
});

export const ArabicTheme = createTheme({
  palette: {
    error: { main: "#F00" },
    secondary: { main: "#006827" }
  },
  typography: {
    fontFamily: "GeezaPro Bold",
    h1: {
      fontSize: "3vw",
      fontStyle: "normal",
      fontWeight: 400
    },
    h6: {
      fontSize: "2vmin",
      fontStyle: "normal",
      fontWeight: 400,
      fontFamily: "GeezaPro Regular"
    },
    h5: {
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      "@media (min-width: 600px) and (max-width: 959.95px)": {
        fontSize: "15px"
      }
    },
    subtitle1: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      fontFamily: "GeezaPro Regular",
      "@media (min-width: 600px) and (max-width: 959.95px)": {
        fontSize: "11px"
      }
    },
    body1: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      "@media (min-width: 600px) and (max-width: 959.95px)": {
        fontSize: "12px"
      }
    }
  }
});

export default withTranslation<AccountLogin>()(
  withStyles(webStyle)(AccountLogin)
);
// Customizable Area End
