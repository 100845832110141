import React from "react";

// Customizable Area Start
import {
  Box, Button, Typography,
  Grid, ButtonBase
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import { firstBannerImage, secondSectionImg, LandingPageLogo, alertImage, reportImage, taskListImage, globe } from './assets'
import { withTranslation } from "react-i18next";
import { isArabicLang, conditionSort } from "../../../components/src/HelperMethonds";
import Loader from "../../../components/src/Loader.web";
// Customizable Area Start

import LandingPageController, {
  Props
} from "./LandingPageController";

export class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  GenLayoutOne = (classes: any, Responsedata: any) => {
    const { i18n } = this.props
    return (
      <Box className={classes.layoutOneContainer}>

        <Box className={classes.layoutOnecontent}>
          <Typography gutterBottom variant="h2">
            <div
              dangerouslySetInnerHTML={{ __html: Responsedata.attributes.title }}
            />
          </Typography>

          <Typography className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)} variant="body1">
            <div
              dangerouslySetInnerHTML={{ __html: Responsedata.attributes.short_description }}
            />
          </Typography>

          <Button
            variant="contained"
            onClick={() => this.handleRedirectionNewTab(Responsedata.attributes.button_url)}
            data-test-id="startedBtn"
            className={classes.layoutOneBtn}
          >
            {Responsedata.attributes.button_name}
          </Button>
        </Box>


        <Box className={classes.layoutOneImgContainer}>
          <img
            src={firstBannerImage}
            className={classes.bannerRage}
            data-test-id="firstBannerImage"
          />
        </Box>
      </Box>
    )
  }

  GenLayoutTwo = (classes: any, Responsedata: any) => {
    const { i18n } = this.props
    return (
      <Grid
        container
        justifyContent="space-evenly"
        className={classes.layoutTwoContainer}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Box marginTop={'60px'} width={'100%'}>
              <Typography align="center" variant="h1" className={classes.fontBold}>
                {Responsedata.attributes.title}
              </Typography>
              <Typography align="center" variant="body1" className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)}>
                <div
                  dangerouslySetInnerHTML={{ __html: Responsedata.attributes.short_description }}
                />
              </Typography>
            </Box>
            <Box marginTop={"18px"} className={classes.layoutTwoBannerContainer}>
              <img src={secondSectionImg} className={classes.bannerRage} />
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={5} lg={5}>
          <Box padding={"25px 75px"}>
            <Typography align="left" variant="subtitle1" className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)}>
              <div
                dangerouslySetInnerHTML={{ __html: Responsedata.attributes.left_description }}
              />
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={5} lg={5}>
          <Box padding={"25px 75px"}>
            <Typography align="left" variant="subtitle1" className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)}>
              <div
                dangerouslySetInnerHTML={{ __html: Responsedata.attributes.right_description }}
              />
            </Typography>
          </Box>
        </Grid>

      </Grid>
    )
  }

  GenLayoutThree = (classes: any, Responsedata: any) => {
    const { i18n } = this.props
    return (
      <Grid container
        alignItems="center"
        spacing={6}
        className={classes.layoutThreeContainer}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Box width={'100%'} padding={'0 10px'}>
              <Typography gutterBottom align="center" variant="h1" className={classes.fontBold}>
                {Responsedata.attributes.title}
              </Typography>
              <Typography align="center" variant="body1" className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)}>
                {Responsedata.attributes.short_description}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {Responsedata.attributes.descriptions.map((data: any, index: number) => {
            const Banner = [taskListImage, alertImage, reportImage]
            return (
              <Grid spacing={2} direction={index % 2 ? 'row-reverse' : 'row'} container justifyContent="space-evenly" alignItems="center">

                <Grid item xs={10} sm={6} md={5} lg={3}>
                  <Box className={classes.layoutThreeBannerContainer}>
                    <img src={Banner[index] || Banner[0]} className={classes.bannerRage} />
                  </Box>
                </Grid>

                <Grid item xs={10} sm={8} md={5} lg={4}>
                  <Typography gutterBottom variant="h3" className={classes.fontBold}>
                    {data.label}
                  </Typography>
                  <Typography variant="h4" className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)}>
                    {data.description}
                  </Typography>
                </Grid>

              </Grid>
            )
          })}
        </Grid>

      </Grid>
    )
  }

  GenLayoutFour = (classes: any, Responsedata: any) => {
    const { i18n } = this.props
    return (
      <Grid container
        justifyContent="space-evenly"
        alignItems="center"
        className={classes.layoutFourContainer}
      >
        <Grid item xs={10}>
          <Box>
            <Typography color="secondary" gutterBottom align="center" variant="h1" className={classes.fontBold}>
              <div
                dangerouslySetInnerHTML={{ __html: Responsedata.attributes.title }}
              />
            </Typography>
            <Typography color="secondary" align="center" variant="body1" className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)}>
              <div
                dangerouslySetInnerHTML={{ __html: Responsedata.attributes.short_description }}
              />
            </Typography>
          </Box>
          <Box className={classes.layoutFourBtnContainer}>

            <Button
              onClick={() => this.handleRedirectionNewTab(Responsedata.attributes.button_url)}
              variant="contained"
              data-test-id="signUpFreeBtn"
              className={classes.layoutFourBtn}
            >
              {Responsedata.attributes.button_name}
            </Button>

          </Box>
        </Grid>
      </Grid>
    )
  }
  // Customizable Area End

  render() {
    const { classes, t, i18n } = this.props;
    const { Responsedata, contactData, FooterData } = this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={conditionSort(isArabicLang(i18n.language), ArabicTheme, theme)}>
        <Loader loading={this.state.isLoading} />
        <Grid container className={classes.container}>

          {Responsedata.map((data: any, index: number) => {
            return (
              <Grid key={index} item xs={12}>

                {data.attributes.layout.name == 'Layout 1' && this.GenLayoutOne(classes, data)}

                {data.attributes.layout.name == 'Layout 2' && this.GenLayoutTwo(classes, data)}

                {data.attributes.layout.name == 'Layout 3' && this.GenLayoutThree(classes, data)}

                {data.attributes.layout.name == 'Layout 4' && this.GenLayoutFour(classes, data)}

              </Grid>
            )
          })}

          <Grid item xs={12} className={classes.footerWrapper}>
            {FooterData &&
              <Grid
                container
                className={classes.footerContainer}
              >
                <Grid item xs={12} sm={7} md={3} lg={3} xl={3}>
                  <ButtonBase data-test-id="footerWeblogo" className={classes.footerLogoContainer} disableRipple onClick={() => window.location.reload()}>
                    <img src={LandingPageLogo} className={classes.bannerRage} />
                  </ButtonBase>
                  <Typography style={{ marginBottom: "16px" }} variant="h5">
                    {t('contactInfoTxt')}
                  </Typography>
                  {contactData &&
                    <Typography variant="h5" className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)} style={{paddingBottom:'15px'}}>
                      {this.renderAndCheckLine(contactData.attributes.address_line_1)}
                      {this.renderAndCheckLine(contactData.attributes.address_line_2)}
                      {this.renderAndCheckLine(contactData.attributes.city, contactData.attributes.state)}
                      {this.renderAndCheckLine(contactData.attributes.country, contactData.attributes.zip_code)}
                      {this.renderAndCheckLine(`${isArabicLang(i18n.language) ? 'ㅤ' : ''}${contactData.attributes.phone_number}`)}
                    </Typography>
                  }
                </Grid>

                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                  <Box className={classes.footerListContainer}>
                    {Object.keys(FooterData).map((data, index) => (
                      <Box key={index}>
                        <Typography style={{ marginBottom: "14px" }} variant="h5" className={classes.fontBold}>
                          {data}
                        </Typography>
                        <Box>
                          {FooterData[data].data.map((footerLink: any, index: any) => (
                            <Typography data-test-id="footerLink" onClick={() => this.handleRedirectionNewTab(footerLink.attributes.page_url)} color="primary" variant="h5" className={classes.footLinkRage} key={index}>
                              {footerLink.attributes.name}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Grid>

                <div className={classes.dividerRage} />

                <Grid item xs={12} className={classes.copyRightContainer}>
                  <Box className={classes.copyRightRage}>
                    <Typography variant="h5" className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)}>
                      {t('copyrightTxt')}
                    </Typography>

                    <Typography variant="h5">{t('websiteTitle')}</Typography>
                  </Box>

                  <Box className={classes.alignIconMessage}>
                    <img src={globe} />
                    <Typography className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)} variant='h5'>
                      {t(this.state.selectedOption)}
                    </Typography>
                  </Box>
                </Grid>

              </Grid>
            }
          </Grid>

        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle: any = (theme: any) => ({
  container: {
    width: "100vw",
    overflowX: "hidden"
  },
  fontBold: {
    fontFamily: 'HelveticaNeue Bold',
  },
  //Drawer
  alignIconMessage: {
    gap: "8px",
    display: "flex",
    alignItems: "center"
  },
  fontRegular: {
    fontFamily: 'HelveticaNeue Regular',
  },
  arabicFontRegular: {
    fontFamily: "GeezaPro Regular",
  },
  //Layout 1
  layoutOneContainer: {
    display: "flex",
    padding: "20px 50px",
    gap: "30px",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "550px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      gap: "20px"
    },
  },
  layoutOnecontent: {
    width: "47%",
    [theme.breakpoints.down("sm")]: {
      width: "90%"
    }
  },
  layoutOneBtn: {
    color: "#fff",
    marginTop: "60px",
    "fontSize": "26px",
    "fontStyle": "normal",
    "fontWeight": 400,
    minWidth: "284px",
    minHeight: "64px",
    textTransform: "none",
    backgroundColor: "#67C375",
    "&:hover": {
      backgroundColor: "#67C375",
      opacity: "0.8"
    },
  },
  layoutOneImgContainer: {
    width: "588px",
    height: "417px",
  },
  bannerRage: {
    width: "100%",
    height: "100%",
    objectFit: "contain"
  },
  //Layout 2
  layoutTwoContainer: {
    backgroundColor: "#E1F3FF"
  },
  layoutTwoBannerContainer: {
    height: "452px",
    weight: "339px",
  },
  //Layout Three 
  layoutThreeContainer: {
    padding: "56px 0px"
  },
  layoutThreeBannerContainer: {
    width: "403px",
    height: "417px",
    flexShrink: 0,
    "@media (max-width: 959px)": {
      paddingTop: "25px"
    },
    "@media (max-width: 768px)": {
      width: "100%",
      height: "100%"
    },
  },
  //Layout Four 
  layoutFourContainer: {
    padding: "60px 0 52px 0",
    backgroundColor: "#2283C0"
  },
  layoutFourBtnContainer: {
    marginTop: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  layoutFourBtn: {
    minWidth: "284px",
    minHeight: "68px",
    textTransform: "none",
    borderRadius: "6px",
    border: "1px solid #67C375",
    background: "#67C375",
    color: "#FFF",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: 400,
    "&:hover": {
      opacity: "0.8",
      background: "#67C375"
    }
  },
  // Footer css here
  footerWrapper: {
    display: "flex",
    justifyContent: "center"
  },
  footerContainer: {
    width: "88%",
    marginTop: "44px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "initial",
      gap: "20px"
    },
  },
  footerListContainer: {
    display: "flex",
    gap: "160px",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "20px",
    },
    [theme.breakpoints.only("md")]: {
      gap: "80px",
    },
  },
  footerLogoContainer: {
    width: "112px",
    hight: "137px",
    marginBottom: "36px"
  },
  footLinkRage: {
    marginBottom: "12px",
    cursor: "pointer",
    width: "fit-content",
    fontFamily: 'HelveticaNeue Regular',
    color:'#000'
  },
  dividerRage: {
    margin: "15px 0",
    width: "100%",
    border: "1px solid #363535"
  },
  copyRightContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "20px",
    '@media (min-width: 0px) and (max-width: 800px)': {
      justifyContent: "initial",
      flexDirection: "column"
    }
  },
  copyRightRage: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    '@media (min-width: 0px) and (max-width: 800px)': {
      flexDirection: "column",
      alignItems: "initial",
    }
  }
});

export const theme = createTheme({
  palette: {
    error: { main: "#F00" },
    secondary: { main: "#FFF" },
    primary: { main: "#959494" }
  },
  typography: {
    fontFamily: 'HelveticaNeue Medium',
    h1: {
      "fontSize": "48px",
      "fontStyle": "normal",
      "fontWeight": 400,
      "wordBreak": "break-word"
    },
    h2: {
      "fontSize": "44px",
      "fontStyle": "normal",
      "fontWeight": 400,
      "wordBreak": "break-word"
    },
    h3: {
      "fontSize": "32px",
      "fontStyle": "normal",
      "fontWeight": 400,
      "wordBreak": "break-word"
    },
    h4: {
      "fontSize": "28px",
      "fontStyle": "normal",
      "fontWeight": 400,
      "wordBreak": "break-word",
    },
    h5: {
      "fontSize": "26px",
      "fontStyle": "normal",
      "fontWeight": 400,
      "wordBreak": "break-word"
    },
    body1: {
      "fontSize": "24px",
      "fontStyle": "normal",
      "fontWeight": 400,
      "wordBreak": "break-word"
    },
    subtitle1: {
      "fontSize": "20px",
      "fontStyle": "normal",
      "fontWeight": 400,
      "wordBreak": "break-word"
    }
  },
});

export const ArabicTheme = createTheme({
  typography: {
    fontFamily: "GeezaPro Bold",
    h2: {
      "fontSize": "44px",
      "fontWeight": 400,
      "wordBreak": "break-word",
      "fontStyle": "normal",
    },
    h1: {
      "fontSize": "48px",
      "fontWeight": 400,
      "fontStyle": "normal",
      "wordBreak": "break-word"
    },
    h4: {
      "fontSize": "28px",
      "wordBreak": "break-word",
      "fontStyle": "normal",
      "fontWeight": 400,
    },
    h3: {
      "fontStyle": "normal",
      "fontWeight": 400,
      "fontSize": "32px",
      "wordBreak": "break-word"
    },
    h5: {
      "fontWeight": 400,
      "fontSize": "26px",
      "wordBreak": "break-word",
      "fontStyle": "normal",
    },
    subtitle1: {
      "fontStyle": "normal",
      "fontWeight": 400,
      "wordBreak": "break-word",
      "fontSize": "20px",
    },
    body1: {
      "fontStyle": "normal",
      "fontWeight": 400,
      "fontSize": "24px",
      "wordBreak": "break-word"
    },
  },
  palette: {
    secondary: { main: "#FFF" },
    error: { main: "#F00" },
    primary: { main: "#959494" },
  },
});

export default withTranslation<LandingPage>()(withStyles(webStyle)(LandingPage));
// Customizable Area End