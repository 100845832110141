import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  getStorageData,
  removeStorageData,
  setStorageData
} from "../../../framework/src/Utilities";
import React from "react";
import { isTokenExpired, uiChangeLanguage } from "../../../components/src/HelperMethonds"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  i18n: any;
  t: Function;
  classes?: any;
  WrappedComponent?: any;
  hideSiteLogo?: boolean;
  whiteBackground?: boolean;
  enableDrawer?: boolean;
  location?: any;
  enableSidebarDrawer?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: any;
  Responsedata: any;
  contactData: any;
  FooterData: any;
  HeaderData: any;
  selectedOption: any;
  isDrawerOpen: boolean;
  token: string;
  userData: any;
  modalStats: any;
  languageOptions: any

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getHeaderCallId: string = "";
  apiProfileUserDataCallId: string = "";
  apiDeactivateAccount: string = "";
  getLanguageCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      anchorEl: {
        language: null,
        profile: null
      },
      Responsedata: [],
      contactData: "",
      FooterData: "",
      HeaderData: "",
      selectedOption: "",
      isDrawerOpen: false,
      token: "",
      userData: {},
      modalStats: {
        type: "",
        isOpen: false
      },
      languageOptions: []

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    this.handleGetHeader(from, message);
    this.handleResponseForUserData(from, message);
    this.handleAccountDeactivate(from, message);
    this.handleLangaungeOption(from, message)
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getHeaderLinks();
    this.getToken();
    this.getlanguagesData();
  }

  handleModalAppearance = async (type: any, isOpen: any) => {
    let token = await getStorageData("authToken")
    if (this.state.modalStats.type === "GenSucessfullDeactiveAccount" && !token) {
      this.handleNavigate("LandingPage")
    }
    this.setState({ modalStats: { type, isOpen } });

  };

  ConditionalPanel = (userProps: {
    children: React.ReactNode;
    conditionArray: any;
  }) => {
    const { children, conditionArray } = userProps;
    const condition = conditionArray.every((state: any) => state);
    return condition ? <>{children}</> : null;
  };

  sendInfoToLandingPage = (data: any) => {
    const msg = new Message(getName(MessageEnum.ConnectTOLandingPage));
    msg.addData(getName(MessageEnum.ShouldUpdate), data);
    this.send(msg);
  };

  sendInfoToMainLayout = (data: any) => {
    const msg = new Message(getName(MessageEnum.ConnectTOMainLayout));
    msg.addData(getName(MessageEnum.HeaderLinksData), data);
    this.send(msg);
  };

  handleDrawerMainLayout = () => {
    const msg = new Message(getName(MessageEnum.ConnectTOMainLayout));
    msg.addData(getName(MessageEnum.ToggleSideBar), { data: { open: true } });
    this.send(msg);
  };

  handleNavigate = (path: string) => {
    this.props.navigation.navigate(path);
  };

  handleLogoNavigate = (path: string) => {
    if (window.location.pathname === "/CustomisableUserProfiles") {
      return this.props.navigation.navigate(path);
    }
    window.location.reload();
  };

  conditionSort = (condition: any, trueResult: any, falseResult: any) => {
    return condition ? trueResult : falseResult;
  };

  setLanguage = async () => {
    const storedLanguage = await getStorageData("language");
    const { languageOptions } = this.state;

    if (storedLanguage) {
      const selectedLanguage = languageOptions.find(
        (item: any) => item.attributes.language_code === storedLanguage && item.attributes.active
      );

      if (selectedLanguage) {
        return this.setState({ selectedOption: storedLanguage });
      }
    }

    setStorageData("language", "en");
    this.setState({ selectedOption: "en" });
    this.props.i18n.changeLanguage("en")
    uiChangeLanguage('en')
  };


  getlanguagesData = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLanguageCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_languageoptions/languages`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getToken = async () => {
    let token = await getStorageData("authToken");
    if (!token) return;
    this.setState({ token }, () => {
      this.getUserProfileData();
    });
  };

  handleDrawer = (stats: boolean) => {
    this.setState({ isDrawerOpen: stats });
  };

  popoverhandleClose = () => {
    this.setState({ anchorEl: { language: null, profile: null } });
  };

  popoverhandleOpen = (event: any, type: string) => {
    this.setState({ anchorEl: { [type]: event.currentTarget } });
  };

  handleRadioChange = (event: any) => {
    setStorageData("language", event.target.value);
    this.setState({ selectedOption: event.target.value });
    this.sendInfoToLandingPage({ refresh: true });
    this.popoverhandleClose();
    uiChangeLanguage(event.target.value)
    this.props.i18n.changeLanguage(event.target.value);
  };

  handleRedirectionNewTab = (url: string) => {
    window.open(url, "_blank");
  };

  getHeaderLinks = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHeaderCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage/footer_links?type=header&language=${this.props.i18n.language}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUserProfileData = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiProfileUserDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  accountDeactivate = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeactivateAccount = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_customisableuserprofiles/customisableuserprofiles/deactivate_account`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAccountDeactivate = (from: string, message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiDeactivateAccount !== null &&
      this.apiDeactivateAccount ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        removeStorageData('authToken')
        this.handleModalAppearance("GenSucessfullDeactiveAccount", true)
      }
    }
  };

  handleLangaungeOption = (from: string, message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getLanguageCallId !== null &&
      this.getLanguageCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ languageOptions: responseJson.data }, () => this.setLanguage())
      }
    }
  }

  handleGetHeader = (from: string, message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getHeaderCallId !== null &&
      this.getHeaderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        this.setState({ HeaderData: responseJson.data });
        this.sendInfoToMainLayout({ HeaderLinksArray: responseJson.data.data });
      }
    }
  };

  handleResponseForUserData = (from: string, message: Message) => {
    if (
      this.apiProfileUserDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.data) {
        this.setState({ userData: apiResponse.data.attributes });
      } else {
        isTokenExpired(apiResponse)
      }
    }
  };
  // Customizable Area End
}
