import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { parsePhoneNumber } from 'awesome-phonenumber'
import { getStorageData, setStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { isTokenExpired, translateErrors, preventEType, handleLoader } from "../../../components/src/HelperMethonds"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  t: Function;
  i18n: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  modalStats: any;
  boardList: any;
  formikGlobalprops: any;
  boardInvitationLink: any;
  imageFile: any;
  isLinkCopyDone: boolean;
  isLoading: boolean;
  boardID:any;
  invitationTaskId:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectTaskTrackingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCreateBoardCallId: string = "";
  apiGetBoardCallId: string = "";
  apiDeleteBoardCallId: string = "";
  apiBoardInvitationCallId: string = "";
  apiRemoveInvitationNoCallId: string = "";
  apiDeleteBoardMemberCallId: string = "";
  apiInviteMemberViaWhatsappCallId: string = "";
  apiBoardAndTaskReqCallID: string = "";
  boardIconUpload: any;
  deleteModalTypes: any = [
    configJSON.GenConfirmDeleteMemberID,
    configJSON.GenSucessfullDeleteMemberID,
    configJSON.GenConfirmDeleteBoardID,
    configJSON.GenSucessfullDeleteBoardID
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ConnectTOBoard)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      modalStats: {
        type: "",
        isOpen: false,
        selectedBoard: {},
        selectedMember: {}
      },
      boardList: [],
      formikGlobalprops: {},
      boardInvitationLink: '',
      imageFile: {},
      isLinkCopyDone: false,
      isLoading: false,
      boardID:"",
      invitationTaskId:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    this.handleResponseForInvitationLink(from, message)
    this.handleResponseForGetBoard(from, message)
    this.handleResponseForDeleteBoardMember(from, message)
    this.handleResponseForDeleteBoard(from, message)
    this.handleResponseForCreateBoard(from, message)
    this.handleResponseForInviteMemberViaWhatsapp(from, message)
    this.handleResponseForTaskAndBoardRequest(from, message)
    if (message.id === getName(MessageEnum.ConnectTOBoard)) {
      let data = message.getData(getName(MessageEnum.ShouldUpdate))
      if (data.refresh) {
        this.getBoardList()
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getBoardList()
    removeStorageData('tasklistData');
  }
  getBoardAndTaskId = () =>{
    const boardID = this.props.navigation.getParam("id");
    const invitationTaskId = this.props.navigation.getParam("taskId");
    
    
    if(boardID || invitationTaskId){
      this.setState({ boardID, invitationTaskId });
      this.getBoardAndTaskReq()
    }
  }
  sendInfoToMainLayout = (data: any) => {
    const msg = new Message(getName(MessageEnum.ConnectTOMainLayout))
    msg.addData(getName(MessageEnum.ShouldUpdate), data)
    this.send(msg)
  }

  ConditionalPanel = (userProps: {
    children: React.ReactNode;
    conditionArray: any;
  }) => {
    const { children, conditionArray } = userProps;
    const condition = conditionArray.every((state: any) => state);
    return condition ? <>{children}</> : null;
  };

  handleFileUpload = (event: any, formikProps: any) => {
    const selectedFile = event.target.files[0];

    const allowedFormats = ['image/png', 'image/jpeg', 'image/jpg'];

    if (selectedFile) {
      const fileSizeInMB = selectedFile.size / (1024 * 1024);

      if (fileSizeInMB <= 5) {
        if (allowedFormats.includes(selectedFile.type)) {
          formikProps.setFieldValue('boardIcon', selectedFile)
        } else {
          formikProps.setFieldTouched('boardIcon');
          formikProps.setFieldError('boardIcon', this.props.t('fileTypeValidation', { selectedFileName: selectedFile.name }));
        }
      } else {
        formikProps.setFieldTouched('boardIcon');
        formikProps.setFieldError('boardIcon', this.props.t('fileSizeValidMsg', { selectedFileName: selectedFile.name }));
      }
    }
  };

  conditionSort = (condition: any, trueResult: any, falseResult: any) => {
    return condition ? trueResult : falseResult;
  }

  handleModalAppearance = (type: any, isOpen: any, selectedBoard: any = '', selectedMember: any = '') => {
    this.setState({ modalStats: { type, isOpen, selectedBoard, selectedMember }, isLinkCopyDone: false })
    setStorageData('selectedBoard', JSON.stringify(selectedBoard))
    if (!isOpen) {
      this.getBoardList()
    }
  }

  handleInviteMember = (e: any, values: any, formikProps: any) => {
    formikProps.setFieldTouched('invite_member', false, false)
    preventEType(e, /^[^A-Za-z\W_]+$/)
    if (e.key != 'Enter') {
      return
    }
    if (!parsePhoneNumber(`+${values.invite_member}`).valid) {
      formikProps.setFieldTouched('invite_member')
      return formikProps.setFieldError('invite_member', this.props.t('invalidPhoneNoValidationMsg'))
    }
    let tempArray = values.invite_member_array
    tempArray.push(values.invite_member)
    formikProps.setFieldValue('invite_member', '')
    formikProps.setFieldValue('invite_member_array', tempArray)
  }

  handleDeleteInputInviteMember = (index: number, setFieldValue: any, values: any, deletePermanently?: boolean) => {
    let tempArray = values.invite_member_array
    let deletedElement = tempArray.splice(index, 1)
    setFieldValue('invite_member_array', tempArray)
    if (deletePermanently) {
      this.deleteInvitationNo(deletedElement[0])
    }
  }

  handleSubmitForm = (formikProps: any, formikHandleSubmit: any) => {
    this.setState({ formikGlobalprops: formikProps })
    formikHandleSubmit()
  }

  handleGetBoardInviteLink = (data: any) => {
    this.handleModalAppearance(configJSON.GenInviteMemberID, false, data)
    this.getBoardInvitationLink()
  }

  handleCopyLink = () => {
    navigator.clipboard.writeText(this.state.boardInvitationLink)
    this.setState({ isLinkCopyDone: true })
  }

  handleDeletionInviteMember = async (dataObject: any) => {
    let selectedBoard = await getStorageData('selectedBoard')
    let invitedNo = JSON.parse(selectedBoard).attributes.invited_mobile_numbers
    const removedNumber = invitedNo.filter((value: any) => !dataObject.invite_member_array.includes(value));

    removedNumber.forEach((value: any) => {
      this.deleteInvitationNo(value)
    });
  }

  getBoardAndTaskReq = async () =>{
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": await getStorageData("authToken")
      };
      let httpBody: any = {
        language: this.props.i18n.language,
      };
      if (this.state.boardID && this.state.boardID != 'null') {
        httpBody = { ...httpBody, board_id: this.state.boardID };
      }
  
      if (this.state.invitationTaskId && this.state.invitationTaskId != 'null') {
        httpBody = { ...httpBody, task_id: this.state.invitationTaskId };
      }
      handleLoader(this, true)
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.apiBoardAndTaskReqCallID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.BoardAndTaskReqAPiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBoardList = async () => {
    handleLoader(this, true)
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetBoardCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.boardActionEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createBoard = async (dataObject: any) => {
    handleLoader(this, true)
    const formData = new FormData();
    const header = {
      "token": await getStorageData("authToken")
    };

    const fieldsToAppend: { [key: string]: any } = {
      'data[attributes][name]': dataObject.board_name.trim(),
      'data[attributes][description]': dataObject.board_des.trim()
    };

    dataObject.invite_member_array.forEach((value: any, index: any) => {
      const key = 'data[attributes][invited_mobile_numbers][]';
      formData.append(key, value.trim());
    });

    for (const key in fieldsToAppend) {
      const value = fieldsToAppend[key];
      if (value) {
        formData.append(key, value);
      }
    }

    if (dataObject.boardIcon.size > 0) {
      formData.append('data[attributes][icon]', dataObject.boardIcon);
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateBoardCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.boardActionEndPoint}?language=${this.props.i18n.language}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateBoard = async (dataObject: any) => {
    handleLoader(this, true)
    const header = {
      "token": await getStorageData("authToken")
    };

    const formData = new FormData();
    const fieldsToAppend: { [key: string]: any } = {
      'data[attributes][description]': dataObject.board_des.trim(),
      'data[attributes][name]': dataObject.board_name.trim()
    };
    dataObject.invite_member_array.forEach((value: any, index: any) => {
      const key = 'data[attributes][invited_mobile_numbers][]';

      formData.append(key, value.trim());
    });
    if (dataObject.boardIcon.size > 0) {
      formData.append('data[attributes][icon]', dataObject.boardIcon);
    }
    for (const key in fieldsToAppend) {
      const value = fieldsToAppend[key];
      if (value.length) {
        formData.append(key, value);
      }
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateBoardCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.boardActionEndPoint}/${this.state.modalStats.selectedBoard.id}?language=${this.props.i18n.language}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PatchAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Trigger the function immediately after calling the API, independent of its response
    this.handleDeletionInviteMember(dataObject)
  }

  deleteBoard = async () => {
    handleLoader(this, true)
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteBoardCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.boardActionEndPoint}/${this.state.modalStats.selectedBoard.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DeleteAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBoardInvitationLink = async () => {
    handleLoader(this, true)
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiBoardInvitationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.boardActionEndPoint}/${this.state.modalStats.selectedBoard.id}/board_invitation_link`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteInvitationNo = async (number: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiRemoveInvitationNoCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.boardActionEndPoint}/${this.state.modalStats.selectedBoard.id}/remove_invited_mobile_number?mobile_number=${number}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DeleteAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteBoardMember = async (boardMemberId: any) => {
    handleLoader(this, true)
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteBoardMemberCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.actionBoardMemberEndpoint}/${boardMemberId}/delete_member_from_board`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DeleteAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  inviteMemberViaWhatsapp = async (dataObject: any) => {
    handleLoader(this, true)
    const header = {
      "token": await getStorageData("authToken")
    };

    const formData = new FormData();

    dataObject.invite_member_array.forEach((value: any, index: any) => {
      const key = 'data[attributes][invited_mobile_numbers][]';
      formData.append(key, value.trim());
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiInviteMemberViaWhatsappCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.boardActionEndPoint}/${this.state.modalStats.selectedBoard.id}/invite_board_members?language=${this.props.i18n.language}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  handleResponseForCreateBoard = (from: string, message: Message) => {
    if (this.apiCreateBoardCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleLoader(this, false)
      if (apiResponse.data && apiResponse.meta) {
        this.handleModalAppearance('', false)
        this.getBoardList()
        this.sendInfoToMainLayout({ refresh: true })
        handleLoader(this, false)
      } else {
        this.processErrorArray(apiResponse.errors)
        isTokenExpired(apiResponse)
        handleLoader(this, false)
      }
    }
  }

  handleResponseForGetBoard = (from: string, message: Message) => {
    if (this.apiGetBoardCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.getBoardAndTaskId();
      handleLoader(this, false)
      if (apiResponse.data) {
        this.setState({ boardList: apiResponse.data })
      } else {
        this.setState({ boardList: [] })
        isTokenExpired(apiResponse)
      }
    }
  }

  handleResponseForDeleteBoard = (from: string, message: Message) => {
    if (this.apiDeleteBoardCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleLoader(this, false)
      if (apiResponse.message) {
        this.setState({ modalStats: { ...this.state.modalStats, type: configJSON.GenSucessfullDeleteBoardID } })
        this.getBoardList()
        this.sendInfoToMainLayout({ refresh: true })
      } else {
        isTokenExpired(apiResponse)
      }
    }
  }
  handleResponseForTaskAndBoardRequest =(from: string, message: Message) =>{
    if (this.apiBoardAndTaskReqCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleLoader(this, false)
      if (apiResponse) {
        this.props.navigation.navigate('ProjectTaskTracking');
      }
    }
  }
  handleResponseForInvitationLink = (from: string, message: Message) => {
    if (this.apiBoardInvitationCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleLoader(this, false)
      if (apiResponse.invitation_link) {
        this.setState({ boardInvitationLink: apiResponse.invitation_link, modalStats: { ...this.state.modalStats, isOpen: true } })
      } else {
        isTokenExpired(apiResponse)
      }
    }
  }

  handleResponseForDeleteBoardMember = (from: string, message: Message) => {
    if (this.apiDeleteBoardMemberCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleLoader(this, false)
      if (apiResponse.message) {
        this.setState({ modalStats: { ...this.state.modalStats, type: configJSON.GenSucessfullDeleteMemberID } })
        this.getBoardList();
      } else {
        isTokenExpired(apiResponse)
      }
    }
  }

  handleResponseForInviteMemberViaWhatsapp = (from: string, message: Message) => {
    if (this.apiInviteMemberViaWhatsappCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleLoader(this, false)
      if (apiResponse.invited_mobile_numbers) {
        this.getBoardList()
        this.handleModalAppearance('', false)
      } else {
        this.handleModalAppearance('', false)
        isTokenExpired(apiResponse)
      }
    }
  }

  processErrorArray = (data: any) => {
    const errorMappings: { [key: string]: { field: string; errorField: string } } = {
      invited_mobile_numbers: { field: 'invite_member', errorField: 'invite_member' },
      description: { field: 'board_des', errorField: 'board_des' },
      name: { field: 'board_name', errorField: 'board_name' }
    };

    data.forEach((item: any) => {
      for (const key in errorMappings) {
        if (key in item) {
          const { field, errorField } = errorMappings[key];
          let errStr = translateErrors(item[key], this.props.t)
          this.state.formikGlobalprops.setFieldTouched(field);
          this.state.formikGlobalprops.setFieldError(errorField, errStr);
        }
      }
    });
  };
  // Customizable Area End
}
