import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Tooltip, ButtonBase
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { createTheme, ThemeProvider, withStyles, createStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import TaskGroupsController, {
  Props,
  configJSON,
} from "./TaskGroupsController";

export class TaskGroups extends TaskGroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes, t } = this.props;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Loader loading={this.state.isLoading}/>
        <Box>
          <Typography gutterBottom variant="h3" style={{ textTransform: "capitalize" }}>{this.state.taskListSummary.attributes?.name}</Typography>
        </Box>

        <Box className={classes.headerStyle}>
          <Typography className={classes.fontBold} variant="h5" style={{ textTransform: "capitalize" }}>{this.state.taskListSummary.attributes?.name}</Typography>
          <Typography data-test-id="reportPdfBtn" component={ButtonBase} disableRipple disabled={this.state.taskListSummary.attributes?.tasks.length === 0} onClick={this.openPdfFile} variant="h6" className={classes.PrintIconStyle}> <i> {svgIcon.printIcon} </i> {t('printReportTxt')}</Typography>
        </Box>

        {
          this.state.taskListSummary.attributes?.tasks.length === 0 ? (
            <Typography style={{ marginTop: "20px" }} align="center" variant="body1">{t('noCompletedTaskFoundMsg')}</Typography>
          ) : (
            <Box className={classes.boardContainer}>
              {this.state.taskListSummary.attributes?.tasks.map((val: any) => (
                <Box className={classes.cardStyle} key={val.id}>
                  <Box minHeight={"105px"} display={"flex"} padding={"19px 18px"} flexDirection={"column"} gridRowGap={"9px"} boxSizing={"border-box"}>
                    <Box className={classes.cardHeader}>
                      <Tooltip title={val.attributes.title} placement="top">
                        <Typography variant="body1" className={classes.tittle}>{val.attributes.title} </Typography>
                      </Tooltip>
                      {val.attributes.is_task_completed ? <i>{svgIcon.VerifyGroupIcon}</i> : <CheckCircleOutlineIcon className={classes.completeIcon} />}
                    </Box>
                    <Box>
                      <Typography variant="body2" className={`${classes.fontRegular} ${classes.description}`}> {val.attributes.description}</Typography>
                    </Box>
                  </Box>
                  {svgIcon.Line}
                  <Box boxSizing={"border-box"} display={"flex"} padding={"14px 18px 20px 18px"} flexDirection={"column"} gridRowGap={"10px"}>
                    <Box display={"flex"} gridGap={"8px"}>
                      <Typography variant="subtitle1">{t('completedOnTxt')}</Typography>
                      <Typography variant="subtitle1" className={classes.successColor}>{this.formatDueDate(val.attributes.completion_date)}</Typography>
                    </Box>
                    <Box display={"flex"} gridGap={"8px"}>
                      <Typography variant="subtitle1">{t('completedByTxt')}</Typography>
                      <Tooltip title={val.attributes.completed_by} placement="top">
                        <Typography variant="subtitle1" className={classes.successColor}>{val.attributes.completed_by}</Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          )
        }
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = () => createStyles({
  fontRegular: {
    fontFamily: 'HelveticaNeue Regular',
  },
  completeIcon: {
    color: "#D4D4D4",
    width: "22px",
    height: "22px",
  },
  fontBold: {
    fontFamily: 'HelveticaNeue Bold',
  },
  headerStyle: {
    height: "54px",
    background: "#67C375",
    marginTop: "22px",
    display: "flex",
    alignItems: "center",
    padding: "0 43px 0 24px",
    justifyContent: "space-between" as const,
    borderRadius: "5px"
  },
  PrintIconStyle: {
    display: "flex",
    alignItems: "center",
    color: "#fff",
    gap: "8px",
    fontFamily: 'HelveticaNeue Medium',
    textDecoration: "none"
  },
  boardContainer: {
    width: "100%",
    marginTop: "22px",
    flexWrap: "wrap" as const,
    display: "flex",
    gap: "22px",
    rowGap: "22px",
    [theme.breakpoints.only("xs")]: {
      justifyContent: "center" as const,
    },
  },
  cardStyle: {
    "width": "246px",
    position: "relative" as const,
    "minHeight": "180px",
    height: "auto",
    "borderRadius": "4px",
    "border": "1px solid #D4D4D4",
    "background": "rgb(250,250,250)",
    boxSizing: "border-box"
  },
  tittle: {
    width: "175px",
    lineHeight: "18px",
    textTransform: "capitalize",
    padding: "0",
    "textOverflow": "ellipsis",
    "whiteSpace": "nowrap",
    "overflow": "hidden"
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between" as const,
  },
  description: {
    width: "195px",
    lineHeight: "18px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 2,
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
  },
  successColor: {
    color: "#30893D",
    "textOverflow": "ellipsis",
    "width": "50%",
    "whiteSpace": "nowrap",
    "overflow": "hidden"
  }
});

export const theme = createTheme({
  palette: {
    error: { main: "#F00" },
  },
  typography: {
    fontFamily: 'HelveticaNeue Medium',
    h3: {
      "fontSize": "32px",
      "fontStyle": "normal",
      "fontWeight": 400
    },
    h4: {
      "fontSize": "20px",
      "fontStyle": "normal",
      "fontWeight": 400
    },
    h5: {
      "fontSize": "18px",
      "fontStyle": "normal",
      "fontWeight": 400,
      "color": "#FFF"
    },
    h6: {
      "fontSize": "12px",
      "fontStyle": "normal",
      "fontWeight": 400,
      "color": "#FFF"
    },
    caption: {
      "fontSize": "16px",
      "fontStyle": "normal",
      "fontWeight": 400
    },
    //For Dialog
    body2: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400
    },
    body1: {
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 400
    },
    subtitle1: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: "18px"
    }
  },
});

const svgIcon = {
  printIcon: <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="#67C375">
    <path d="M12.4583 5.16667V3.5C12.4583 1.83333 11.8333 1 9.95829 1H7.04163C5.16663 1 4.54163 1.83333 4.54163 3.5V5.16667H12.4583Z" stroke="#FFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11.8333 15.1666C11.8333 16.8333 11 17.6666 9.33329 17.6666H7.66663C5.99996 17.6666 5.16663 16.8333 5.16663 15.1666V11.8333H11.8333V15.1666Z" stroke="#FFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M16 11.8334C16 13.5 15.1667 14.3334 13.5 14.3334H11.8333V11.8334H5.16667V14.3334H3.5C1.83333 14.3334 1 13.5 1 11.8334V7.66669C1 6.00002 1.83333 5.16669 3.5 5.16669H13.5C15.1667 5.16669 16 6.00002 16 7.66669V11.8334Z" stroke="#FFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12.6667 11.8333H11.6584H4.33337" stroke="#FFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M4.33337 8.5H6.83337" stroke="#FFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>,
  VerifyGroupIcon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path d="M21 11C21 5.5 16.5 1 11 1C5.5 1 1 5.5 1 11C1 16.5 5.5 21 11 21C16.5 21 21 16.5 21 11Z" fill="#67C375" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6.75 11L9.58 13.83L15.25 8.17004" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>,
  Line: <svg xmlns="http://www.w3.org/2000/svg" width="244" height="1" viewBox="0 0 244 1" fill="none">
    <path d="M0.5 0.5H243.5" stroke="#A6A6A6" stroke-opacity="0.5" stroke-linecap="square" />
  </svg>


}

export default withTranslation<TaskGroups>()(withStyles(webStyle)(TaskGroups));
// Customizable Area End
