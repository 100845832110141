import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment from "moment";
import 'moment/locale/ar'
import { isTokenExpired, conditionSort, translateErrors, isArabicLang, handleLoader } from "../../../components/src/HelperMethonds";

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

export interface ValidResponseType {
  data: object;
}
export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>;
}
export interface ErrorPayloadType {
  key: string;
  full_phone_number: string;
  token: string;
  profile: string;
}
export interface AllTaskListArr {
  data: Array<AllTaskListObj>;
}
export interface AllTaskListObj {
  id: string;
  attributes: {
    name: string;
    tasks: Array<TaskListTask>;
    is_task_list_created_by_current_user: boolean;
    is_task_completed: boolean;
  }
}
export interface TaskData {
  data: AllTaskListObj;
}
export interface TaskListTask {
  id: string;
  attributes: {
    id: number;
    title: string;
    due_date: null;
    description: string;
    completion_date: string;
    is_current_user_has_assigned_task: boolean;
    is_task_completed: boolean;
  }
}
export interface ErrorResponse {
  errors: [
    {
      token: string;
      Tasklist: string;
    }
  ]
}
export interface BoardArr {
  data: BoardObj;
}
export interface BoardObj {
  attributes: {
    name: string;
    description: string;
    is_current_user_project_manager: boolean;
  }
}
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object | string;
}
interface ValidResponse {
  data: object;
}
interface ValidResponseMessage {
  message: string;
}
interface CreateTasklList {
  data: {
    attributes: {
      id: number;
      task_list_id: number;
      name: string;
      tasks: Array<TaskListTask>;
    }
  }
}
interface TaskListErrorResponse {
  errors: {
    board: Array<string>;
    name: Array<string>;
  }
}
interface ErrorType {
  message: string;
  type: boolean;
}
interface ModalType {
  type: string;
  isOpen: boolean;
  isEdit: boolean;
  activeTab: number;
  taskId: string;
  boardId: string;
  selectedTask: object;
  taskListId: string;
  taskListName: string;
  isUserManager: boolean;
}
interface CommentType {
  data: Array<CommentList>
}
export interface CommentList {
  id: string;
  attributes: {
    task_id: number;
    commentable_type: string;
    comment: string;
    created_at: string;
    updated_at: string;
    completed_task: boolean;
    account: {
      name: string;
    };
    task: CommentTask;
  }
}
interface CommentTask {
  id: number;
  name: string;
  task_list_id: number;
  is_task_completed: boolean;
}

interface CommentError {
  errors: Array<CommentErrorObj>;
}

interface CommentErrorObj {
  message: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  t: Function;
  i18n: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activeCreateTask: boolean;
  showAllComments: boolean;
  datePickerVisible: boolean;
  openDeleteModal: boolean;
  selectedDate: Value | Date;
  successBtnVisible: boolean;
  printModalVisible: boolean;
  deleteBtnVisible: boolean;
  allTaskListData: Array<AllTaskListObj>;
  boardData: BoardObj;
  errorNotiShow: boolean;
  errorNameMessage: ErrorType;
  errorBoardMessage: ErrorType;
  taskCalenderId: string;
  deleteTaskId: string;
  calenderCurrentTarget: HTMLButtonElement | null;
  boardId: string;
  taskListTitle: string;
  tokenMessage: string;
  modalStates: ModalType;
  taskName: string;
  tempTaskID: string | null;
  errorMessage: string;
  commentListData: Array<CommentList>;
  commentListIndex: number;
  commentData: string;
  commentTaskId: number;
  taskSelect: boolean;
  taskListId: string;
  taskData: AllTaskListObj;
  selectTask: HTMLButtonElement | null;
  selectedTaskId: string;
  taskListCurrentTarget: HTMLInputElement | HTMLTextAreaElement | null;
  selectedIndex: number;
  commentError: string;
  tempIndex: number;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllTaskListApiCallId: string = "";
  getBoardApiCallId: string = "";
  deleteTaskApiCallId: string = "";
  postTaskListApiCallId: string = "";
  patchDueDateApiCallId: string = "";
  patchComplteTaskApiCallId: string = "";
  getCommentListApiCallId: string = "";
  postAddCommentApiCallId: string = "";
  getViewTaskApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ConnectTOTasklist)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      activeCreateTask: false,
      showAllComments: false,
      datePickerVisible: false,
      openDeleteModal: false,
      selectedDate: new Date(),
      successBtnVisible: false,
      deleteBtnVisible: false,
      allTaskListData: [],
      boardData: {
        attributes: {
          name: "",
          description: "",
          is_current_user_project_manager: false
        }
      },
      errorNotiShow: false,
      errorNameMessage: {} as ErrorType,
      errorBoardMessage: {} as ErrorType,
      taskCalenderId: "",
      deleteTaskId: "",
      calenderCurrentTarget: null,
      boardId: this.props.navigation.getParam("id"),
      taskListTitle: "",
      tokenMessage: "",
      modalStates: {
        type: "",
        isOpen: false,
        isEdit: false,
        activeTab: 0,
        taskId: "",
        boardId: this.props.navigation.getParam("id"),
        selectedTask: {} as ModalType,
        taskListId: "",
        taskListName: "",
        isUserManager: false,
      },
      taskName: "",
      errorMessage: "",
      commentListData: [],
      commentListIndex: NaN,
      commentData: "",
      commentTaskId: NaN,
      taskSelect: false,
      taskListId: "",
      taskData: {} as AllTaskListObj,
      selectTask: null,
      selectedTaskId: "",
      taskListCurrentTarget: null,
      selectedIndex: NaN,
      commentError: "",
      tempIndex: NaN,
      printModalVisible: false,
      tempTaskID: null,
      isLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    moment.locale('en')
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.ConnectTOTasklist)) {
      let data = message.getData(getName(MessageEnum.ShouldUpdate))
      if (data && data.refresh) {
        this.getAllTaskListDataShow()
        this.getCommentListDataShow(this.state.taskListId);
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isValidResponseMessage(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    this.getAllTaskListDataShow();
    this.getBoradDataShow();
  }

  sendInfoToMainLayout = (data: any) => {
    const msg = new Message(getName(MessageEnum.ConnectTOMainLayout))
    msg.addData(getName(MessageEnum.TaskListData), data)
    this.send(msg)
  }

  openPdfFile = () => {
    this.deactivePrintModal()
    window.open(`${require('../../../framework/src/config').baseURL}/${configJSON.taskListPdfEndPoint}/${this.state.tempTaskID}?format=pdf&language=${this.props.i18n.language}`, '_blank');
  }

  isValidResponse = (responseJson: ValidResponse) => {
    return responseJson && responseJson.data;
  };

  isValidResponseMessage = (responseJson: ValidResponseMessage) => {
    return responseJson && responseJson.message;
  };

  isInValidResponse = (responseJson: ErrorResponse) => {
    return responseJson && responseJson.errors;
  };

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: AllTaskListArr & BoardArr & ValidResponseMessage & CreateTasklList & CommentType & CommentList & TaskData) => {
    if (apiRequestCallId === this.getAllTaskListApiCallId) {
      handleLoader(this, false)
      this.getAllTaskListDataSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getBoardApiCallId) {
      handleLoader(this, false)
      this.getBoardSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteTaskApiCallId) {
      handleLoader(this, false)
      this.deleteTaskSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postTaskListApiCallId) {
      handleLoader(this, false)
      this.postTaskListSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.patchDueDateApiCallId) {
      handleLoader(this, false)
      this.patchDueDateSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.patchComplteTaskApiCallId) {
      handleLoader(this, false)
      this.patchCompleteTaskSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getCommentListApiCallId) {
      handleLoader(this, false)
      this.getCommentListDataSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postAddCommentApiCallId) {
      handleLoader(this, false)
      this.postAddCommentSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getViewTaskApiCallId) {
      handleLoader(this, false)
      this.getViewTaskSuccessCallBack(responseJson);
    }
  };

  apiFailureCallBacks = (apiRequestCallId: string, responseJson: ErrorResponse & TaskListErrorResponse & CommentError) => {
    isTokenExpired(responseJson);
    if (apiRequestCallId === this.getAllTaskListApiCallId) {
      handleLoader(this, false)
      this.getAllTaskListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.getBoardApiCallId) {
      handleLoader(this, false)
      this.getBoardFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteTaskApiCallId) {
      handleLoader(this, false)
      this.deleteTaskFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.postTaskListApiCallId) {
      handleLoader(this, false)
      this.postTaskListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.getCommentListApiCallId) {
      handleLoader(this, false)
      this.getCommentListFailureCallBack(responseJson);
    }
  };

  apiCall = (data: APIPayloadType) => {
    handleLoader(this, true)
    let { contentType, method, endPoint, body } = data;
    const token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": contentType,
      token
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getAllTaskListDataShow = () => {
    this.getAllTaskListApiCallId = this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getAllTaskListEndpoint}?board_id=${this.props.navigation.getParam("id")}`,
    });
  };

  getAllTaskListDataSuccessCallBack = (responseJson: AllTaskListArr) => {
    this.setState({ allTaskListData: responseJson.data });
    this.sendInfoToMainLayout({ data: responseJson.data, message: "Tasklist Data" })
  };

  getAllTaskListFailureCallBack = (responseJson: ErrorResponse) => {
    this.sendInfoToMainLayout({ data: [], message: this.props.t('taskListNotFound') })
    this.setState({ errorNotiShow: true, tokenMessage: responseJson.errors[0].token, errorMessage: responseJson.errors[0].Tasklist });
  };

  getBoradDataShow = () => {
    this.getBoardApiCallId = this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getBoardEndpoint}/${this.props.navigation.getParam("id")}`,
    });
  };

  getBoardSuccessCallBack = (responseJson: BoardArr) => {
    this.setState({ boardData: responseJson.data });
  };

  getBoardFailureCallBack = (responseJson: ErrorResponse) => {
    this.setState({
      errorNotiShow: true,
      tokenMessage: responseJson.errors[0].token
    });
  };

  getCommentListDataShow = (taskListId: string) => {
    this.getCommentListApiCallId = this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.commentList}${taskListId}&language=${this.props.i18n.language}`,
    });
  };

  getCommentListDataSuccessCallBack = (responseJson: CommentType) => {
    this.setState({ commentListData: responseJson.data, commentError: '', commentListIndex: this.state.tempIndex });
  };

  getCommentListFailureCallBack = (responseJson: CommentError) => {
    this.setState({ commentError: responseJson.errors[0].message, commentListIndex: this.state.tempIndex });
  };

  getViewTaskDataShow = (taskListId: string) => {
    this.getViewTaskApiCallId = this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.viewTaskEndPoint}/${taskListId}`,
    });
  };

  getViewTaskSuccessCallBack = (responseJson: TaskData) => {
    this.setState({ taskData: responseJson.data });
  };

  deleteTask = () => {
    this.deleteTaskApiCallId = this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.deleteType,
      endPoint: `${configJSON.deleteTaskEndpoint}/${this.state.deleteTaskId}`,
    });
  };

  deleteTaskSuccessCallBack = (responseJson: ValidResponseMessage) => {
    if (responseJson) {
      this.getAllTaskListDataShow();
      this.setState({
        successBtnVisible: true,
        deleteBtnVisible: false,
        commentListIndex: NaN,
        tempIndex: NaN
      });
    }
  };

  deleteTaskFailureCallBack = (responseJson: ErrorResponse) => {
    this.setState({
      errorNotiShow: true,
      tokenMessage: responseJson.errors[0].token
    });
  };

  handleCreateTaskList = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    this.setState({ isLoading: true })
    let formdata = new FormData();
    formdata.append("task_list[board_id]", this.props.navigation.getParam("id"));
    formdata.append("task_list[name]", this.state.taskListTitle);

    this.postTaskListApiCallId = this.apiCall({
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.postTaskListEndpoint,
      body: formdata
    });
  };

  postTaskListSuccessCallBack = (responseJson: CreateTasklList) => {
    this.getAllTaskListDataShow();
    this.setState({
      activeCreateTask: false,
      isLoading: false,
      errorNameMessage: { type: false, message: "" },
      taskListTitle: ""
    });
  };

  postTaskListFailureCallBack = (responseJson: TaskListErrorResponse) => {
    const error = responseJson.errors;
    if (error.name) {
      this.setState({
        errorNotiShow: true,
        isLoading: false,
        errorNameMessage: { type: true, message: translateErrors(responseJson.errors.name[0], this.props.t) }
      });
    }
    if (error.board) {
      this.setState({
        errorNotiShow: true,
        errorBoardMessage: { type: true, message: translateErrors(responseJson.errors.board[0], this.props.t) }
      });
    }
  };

  handleDueDate = (formatedDate: string) => {
    let raw = JSON.stringify({
      "due_date": formatedDate
    });

    this.patchDueDateApiCallId = this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.patchMethod,
      endPoint: `${configJSON.taskEndPoint}/${this.state.taskCalenderId}/${configJSON.dueDateEndPoint}`,
      body: raw
    });
  };

  patchDueDateSuccessCallBack = (responseJson: CreateTasklList) => {
    this.getAllTaskListDataShow();
  };

  handleCompleteTask = (taskId: string, is_current_user_has_assigned_task: boolean) => {
    if (is_current_user_has_assigned_task) {
      this.patchComplteTaskApiCallId = this.apiCall({
        contentType: configJSON.exampleApiContentType,
        method: configJSON.patchMethod,
        endPoint: `${configJSON.taskEndPoint}/${taskId}/${configJSON.completeTask}?language=${this.props.i18n.language}`,
      });
      this.setState({ commentListIndex: NaN, tempIndex: NaN })
    }
  };

  patchCompleteTaskSuccessCallBack = (responseJson: CreateTasklList) => {
    this.getAllTaskListDataShow();
    this.getCommentListDataShow(responseJson.data.attributes.task_list_id as unknown as string);
  };

  handleAddComment = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    this.setState({ isLoading: true })
    let formdata = new FormData();
    formdata.append("comment[task_id]", this.state.selectedTaskId);
    formdata.append("comment[comment]", this.state.commentData);
    formdata.append("comment[commentable_type]", configJSON.commentableType);

    this.postAddCommentApiCallId = this.apiCall({
      method: configJSON.exampleAPiMethod,
      endPoint: `${configJSON.addCommentEndPoint}?language=${this.props.i18n.language}`,
      body: formdata
    });
  };

  postAddCommentSuccessCallBack = (responseJson: CommentList) => {
    this.getCommentListDataShow(this.state.taskListId);
    this.getViewTaskDataShow(this.state.taskListId);
    this.setState({ commentData: "", taskSelect: false, taskListCurrentTarget: null, selectedIndex: NaN, selectedTaskId: '', isLoading: false });
  };

  activeCreateTask = () => {
    this.setState({ activeCreateTask: true, commentListIndex: NaN, tempIndex: NaN });
  };

  cancleCreateTask = () => {
    this.setState({ activeCreateTask: false, errorNameMessage: { type: false, message: "" }, errorBoardMessage: { type: false, message: "" } });
  };

  activeCommentContainer = (taskListId: string, index: number) => {
    this.getCommentListDataShow(taskListId);
    this.getViewTaskDataShow(taskListId);
    this.setState({ showAllComments: true, tempIndex: index, taskListId: taskListId, commentData: '', commentListData: [], selectedTaskId: '', taskListCurrentTarget: null, activeCreateTask: false });
  };

  deActivateCommentContainer = () => {
    this.setState({ showAllComments: false, commentListIndex: NaN, tempIndex: NaN });
  }

  activeDatePicker = (event: React.MouseEvent<HTMLButtonElement>, id: string, date: string | null, isUserProjectManager: boolean, isTaskCompleted: boolean) => {
    let taskDate: string = date as string
    if (!date) {
      taskDate = new Date() as unknown as string
    }
    if (isUserProjectManager && !isTaskCompleted) {
      this.setState({
        datePickerVisible: !this.state.datePickerVisible,
        taskCalenderId: id,
        calenderCurrentTarget: event.currentTarget,
        selectedDate: taskDate as unknown as Value
      });
    }
  };

  handleCloseCalender = () => {
    this.setState({ calenderCurrentTarget: null });
  };

  activeDeleteModal = (id: string, taskName: string) => {
    this.setState({
      openDeleteModal: true,
      deleteBtnVisible: true,
      deleteTaskId: id,
      taskName: taskName
    });
  };

  activePrintModal = (taskName: string, taskId: string) => {
    this.setState({
      openDeleteModal: true,
      printModalVisible: true,
      taskName: taskName,
      tempTaskID: taskId
    });
  };

  deactivePrintModal = () => {
    this.setState({
      openDeleteModal: false,
      printModalVisible: false,
      taskName: '',
      tempTaskID: ''
    });
  };

  deactiveDeleteModal = () => {
    this.setState({
      openDeleteModal: false,
      deleteBtnVisible: false,
      printModalVisible: false,
      successBtnVisible: false,
      taskName: '',
      tempTaskID: ''
    });
  };

  handleHomepage = () => {
    this.setState({
      openDeleteModal: false,
      successBtnVisible: false
    });
  };

  handleDateChange = (value: Value) => {
    this.setState({
      selectedDate: value,
      datePickerVisible: false,
      calenderCurrentTarget: null,
    }, () => {
      if (this.state.selectedDate !== null && this.state.selectedDate instanceof Date) {
        const formatedDate = `${this.state.selectedDate.getDate()}-${this.state.selectedDate.getMonth() + 1}-${this.state.selectedDate.getFullYear()}`;
        this.handleDueDate(formatedDate);
      }
    });
  };

  formatNavigationLabel = () => {
    const date = moment(this.state.selectedDate as unknown as string);
    const enDate = date.locale("en")
    const formattedDateEn = enDate.format('DD MMMM, YYYY');

    let arabicDate = `${enDate.format("DD")} ${moment(date).locale('ar').format("MMMM")} ${enDate.format("YYYY")}`

    let formattedDate = conditionSort(isArabicLang(this.props.i18n.language), arabicDate, formattedDateEn)
    return formattedDate;
  };

  formatDateTime = (time: string) => {
    const date = moment(time)
    const englishTime = date.locale('en')
    const formattedDateEng = englishTime.format("DD MMM, HH:mm A");

    let arabicDate = `${englishTime.format("DD")} ${moment(date).locale('ar').format("MMM")} ${englishTime.format("HH:mm")} ${moment(date).locale('ar').format("A")}`

    let formattedDate = conditionSort(isArabicLang(this.props.i18n.language), arabicDate, formattedDateEng)

    return formattedDate;
  };

  taskListTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 40) {
      this.setState({ taskListTitle: event.target.value, errorNameMessage: { type: false, message: "" } });
    
    } else {
      this.setState({ taskListTitle: event.target.value });
    }
  };

  handleViewTask = (type: string, isOpen: boolean, id: string, taskListId: string, isEdit: boolean, taskListName: string, isUserManager: boolean) => {
    this.setState({ modalStates: { ...this.state.modalStates, type, isOpen, isEdit, taskId: id, taskListId: taskListId, taskListName: taskListName, isUserManager }, commentListIndex: NaN, tempIndex: NaN });
  };

  handleCallback = (isOpen: boolean) => {
    this.setState({ modalStates: { ...this.state.modalStates, isOpen, isEdit: false } });
  };

  formatDueDate = (date: string | null) => {
    const today = moment().startOf('day');
    const inputDate = moment(date);

    if (inputDate.isSame(today, 'day')) {
      return this.props.t('dueTodayTxt');
    }

    const tomorrow = today.clone().add(1, 'day');
    if (inputDate.isSame(tomorrow, 'day')) {
      return this.props.t('dueTomorrowTxt');
    }

    let momentObj = moment(inputDate).locale("en")
    let arabicDate = `${momentObj.format("D")} ${moment(inputDate).locale('ar').format("MMM")}`

    let DynamicDate = conditionSort(isArabicLang(this.props.i18n.language), arabicDate, momentObj.format("MMM D"))

    return this.props.t('dueDateTxt', { DynamicDate })
  };

  addComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ commentData: event.target.value });
  };

  handleFocus = (event: { currentTarget: any }) => {
    this.setState({ taskSelect: true, taskListCurrentTarget: event.currentTarget });
  };

  handleTaskId = (taskId: string, index: number) => {
    this.setState({ selectedTaskId: taskId, selectedIndex: index, taskListCurrentTarget: null });
  };

  handleClose = () => {
    this.setState({ taskListCurrentTarget: null });
  };
  // Customizable Area End
}