

import {
  // Customizable Area Start
  Box,
  Input,
  Button,
  ButtonBase,
  Grid,
  Dialog,
  Avatar,
  Chip,
  Switch,
  Typography,
  Badge,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Popover,
  Table,
  TextField,
  IconButton,
  Tabs,
  Tab,
  Paper,
  InputAdornment
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import AddIcon from '@material-ui/icons/Add';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { editIcon } from "../src/assets";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { isEmptyObject, conditionSort, isArabicLang } from "../../../components/src/HelperMethonds";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import TaskAllocatorController, {
  Props,
  configJSON,
} from "./TaskAllocatorController";


export class TaskAllocator extends TaskAllocatorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start   
    // Customizable Area End
  }


  // Customizable Area Start  

  commongForm = (values: any, setFieldError: any, setFieldValue: any, setFieldTouched: any, errors: any, handleChange: any, touched: any) => {
    const { t } = this.props
    return <>
      <Box>
        <Typography gutterBottom variant="body1">
          {t('taskNameInputLabel')}
          <span className={this.props.classes.reqField}>*</span>
        </Typography>
        <Input
          name="tittle"
          data-test-id="txtInputName"
          className={this.props.classes.inputRage}
          value={values.tittle}
          onChange={handleChange}
          fullWidth={true}
          disabled={!this.state.modalStats.isEdit}
          placeholder={t('taskNamePlaceholderTxt')}
          disableUnderline={true}
        />
        {errors.tittle && touched.tittle && this.GenErrorText({ type: "error", message: errors.tittle })}
      </Box>

      <Box>
        <Typography gutterBottom variant="body1">{t('taskDesInputLabel')}</Typography>
        <Input
          name="description"
          value={values.description}
          onChange={handleChange}
          data-test-id="txtInputcreateDes"
          className={this.props.classes.textAreaRage}
          fullWidth={true}
          disabled={!this.state.modalStats.isEdit}
          rows={4}
          multiline
          placeholder={t('taskDesPlaceholderTxt')}
          disableUnderline={true}
        />
        {errors.description && touched.description && this.GenErrorText({ type: "error", message: errors.description })}
      </Box>
    </>

  }

  commonBadge = (values: any, setFieldValue: any) => {
    return <Box className={this.props.classes.chipContainer}>
      {values.assign_member_array.map((data: any, index: number) => (
        <Badge key={data.id} data-test-id="chipcancel" badgeContent={
          <ButtonBase disableRipple onClick={() => this.handleDelete(index, setFieldValue, values,)} className={this.props.classes.cancelChipIcon}>
            {SvgIcons.chipCancelIcon}
          </ButtonBase>}
        >
          <Chip
            className={this.props.classes.chipRage}
            label={data.full_name}
            variant="outlined"
            size="small" />
        </Badge>
      ))}
    </Box>
  }

  GenCreateTask = () => {
    const { t, i18n, classes } = this.props
    return (
      <Box id="y46" className={this.props.classes.container}>
        <Box className={this.props.classes.headSection}>
          <AddIcon id="y47" className={this.props.classes.addIcon} />
          <Box>
            <Typography variant="body1">{t('createTaskTxt')}</Typography>
            <Typography id="y49" className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)} variant="caption">
              {t('taskModalSubHeader')}
            </Typography>
          </Box>
        </Box>
        <Formik
          validateOnBlur={false}
          onSubmit={(dataObject) => {
            this.createTask(dataObject)
          }}

          initialValues={{
            tittle: '',
            description: '',
            assign_member: '',
            assign_member_array: []
          }}
          validationSchema={Yup.object().shape({
            tittle: Yup.string().trim().required(t('taskNameReqMsg')).max(25, t('taskNameMaxLengthMsg'))
          })}
          render={({
            errors,
            touched,
            handleChange,
            handleSubmit,
            values,
            setFieldValue,
            setFieldError,
            setFieldTouched
          }) => {
            return (
              <>
                <Box className={this.props.classes.inputSection}>
                  {this.commongForm(values, setFieldError, setFieldValue, setFieldTouched, errors, handleChange, touched)}
                  <Box>
                    <Typography gutterBottom variant="body1">{t('assignMemberInputLabel')}</Typography>
                    <Autocomplete
                      classes={{ inputRoot: this.conditionSort(isArabicLang(i18n.language), classes.pLeftAutoComp, classes.pRightAutoComp) }}
                      noOptionsText={t('noMemberFoundTxt')}
                      options={this.state.taskMember.map((item: any) => ({ full_name: item.attributes.full_name, account_id: item.id })) || []}
                      getOptionLabel={(option: any) => option.full_name}
                      style={{
                        width: "100%", borderRadius: "7px",
                        border: "1px solid #F2F2F2"
                      }}
                      data-test-id="txtInputassignmember"
                      value={this.state.taskMember.find((item: any) => (item.id === values.assign_member) && item.full_name)}
                      inputValue={values.assign_member}
                      onInputChange={(event: any) => {
                        if (event && event.target && event.target.value)
                          setFieldValue('assign_member', event.target.value)
                        else
                          setFieldValue('assign_member', "")
                      }}
                      onChange={(event, newValue: any) => {
                        if (newValue) {
                          this.handleAssignMembers(event, values, newValue, setFieldValue, setFieldError);
                        }
                      }}
                      disableClearable
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          className={this.props.classes.autoFieldStyle}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: this.renderArrowIconAutoComplete(params, i18n),
                            disableUnderline: true, classes: {
                              input: conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)
                            }
                          }}
                          placeholder={t('assignMemberPlaceholder')}
                        />
                      )}
                    />
                  </Box>
                  {this.commonBadge(values, setFieldValue)}
                </Box>
                <Button data-test-id="formSubmitBtn" disabled={this.state.isLoading} onClick={() => this.handleSubmitForm({ setFieldError, setFieldTouched }, handleSubmit)} className={this.props.classes.createUpdateBoardBtn}>
                  {t('createTaskTxt')}
                </Button>
              </>
            )
          }} />
      </Box>
    )
  }

  GenEditTAsk = () => {
    const { calenderCurrentTarget } = this.state;
    const { t, i18n, classes } = this.props
    const openCalender = Boolean(calenderCurrentTarget);
    return (
      <Box className={this.props.classes.container}>
        <Box className={this.props.classes.headSection}>
          <img src={editIcon} alt="" className={this.props.classes.editIcon} />
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <Typography variant="body1" style={{ textTransform: "capitalize" }}>{this.state.modalStats.selectedTask?.attributes?.title}</Typography>
            <Paper
              className={this.props.classes.group_icon}>
              <ButtonBase disableRipple onClick={() => this.openPdfFile(this.state.modalStats.taskId)}>
                {SvgIcons.grop_icon}
              </ButtonBase>
            </Paper>
          </Box>
        </Box>
        <Box className={this.props.classes.nortificationOuter} >
          <div className={this.props.classes.nortification} >
            <Typography className={this.props.classes.fontRegular1} style={{ marginTop: "8px" }} variant="caption"> {t('notificationTxt')} </Typography>
            <Switch
              data-test-id="WNSwitch"
              onChange={this.handleNotificationSwitch}
              checked={this.state.taskNotificationStatus}
              focusVisibleClassName={this.props.classes.focusVisible}
              disableRipple
              classes={{
                root: this.props.classes.rootSwitch,
                switchBase: this.props.classes.switchBase,
                thumb: this.props.classes.thumb,
                track: this.props.classes.track,
                checked: this.props.classes.checked,
              }}
              disabled={!this.state.modalStats.isEdit}
            />
          </div>
          <button disabled={!this.state.modalStats.isEdit} style={{ cursor: "pointer" }} className={this.props.classes.nortification} onClick={(event: React.MouseEvent<HTMLButtonElement>) => this.activeDatePicker(event, this.state.modalStats.selectedTask?.attributes?.id)} data-test-id={"datePicker"}>
            <Typography className={this.props.classes.fontRegular1} variant="caption"> {t('dueDateTitleTxt')} </Typography>
            <Typography className={this.props.classes.fontRegular1} style={{ marginTop: "8px", fontWeight: 600 }} variant="caption"> {this.formatDueDate(this.state.modalStats.selectedTask?.attributes?.due_date)} </Typography>
          </button>
          <Popover
            open={openCalender}
            anchorEl={calenderCurrentTarget}
            onClose={this.handleCloseCalender1}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            PaperProps={{
              style: { borderRadius: "10px", boxShadow: "none" }
            }}
            data-testid={"closeCalenderTestID"}
          >
            <Calendar
              minDate={new Date()}
              locale={conditionSort(isArabicLang(i18n.language), 'ar', 'en')}
              onChange={this.handleDateChange1}
              value={this.state.selectedDate1}
              nextLabel={false}
              next2Label={false}
              prevLabel={false}
              prev2Label={false}
              className={this.props.classes.calenderMainContainerStyle}
              data-testid={"calenderTestId"}
            />
          </Popover>

        </Box>
        <Formik
          enableReinitialize
          validateOnBlur={false}
          onSubmit={(dataObject) => {
            this.updateTask(dataObject)
          }}
          initialValues={{
            assign_member: '',
            tittle: this.state.modalStats.selectedTask?.attributes?.title || '',
            assign_member_array: this.state.modalStats.selectedTask?.attributes?.assign_members || [],
            description: this.state.modalStats.selectedTask?.attributes?.description || ''
          }}
          validationSchema={Yup.object().shape({
            tittle: Yup.string().trim().required(t('taskNameReqMsg')).max(25, t('taskNameMaxLengthMsg'))
          })
          }
          render={({
            errors,
            touched,
            handleChange,
            handleSubmit,
            values,
            setFieldValue,
            setFieldError,
            setFieldTouched
          }) => {
            return (
              <Box className={this.props.classes.inputSection}>
                {this.commongForm(values, setFieldError, setFieldValue, setFieldTouched, errors, handleChange, touched)}
                <Box>
                  <Typography gutterBottom variant="body1">{t('assignMemberInputLabel')}</Typography>
                  {this.state.modalStats.isEdit ?
                    <Box>
                      <Autocomplete
                        classes={{ inputRoot: this.conditionSort(isArabicLang(i18n.language), classes.pLeftAutoComp, classes.pRightAutoComp) }}
                        noOptionsText={t('noMemberFoundTxt')}
                        id="food-item-search"
                        data-test-id="txtInputassignmember"
                        options={this.state.taskMember.map((item: any) => ({ full_name: item.attributes.full_name, account_id: item.id }))}
                        getOptionLabel={(option: any) => option.full_name}

                        value={this.state.taskMember.find((item: any) => (item.id === values.assign_member) && item.full_name)}
                        inputValue={values.assign_member}
                        onInputChange={(event: any) => {
                          if (event && event.target && event.target.value)
                            setFieldValue('assign_member', event.target.value)
                          else
                            setFieldValue('assign_member', "")
                        }}
                        style={{
                          width: "100%", borderRadius: "7px",
                          border: "1px solid #F2F2F2"
                        }}
                        onChange={(event, newValue: any) => {
                          if (newValue) {
                            setFieldValue('assign_member', newValue.id)
                            this.handleAssignMembers(event, values, newValue, setFieldValue, setFieldError)
                          }
                        }}
                        disableClearable
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: this.renderArrowIconAutoComplete(params, i18n),
                              disableUnderline: true, classes: {
                                input: conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)
                              }
                            }}
                            className={this.props.classes.autoFieldStyle}
                            placeholder={t('assignMemberPlaceholder')}
                          />
                        )}
                      />
                      {this.commonBadge(values, setFieldValue)}
                      <Button data-test-id="formSubmitBtn2" onClick={() => this.handleSubmitForm({ setFieldError, setFieldTouched }, handleSubmit)} className={this.props.classes.createUpdateBoardBtn}>
                        {t('updateTaskTxt')}
                      </Button>
                    </Box> :

                    <Box >
                      <Box className={this.props.classes.chipContainer}>
                        <Box style={{ display: "flex", alignItems: "center", gap: "18px" }}>
                          <AvatarGroup spacing={"small"}>
                            {this.state.modalStats.selectedTask.attributes?.assign_members.slice(0, 5).map((val: any, index: number) => {
                              return <Avatar style={{ zIndex: index }} className={this.conditionSort(isArabicLang(i18n.language), classes.memberAvatarArabic, classes.memberAvatar)} src={val.avatar?.url} alt={val.full_name} key={val.id} />
                            })}
                          </AvatarGroup>
                          {this.state.modalStats.selectedTask.attributes?.assign_members?.length > 0 ? <Typography className={this.props.classes.memberCount}>{this.state.modalStats.selectedTask?.attributes?.assign_members.length}  {t('membersTxt')}</Typography> : <Typography className={this.props.classes.memberCount1}>{t('noMemberFoundTxt')}</Typography>}
                        </Box>
                      </Box>
                      {this.state.modalStats.isUserManager && <Button data-test-id="editBtn" onClick={this.handleEdit} className={this.props.classes.createUpdateBoardBtn}>
                        {t('editTaskTxt')}
                      </Button>}
                    </Box>
                  }
                </Box>
              </Box>
            )
          }} />
      </Box>
    )
  }

  viewTaskListSummury = () => {
    const { t, i18n, classes } = this.props
    return (
      <Box className={classes.dialogContainer} >
        <Box className={classes.headSection}>
          <img src={editIcon} alt="" className={classes.editIcon} />
          <Typography variant="body1" data-testid={"taskSummaryTestId"} style={{ textTransform: "capitalize" }}>{this.state.modalStats.taskListName} {t("summaryTxt")}</Typography>
        </Box>
        {/* tab */}
        <Box >
          <Tabs
            value={this.state.modalStats.activeTab}
            data-test-id="activeTab"
            onChange={this.handleActiveTab}
            textColor="secondary"
            TabIndicatorProps={{
              style: {
                backgroundColor: '#000',
                height: 3,
                borderRadius: "2px 2px 0px 0px",
              }
            }}
            className={conditionSort(isArabicLang(i18n.language), classes.tabStyleContainerArabicAlter, classes.tabStyleContainer)}
          >
            <Tab label={t('createdTabName')} className={classes.tabStyle} />
            <Tab label={t('ongoingTabName')} className={classes.tabStyle} />
            <Tab label={t('OverdueTabName')} className={classes.tabStyle} />
            <Tab label={t('CompletedTabName')} className={classes.tabStyle} />
          </Tabs>
        </Box>
        {/* table */}
        <TableContainer className={classes.tableContainter} >
          <Table aria-label="simple table">

            <TableHead>
              <TableRow>

                <TableCell padding="none" style={{ paddingRight: conditionSort(isArabicLang(i18n.language), '19px', '') }} align={conditionSort(isArabicLang(i18n.language), 'right', 'left')} className={classes.tableHead}>
                  <Typography variant="caption" >{t('taskNameTxt')}</Typography> </TableCell>
                <TableCell padding="none" align="center" className={classes.tableHead}>
                  <Typography variant="caption">{t('assignToTxt')}</Typography>
                </TableCell>
                <TableCell padding="none" style={{ paddingRight: conditionSort(isArabicLang(i18n.language), '', '19px') }} align={conditionSort(isArabicLang(i18n.language), 'left', 'right')} className={classes.tableHead}>
                  <Typography variant="caption">{t('dueDateTitleTxt')}</Typography>
                </TableCell>

              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.taskListSummary.length > 0 ? (
                this.state.taskListSummary.map((row: any) => (
                  <TableRow key={row.id}>

                    <TableCell align={conditionSort(isArabicLang(i18n.language), 'right', 'left')} className={classes.tableCol} component="th" scope="row">
                      <Typography color="textSecondary" className={`${classes.fontRegular} ${classes.textWrap}`} variant="caption" style={{ textTransform: "capitalize" }}> {row.attributes.title}</Typography>
                    </TableCell>
                    <TableCell align="center" className={classes.tableCol} >
                      <Chip
                        className={this.conditionSort(row.attributes.assign_member, classes.chipRageAssigned, classes.chipRageNotAssigned)}
                        label={this.conditionSort(row.attributes.assign_member, row.attributes.assign_member.full_name, t('notAssignYetTxt'))}
                        variant="outlined"
                        size="small" />
                    </TableCell>
                    <TableCell align={conditionSort(isArabicLang(i18n.language), 'left', 'right')} className={classes.tableCol} >
                      <Typography className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)} variant="caption">
                        <Chip
                          className={this.conditionSort(row.attributes.due_date && this.state.modalStats.activeTab != 3, classes.chipRagePresentDueDate, classes.chipRageMTCompDueDate)}

                          label={
                            <div style={{ flexDirection: conditionSort(isArabicLang(i18n.language), 'row-reverse', 'row') }} className={classes.taskCompletedChip}>{this.conditionSort(this.state.modalStats.activeTab === 3, SvgIcons.verifyIcon, "")} {this.conditionSort(row.attributes.due_date !== null, this.formatDueDate(row.attributes.due_date), t('asDueDateTxt'))}</div>
                          }

                          variant="outlined"
                          size="small" />
                      </Typography>
                    </TableCell>

                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell style={{ borderBottom: "none" }} colSpan={3} align="center">
                    <Typography variant="subtitle1">{t('noDataAvailableMsg')}</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>

          </Table>
        </TableContainer>
      </Box>
    )
  }

  GenErrorText = (erorrProps: any) => {
    const Icon1 = this.conditionSort(erorrProps.type == 'success', CheckCircleOutlineOutlinedIcon, InfoOutlinedIcon)
    return (
      <Typography
        color={this.conditionSort(erorrProps.type == 'success', 'secondary', 'error')}
        className={this.props.classes.commonError} variant='subtitle1'>
        <span>
          <Icon1
            className={this.conditionSort(erorrProps.type == 'success', '', this.props.classes.errorIcon)}
            color={this.conditionSort(erorrProps.type == 'success', 'secondary', 'error')} fontSize='small' />
        </span>
        {erorrProps.message}
      </Typography>
    )
  }

  GenInviteMemberInTask = () => {
    const { t, i18n, classes } = this.props
    return (
      <Box className={this.props.classes.container}>
        <Box>
          <Box className={this.props.classes.headerTitleContainer}>
            <Typography variant="body2">{t('inviteMemberTxt')}</Typography>
            <Chip className={this.props.classes.headingChip} variant="outlined"
              label={<Typography variant="subtitle2" className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)}>
                {this.state.modalStats?.selectedTask?.attributes?.title}
              </Typography>}
            />
          </Box>
          <Typography style={{ color: "#959494" }} className={conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)} variant="caption">
            {t('inviteMemberModalSubHeader')}
          </Typography>
        </Box>

        <Box className={this.props.classes.inputSection}>
          <Box>
            <Typography gutterBottom variant="body1">{t('viaLinkInputLabel')}</Typography>
            <Input
              data-test-id="taskIniviteLinkInput"
              style={{ paddingRight: "7px" }}
              classes={{ focused: this.props.classes.foucsInput }}
              className={this.conditionSort(isArabicLang(i18n.language), `${this.props.classes.inviteInputRage} ${this.props.classes.inviteInputArabicAlter}`, this.props.classes.inviteInputRage)}
              fullWidth={true}
              readOnly
              value={this.state.taskInvitationLink}
              disableUnderline={true}
              endAdornment={
                <InputAdornment position={this.conditionSort(isArabicLang(i18n.language), 'start', 'end')}>
                  <IconButton
                    data-test-id="linkCopyBtn"
                    onClick={this.handleCopyTaskLink}
                    className={this.props.classes.copyBtn}
                  >
                    {SvgIcons.pasteIcon}
                  </IconButton>
                </InputAdornment>
              }
            />
            {this.state.isTaskLinkCopied && this.GenErrorText({ type: "success", message: t('infoLinkCopied') })}
          </Box>

          <Formik
            enableReinitialize
            validateOnBlur={false}
            onSubmit={(dataObject) => {
              this.assignTaskToBoardMember(dataObject)
            }}
            initialValues={{
              assign_member: '',
              assign_member_array: this.state.modalStats.selectedTask?.attributes?.assign_members || []
            }}
            validationSchema={Yup.object().shape({
              assign_member_array: Yup.array()
                .of(Yup.string())
                .min(1, t('selectAtleastOneMemberMsg'))
                .required(t('selectAtleastOneMemberMsg')),
            })}
            render={({
              errors,
              touched,
              handleChange,
              handleSubmit,
              values,
              setFieldValue,
              setFieldError
            }) => {
              return (
                <>
                  <Box>
                    <Typography gutterBottom variant="body1">{t('assignMemberInputLabel')}</Typography>
                    <Autocomplete
                      noOptionsText={t('noMemberFoundTxt')}
                      classes={{ inputRoot: this.conditionSort(isArabicLang(i18n.language), classes.pLeftAutoComp, classes.pRightAutoComp) }}
                      options={this.state.taskMember.map((item: any) => ({ full_name: item.attributes.full_name, account_id: item.id })) || []}
                      getOptionLabel={(option: any) => option.full_name}
                      value={this.state.taskMember.find((item: any) => (item.id === values.assign_member) && item.full_name)}
                      inputValue={values.assign_member}
                      onInputChange={(event: any) => {
                        if (event && event.target && event.target.value)
                          setFieldValue('assign_member', event.target.value)
                        else
                          setFieldValue('assign_member', "")
                      }}
                      onChange={(event, newValue: any) => {
                        if (newValue) {
                          setFieldValue('assign_member', newValue.account_id);
                          this.handleAssignMembers(event, values, newValue, setFieldValue, setFieldError);
                        }
                      }}
                      disableClearable
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          className={this.props.classes.autoFieldStyle}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: this.renderArrowIconAutoComplete(params, i18n),
                            disableUnderline: true,
                            classes: {
                              input: conditionSort(isArabicLang(i18n.language), classes.arabicFontRegular, classes.fontRegular)
                            }
                          }}
                          placeholder={t('assignMemberPlaceholder')}
                        />
                      )}
                      data-test-id="dropdownAssignMember"
                    />
                    {errors.assign_member_array && touched.assign_member_array && this.GenErrorText({ type: "error", message: errors.assign_member_array })}
                  </Box>

                  {this.commonBadge(values, setFieldValue)}

                  <Button data-test-id="inviteSubmitBtn" onClick={() => handleSubmit()} className={this.props.classes.createUpdateBoardBtn}>
                    {t('inviteMemberTxt')}
                  </Button>
                </>
              )
            }} />
        </Box>

      </Box>
    )
  }

  renderArrowIconAutoComplete = (params: any, i18n: any) => (
    <InputAdornment position={this.conditionSort(isArabicLang(i18n.language), 'start', 'end')}>
      {params.InputProps.endAdornment.props.children}
    </InputAdornment>
  )
  // Customizable Area End

  render() {
    const { classes, i18n } = this.props;
    return (
      // Customizable Area Start
      <ThemeProvider theme={conditionSort(isArabicLang(i18n.language), ArabicTheme, theme)}>
        <Dialog
          fullWidth
          data-test-id="modalConatiner"
          open={this.conditionSort(this.state.modalStats.type == "GenInviteMemberInTask", (this.state.modalStats.isOpen && this.state.taskInvitationLink), this.conditionSort(this.state.modalStats.type == "GenEditTask", (this.state.modalStats.isOpen && !isEmptyObject(this.state.modalStats.selectedTask)), this.state.modalStats.isOpen))}
          maxWidth="sm"
          onClose={() => this.handleModalAppearance('', false)}
        >
          <>
            <Loader loading={this.state.isLoading} />
            <Grid className={classes.modalContainer} container direction="column" alignItems="center" >
              <this.ConditionalPanel conditionArray={[this.state.modalStats.type == "GenCreateTask"]}>
                {this.GenCreateTask()}
              </this.ConditionalPanel>

              <this.ConditionalPanel conditionArray={[this.state.modalStats.type == "GenEditTask"]}>
                {this.GenEditTAsk()}
              </this.ConditionalPanel>

              <this.ConditionalPanel conditionArray={[this.state.modalStats.type == "GenViewTaskListSummury"]}>
                {this.viewTaskListSummury()}
              </this.ConditionalPanel>

              <this.ConditionalPanel conditionArray={[this.state.modalStats.type == "GenInviteMemberInTask"]}>
                {this.GenInviteMemberInTask()}
              </this.ConditionalPanel>

            </Grid>
          </>
        </Dialog>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const theme = createTheme({
  palette: {
    error: { main: "#F00" },
    secondary: { main: '#006827' },
    text: { secondary: '#959494' }
  },
  typography: {
    fontFamily: 'HelveticaNeue Medium',
    caption: {
      "fontSize": "16px",
      "fontStyle": "normal",
      "fontWeight": 400,
      '@media (min-width: 250px) and (max-width: 500px)': {
        "fontSize": "15px",
      }
    },
    body2: {
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 400
    },
    body1: {
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 400
    },
    subtitle1: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      '@media (min-width: 600px) and (max-width: 959.95px)': {
        "fontSize": "11px",
      }
    },
    subtitle2: {
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: 500,
      color: "#67C375"
    }
  },
});

export const ArabicTheme = createTheme({
  typography: {
    fontFamily: "GeezaPro Bold",
    body2: {
      fontStyle: 'normal',
      fontSize: '22px',
      fontWeight: 400
    },
    caption: {
      "fontSize": "16px",
      "fontWeight": 400,
      "fontStyle": "normal",
      '@media (min-width: 250px) and (max-width: 500px)': {
        "fontSize": "15px",
      }
    },
    body1: {
      fontStyle: 'normal',
      fontSize: '18px',
      fontWeight: 400
    },
    subtitle2: {
      fontSize: '10px',
      fontWeight: 500,
      fontStyle: 'normal',
      color: "#67C375"
    },
    subtitle1: {
      fontSize: '14px',
      fontWeight: 500,
      fontStyle: 'normal',
      '@media (min-width: 600px) and (max-width: 959.95px)': {
        "fontSize": "11px",
      }
    }
  },
  palette: {
    error: { main: "#F00" },
    text: { secondary: '#959494' },
    secondary: { main: '#006827' }
  },
});

const webStyle: any = (theme: any) => ({
  arabicFontRegular: {
    fontFamily: "GeezaPro Regular",
  },
  reqField: {
    color: "red",
    padding: "4px",
  },
  headerTitleContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginBottom: "15px"
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#FFFF !important"
  },
  headingChip: {
    "borderRadius": "4px",
    "border": "1px solid #67C375",
    "background": "#ECFFEF",
    "height": "18px"
  },
  foucsInput: {
    "border": "1px solid black"
  },
  copyBtn: {
    "borderRadius": "6px",
    "border": "1px solid #67C375",
    "background": "#67C375",
    "width": "54px",
    "height": "50px",
    '&:hover': {
      "background": "#67C375",
      "opacity": "0.8"
    }
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    marginTop: "12px"
  },
  inviteInputRage: {
    "borderRadius": "7px",
    "border": "1px solid #D4D4D4",
    "background": "#FAFAFA",
    "height": "66px",
    fontFamily: 'HelveticaNeue Regular',
    "fontSize": "18px",
    "fontStyle": "normal",
    "fontWeight": "500",
    padding: "5px 24px 5px 24px",
    boxSizing: "border-box",
    [theme.breakpoints.only("sm")]: {
      "height": "40px",
      "fontSize": "18px",
    }
  },
  modalContainer: {
    padding: "25px 25px",
    minHeight: "50vh"
  },
  container: {
    minWidth: 'fit-content',
    [theme.breakpoints.only("md")]: {
      width: "100%",
    },
    width: "90%"
  },
  tableContainter: {
    marginTop: "16px",
    height: "40vh",
  },
  dialogContainer: {
    width: "100%",
  },
  additionIcon: {
    fontSize: "36px",
    color: "#67C375"
  },
  errorIcon: {
    transform: "rotate(180deg)"
  },
  memberCount: {
    color: "#959494",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "20px",
  },
  memberCount1: {
    fontFamily: 'HelveticaNeue Regular',
    "fontSize": "18px",
    "fontStyle": "normal",
    "fontWeight": "500",
    color: "#959494 !important",
  },


  addBoardRage: {
    "width": "160px",
    "height": "149px",
    "borderRadius": "4px",
    "border": "2px dashed #D4D4D4",
    "background": "#FDFDFD",
    display: "grid",
    boxSizing: "border-box",
    placeItems: "center",
  },
  calenderMainContainerStyle: {
    border: "1px solid rgba(166, 166, 166, 0.50)",
    borderRadius: "10px",
    "& .react-calendar__navigation": {
      background: "#67C375",
      color: "#959494",
      height: "80px",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
    },
    "& .react-calendar__navigation__arrow": {
      display: "none",
    },
    "& .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus, .react-calendar__navigation button:disabled":
    {
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
      backgroundColor: "#67C375",
      color: "white",
    },
    "& .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus":
    {
      backgroundColor: "#67C375",
      color: "#fff",
    },
    "& .react-calendar__tile--hasActive,.react-calendar__tile--hasActive:enabled:hover, .react-calendar__tile--hasActive:enabled:focus":
    {
      backgroundColor: "#67C375",
      color: "#fff",
    },
    "& .react-calendar__navigation button": {
      color: "#fff",
    },
    "& .react-calendar__tile--active": {
      background: "#67C375",
      color: "white",
      borderRadius: "50%",
    },
    "& .react-calendar__month-view__weekdays__weekday abbr": {
      textDecoration: "none",
      color: "grey",
    },

  },
  navigationLabelPara: {
    fontWeight: 700,
    fontSize: "18px",
  },
  navigationLabelHeader: {
    fontSize: "18px",
  },
  autoFieldStyle: {
    width: "100%",
    "background": "#FAFAFA",
    "fontSize": "18px",
    "fontStyle": "normal",
    "fontWeight": 500,
    padding: "5px 24px 5px 24px",
    boxSizing: "border-box",
    height: "auto",
  },
  addboardBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
  },
  taskContainer: {
    width: "100%",
    marginTop: "25px",
    flexWrap: "wrap",
    display: "flex",
    gap: "22px"
  },
  headSection: {
    display: 'flex',
    gap: "17px",
    alignItems: "center",
  },
  fontRegular: {
    fontFamily: 'HelveticaNeue Regular',
  },
  fontRegular1: {
    color: "#67C375",
    fontFamily: 'HelveticaNeue Regular',
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
  },
  inputSection: {
    marginTop: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "18px"
  },
  inputRage: {
    "borderRadius": "7px",
    "border": "1px solid #F2F2F2 !important",
    width: "100%",
    "background": "#FAFAFA",
    fontFamily: 'HelveticaNeue Regular',
    "fontSize": "18px",
    "fontStyle": "normal",
    "fontWeight": 400,
    padding: "5px 24px 5px 24px",
    boxSizing: "border-box",
    height: "48px",
  },
  textAreaRage: {
    "borderRadius": "7px",
    "border": "1px solid #F2F2F2",
    fontFamily: 'HelveticaNeue Regular',
    width: "100%",
    "background": "#FAFAFA",
    "fontSize": "18px",
    "fontStyle": "normal",
    "fontWeight": 400,
    padding: "5px 24px 5px 24px",
    boxSizing: "border-box",
    height: "auto",
  },
  createUpdateBoardBtn: {
    marginTop: "20px",
    "borderRadius": "6px",
    "border": "1px solid #67C375",
    "background": "#67C375",
    width: '206px',
    height: '48px',
    textTransform: "none",
    "fontSize": "20px",
    "fontStyle": "normal",
    "fontWeight": 400,
    "color": "#fff",
    '&:hover': {
      "background": "#67C375",
      opacity: "0.8"
    }
  },
  commonError: {
    gap: "3px",
    justifyContent: "flex-start",
    display: "flex",
    alignItems: "center",
  },
  addIcon: {
    background: "#67C375",
    color: "#fff",
    "width": "52px",
    "height": "48px"
  },
  editIcon: {
    background: "#67C375",
    color: "#fff",
    "width": "35px",
    "height": "35px",
    padding: "7px",
    borderRadius: "4px"
  },
  group_icon: {
    width: "48",
    height: "48",
    borderRadius: '50%',
    display: 'flex',
    marginLeft: "15px",
    justifyContent: 'center',
    alignItems: 'center',
    background: "#ECFFEF",
    boxShadow: "none",
    border: "1px solid #67C375"
  },
  nortificationOuter: {
    display: "flex",
    gap: "12px",
    paddingLeft: "13%",
    height: "67px",
    alignItems: "center",
    marginTop: "4px"
  },
  nortification: {
    minWidth: "126px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "62px",
    borderRadius: "4px",
    border: "1px solid #67C375",
    background: "#ECFFEF",
  },
  chipRage: {
    "borderRadius": "24px",
    "border": "1px solid #959494",
    "height": "42px",
    padding: "7px 14px",
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    color: "#959494",
    textTransform: "capitalize",
    margin: "4px"
  },
  chipRageNotAssigned: {
    borderRadius: "11px",
    border: "1px solid #67C375",
    height: "22px",
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    textTransform: "capitalize",
    color: "#67C375",
    minWidth: "108px"
  },
  chipRageAssigned: {
    borderRadius: "11px",
    border: "1px solid #959494",
    height: "22px",
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    textTransform: "capitalize",
    minWidth: "108px"
  },
  chipRagePresentDueDate: {
    borderRadius: "11px",
    border: "1px solid #F00",
    background: "rgba(254, 75, 75, 0.42)",
    height: "22px",
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    color: "#F00",
    textTransform: "capitalize",
    minWidth: "100px"
  },
  chipRageMTCompDueDate: {
    borderRadius: "11px",
    border: "1px solid #67C375",
    background: "#ECFFEF",
    height: "22px",
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    color: "#67C375",
    textTransform: "capitalize",
    minWidth: "100px"
  },
  chipRageView: {
    "borderRadius": "11px",
    border: "1px solid #67C375",
    "height": "18px",
    padding: "8px 14px",
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    textTransform: "capitalize",
    '@media (min-width: 500px) and (max-width: 600px)': {
      padding: "4px 8px",
    },
    '@media (min-width: 470px) and (max-width: 499px)': {
      padding: "2px 6px",
    },
    '@media (min-width: 250px) and (max-width: 469px)': {
      padding: "2px 4px",
      fontSize: "8px"
    }
  },
  cancelChipIcon: {
    "position": "absolute",
    "right": "10px",
    "top": "3px"
  },
  tabStyle: {
    minWidth: "25% !important",
    padding: "4px !important",
    textTransform: "capitalize",
    color: "#959494",
    "fontStyle": "normal",
    "fontWeight": 400,
    fontSize: "18px",
    fontFamily: 'HelveticaNeue Regular',
  },
  tabStyleContainer: {
    marginTop: "30px",
    borderBottom: "1px solid #959494",
    "& .Mui-selected": {
      color: "#000",
      fontFamily: 'HelveticaNeue Medium',
    },
  },
  tabStyleContainerArabicAlter: {
    marginTop: "30px",
    borderBottom: "1px solid #959494",
    "& .Mui-selected": {
      color: "#000",
      fontFamily: 'GeezaPro Bold',
    },
  },
  tableHead: {
    border: "0"
  },
  tableCol: {
    padding: "4px !important",
    border: "0",
    maxWidth: "88px"
  },
  rootSwitch: {
    width: "38px",
    height: "24px",
    padding: "0px",
    margin: theme.spacing(1),
    direction: "ltr"
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(10px)',
      color: "#ECFFEF",
      '& + $track': {
        backgroundColor: '#67C375 !important',
        opacity: 1,
        border: '1px solid #67C375',
      },
      '& $thumb': {
        background: "#FFFF",
      },
    },
    '&$focusVisible $thumb': {
      color: '#FFFF',
      border: '6px solid #67C375',
      background: "#FFFF !important",
    },
  },
  inviteInputArabicAlter: {
    padding: "5px 24px 5px 5px",
  },
  thumb: {
    width: "14px",
    height: "14px",
    marginTop: "1px",
    marginLeft: "3px",
    background: "#67C375",
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: "#ECFFEF !important",
    border: '1px solid #67C375 !important',
    opacity: "1 !important",
    height: "71%",
    width: "75%",
    transition: theme.transitions.create(['background-color', 'border']),
  },
  focusVisible: {},
  checked: {},
  textWrap: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
    display: "inline-block"
  },
  pRightAutoComp: {
    paddingRight: "30px",
    paddingLeft: "0px !important"
  },
  pLeftAutoComp: {
    paddingLeft: "30px",
    paddingRight: "0px !important"
  },
  memberAvatar: {
    marginLeft: "-16px"
  },
  memberAvatarArabic: {
    marginRight: "-16px"
  },
  taskCompletedChip: {
    display: "flex",
    alignItems: "center",
    gap: "4px"
  }
});

const SvgIcons = {
  chipCancelIcon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path d="M21 11C21 5.5 16.5 1 11 1C5.5 1 1 5.5 1 11C1 16.5 5.5 21 11 21C16.5 21 21 16.5 21 11Z" fill="#67C375" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.16998 13.83L13.83 8.17004" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M13.83 13.83L8.16998 8.17004" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>,
  grop_icon:
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="#FFF">
      <path d="M12.4583 5.16667V3.5C12.4583 1.83333 11.8333 1 9.95829 1H7.04163C5.16663 1 4.54163 1.83333 4.54163 3.5V5.16667H12.4583Z" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11.8333 15.1667C11.8333 16.8333 11 17.6667 9.33329 17.6667H7.66663C5.99996 17.6667 5.16663 16.8333 5.16663 15.1667V11.8333H11.8333V15.1667Z" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16 11.8333C16 13.5 15.1667 14.3333 13.5 14.3333H11.8333V11.8333H5.16667V14.3333H3.5C1.83333 14.3333 1 13.5 1 11.8333V7.66666C1 5.99999 1.83333 5.16666 3.5 5.16666H13.5C15.1667 5.16666 16 5.99999 16 7.66666V11.8333Z" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.6667 11.8333H11.6584H4.33337" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.33337 8.5H6.83337" stroke="#67C375" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>,
  verifyIcon: <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="#67C375">
    <path d="M16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5C13.125 16.5 16.5 13.125 16.5 9Z" stroke="#FFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M5.8125 9.00006L7.935 11.1226L12.1875 6.87756" stroke="#FFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>,
  pasteIcon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
    <path d="M12 5C14 5 14 4 14 3C14 1 13 1 12 1H8C7 1 6 1 6 3C6 5 7 5 8 5H12Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14 3.02002C17.33 3.20002 19 4.43002 19 9.00002V15C19 19 18 21 13 21H7C2 21 1 19 1 15V9.00002C1 4.44002 2.67 3.20002 6 3.02002" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
}

export default withStyles(webStyle)(TaskAllocator);
// Customizable Area End
