import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { removeStorageData, getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { isTokenExpired } from "../../../components/src/HelperMethonds"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  WrappedComponent?: any;
  HeaderComponent?: any;
  t: Function;
  i18n: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  popoveropen: boolean;
  anchorEl: any;
  userData: any;
  boardReqData: any;
  collapseReport: boolean;
  collapseRequest: boolean;
  collapseSpecificBoardReq: any;
  tasklistData: any;
  isDrawerOpen: boolean;
  headerLinksArray: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectTaskTrackingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiUserDataCallId: string = "";
  apiBoardReqCallId: string = "";
  apiReqAcceptCallId: string = "";
  apiReqDeclineCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ConnectTOMainLayout)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      popoveropen: false,
      anchorEl: '',
      collapseReport: true,
      collapseRequest: true,
      collapseSpecificBoardReq: {},
      userData: {},
      boardReqData: [],
      tasklistData: {
        data: [],
        message: this.props.t('selectAnyBoardMsg')
      },
      isDrawerOpen: false,
      headerLinksArray: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (message.id === getName(MessageEnum.ConnectTOMainLayout)) {
      let data = message.getData(getName(MessageEnum.ShouldUpdate))
      if (data && data.refresh) {
        this.getUserData()
      }
      let taskListinfo = message.getData(getName(MessageEnum.TaskListData))
      if (taskListinfo && taskListinfo.data && taskListinfo.message) {
        this.setState({ tasklistData: { data: taskListinfo.data, message: taskListinfo.message } })
        setStorageData("tasklistData", JSON.stringify(taskListinfo.data))
      }
      let ToggleSideBar = message.getData(getName(MessageEnum.ToggleSideBar))
      if (ToggleSideBar && ToggleSideBar.data.open) {
        this.handleDrawer(true)
      }
      let headerObj = message.getData(getName(MessageEnum.HeaderLinksData))
      if (headerObj && headerObj.HeaderLinksArray) {
        this.setState({ headerLinksArray: headerObj.HeaderLinksArray })
      }
    }
    this.handleResponseForAcceptBoardReq(from, message)
    this.handleResponseForGetBoardReq(from, message)
    this.handleResponseForUserData(from, message)
    this.handleResponseForDeclineBoardReq(from, message)
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getReportReqCollapseStats()
    this.getBoardRequest();
    this.getUserData();
    this.getTaskDataFromStorage();
  }

  handleDrawer = (stats: boolean) => {
    this.setState({ isDrawerOpen: stats });
  };

  handleRedirectionNewTab = (urlString: string) => {
    window.open(urlString, "_blank");
  };

  handleLogoNavigate = (path: string) => {
    if (window.location.pathname === "/ProjectTaskTracking") {
      return window.location.reload();
    }
    this.props.navigation.navigate(path);
  };

  getTaskDataFromStorage = async () => {
    let data = await getStorageData('tasklistData')
    if (!data) return;
    let parseData = JSON.parse(data)
    this.setState(prevState => ({
      tasklistData: {
        ...prevState.tasklistData,
        data: parseData
      }
    }));
  }

  sendInfoToBoard = (data: any) => {
    const msg = new Message(getName(MessageEnum.ConnectTOBoard))
    msg.addData(getName(MessageEnum.ShouldUpdate), data)
    this.send(msg)
  }

  conditionSort = (condition: any, trueResult: any, falseResult: any) => {
    return condition ? trueResult : falseResult;
  }

  popoverhandleClose = () => {
    this.setState({ popoveropen: false });
  };

  popoverhandleOpen = (event: any) => {
    this.setState({ popoveropen: true, anchorEl: event.currentTarget });
  };

  handleCollapseExpandForReport = () => {
    this.setState((prevState) => ({
      collapseReport: !prevState.collapseReport,
    }), () => setStorageData('collapseReport', JSON.stringify(this.state.collapseReport)));
  }

  getReportReqCollapseStats = async () => {
    let stats = await getStorageData('collapseReport')
    let stats2 = await getStorageData('collapseRequest')
    if (stats) {
      let collapseReport = JSON.parse(stats)
      this.setState({ collapseReport })
    }
    if (stats2) {
      let collapseRequest = JSON.parse(stats2)
      this.setState({ collapseRequest })
    }
  }

  handleCollapseExpandForRequest = () => {
    if (this.state.collapseRequest) {
      this.getBoardRequest()
    }
    this.setState((prevState) => ({
      collapseRequest: !prevState.collapseRequest,
    }), () => setStorageData('collapseRequest', JSON.stringify(this.state.collapseRequest)));
  }

  handleCollapseExpandForSpecificReq = (id: any) => {
    this.setState((prevState) => ({
      ...prevState,
      collapseSpecificBoardReq: {
        ...prevState.collapseSpecificBoardReq,
        [id]: !prevState.collapseSpecificBoardReq[id],
      },
    }));
  }

  handleLogout = () => {
    const keysToRemove = ['authToken', 'collapseReport', 'selectedBoard', 'collapseRequest', 'tasklistData'];
    keysToRemove.forEach(key => removeStorageData(key));
    this.props.navigation.navigate("AccountLogin")
  }


  getUserData = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUserDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBoardRequest = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiBoardReqCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBoardRequestEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  acceptBoardRequest = async (id: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiReqAcceptCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.actionBoardMemberEndpoint}/${id}/accept_request?language=${this.props.i18n.language}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PatchAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  declineBoardRequest = async (id: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiReqDeclineCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.actionBoardMemberEndpoint}/${id}/decline_request`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PatchAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResponseForUserData = (from: string, message: Message) => {
    if (this.apiUserDataCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.data) {
        this.setState({ userData: apiResponse.data.attributes })
      } else {
        isTokenExpired(apiResponse)
      }
    }
  }

  handleResponseForGetBoardReq = (from: string, message: Message) => {
    if (this.apiBoardReqCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.data) {
        this.setState({
          boardReqData: apiResponse.data
        });
        if (Object.keys(this.state.collapseSpecificBoardReq).length === 0) {
          this.setState({
            collapseSpecificBoardReq: apiResponse.data.reduce((obj: any, item: any) => {
              obj[item.id] = true;
              return obj;
            }, {})
          })
        }
      } else {
        this.setState({ boardReqData: [], collapseSpecificBoardReq: {} })
        isTokenExpired(apiResponse)
      }
    }
  }

  handleResponseForAcceptBoardReq = (from: string, message: Message) => {
    if (this.apiReqAcceptCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.message) {
        this.getBoardRequest()
        this.sendInfoToBoard({ refresh: true })
      } else {
        isTokenExpired(apiResponse)
      }
    }
  }

  handleResponseForDeclineBoardReq = (from: string, message: Message) => {
    if (this.apiReqDeclineCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.message) {
        this.getBoardRequest()
      } else {
        isTokenExpired(apiResponse)
      }
    }
  }
  // Customizable Area End
}
