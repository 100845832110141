import { removeStorageData, getStorageData } from "../../framework/src/Utilities";
import React from "react";
import LanguageTxtData from './LanguageTxtData';

export const isTokenExpired = (response: any) => {
    if (response && response.errors && response.errors.length > 0) {
        const expiredTokenError = response.errors.find((error: any) => error.token === 'Token has Expired');
        const invalidTokenError = response.errors.find((error: any) => error.token === 'Invalid token');
        const accountNotActivatedError = response.errors.find((error: any) => error === 'Account is not activated');

        if (expiredTokenError || invalidTokenError || accountNotActivatedError) {
            removeStorageData('authToken')
            window.location.reload()
            return true;
        }
    }
    return false;
}

export const isEmptyObject = (obj: Record<string, any>): boolean => {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
}

export const uiChangeLanguage = (language: string) => {
    document.body.setAttribute('lang', language);
    document.body.setAttribute('dir', language === 'ar' ? 'rtl' : 'ltr');
}

export const ConditionalPanel = (userProps: {
    children: React.ReactNode;
    conditionArray: any;
}): any => {
    const { children, conditionArray } = userProps;
    const condition = conditionArray.every((state: any) => state);
    return condition ? <>{children} </> : null;
};

export const formatAndTranslateErrors = (errors: any, t: Function) => {
    const matchingKeys: any = [];

    errors.forEach((object: any) => {
        const errorString: any = Object.values(object)[0];
        for (const key in LanguageTxtData.englishTrl) {
            if (errorString.toLowerCase() === LanguageTxtData.englishTrl[key].toLowerCase()) {
                matchingKeys.push(key);
                break;
            }
        }
    });

    if (matchingKeys.length > 0) {
        let result = matchingKeys.map((key: any) => `\${t('${key}')}`).join('{\\n}');
        return eval(`\`${result}\``)
    } else {
        return 'Error';
    }
};

export const translateErrors = (customErrorString: string, t: Function) => {
    for (const key in LanguageTxtData.englishTrl) {
        if (customErrorString.toLowerCase() === LanguageTxtData.englishTrl[key].toLowerCase()) {
            let result = `\${t('${key}')}`
            return eval(`\`${result}\``)
        }
    }
    return 'Error';
}

export const conditionSort = (condition: any, trueResult: any, falseResult: any) => {
    return condition ? trueResult : falseResult;
};

export const preventEType = (e: any, regex: any, allowSpace?: boolean) => {
    let allowedKeys = ["ArrowRight", "Tab", "Backspace", "ArrowLeft", "Shift", "Enter"];

    allowedKeys = conditionSort(
        allowSpace,
        [...allowedKeys, " "],
        allowedKeys
    );
    if (
        (e.ctrlKey || e.metaKey) &&
        (e.key === "v" || e.key === "c" || e.key === "a" || e.key === "x")
    ) {
        return false;
    }
    return (
        (allowedKeys.includes(e.key) ? false : !regex.test(e.key)) &&
        e.preventDefault()
    );
};

export const preventPaste = (e: any, regex: any) => {
    const pastedText = e.clipboardData.getData("text/plain");
    if (!regex.test(pastedText)) {
        e.preventDefault();
    }
};

export const isArabicLang = (langauge: string) => {
    return langauge === 'ar'
}

export const handleLoader = (innerProps: any, status: boolean) => {
    innerProps.setState({ isLoading: status })
}

export const sortByCountryCode = (arr: any) =>
    arr
        .filter((item: any) => item.countryCodes[0] !== "599")
        .sort((a: any, b: any) => {
            const codeA = a.countryCodes[0];
            const codeB = b.countryCodes[0];
            const minLength = Math.min(codeA.length, codeB.length);

            for (let i = 0; i < minLength; i++) {
                const digitA = parseInt(codeA.charAt(i), 10);
                const digitB = parseInt(codeB.charAt(i), 10);

                if (digitA !== digitB) {
                    return digitA - digitB;
                }
            }
            return (
                codeA.length - codeB.length || a.country.localeCompare(b.country)
            );
        });