import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  CardContent,
  ButtonGroup,
  Grid,
  Card,
  ButtonBase,
  TextField,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  Popover,
  Tooltip,
  List,
  ListItem,
  ListItemText
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, withStyles, createStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {
  deleteBin,
  edit,
  deleteIcon,
  downArrow,
  userAdd,
  check,
  eye,
  eye_green,
  addCircle,
  printer,
  addCircleGreen,
  calendarLogo,
  greenCalender, printIcon
} from "./assets";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import TaskAllocator from "../../TaskAllocator/src/TaskAllocator";
import { withTranslation } from "react-i18next";
import { conditionSort, isArabicLang } from "../../../components/src/HelperMethonds";
import Loader from "../../../components/src/Loader.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import TaskListController, {
  // Customizable Area Start
  AllTaskListObj,
  TaskListTask,
  CommentList,
  // Customizable Area End
  Props,
  configJSON,
} from "./TaskListController";

class TaskList extends TaskListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNavigation = (styles: Record<string, string>) => {
    const navigationLabel = this.formatNavigationLabel();
    const { t } = this.props
    return (
      <Box className={styles.navigationMainContainer}>
        <Typography className={styles.navigationLabelHeader}>
          {t('selectDueDateTxt')}
        </Typography>
        <Typography className={styles.navigationLabelPara}>
          {navigationLabel}
        </Typography>
      </Box>
    );
  };

  renderDateTime = (styles: Record<string, string>, time: string) => {
    const { i18n } = this.props
    const navigationLabel = this.formatDateTime(time);
    return (
      <Typography style={{ fontFamily: conditionSort(isArabicLang(i18n.language), 'GeezaPro Bold', '') }} className={styles.dateBox}>
        {navigationLabel}
      </Typography>
    );
  };

  renderDatePicker = (isUserProjectManager: boolean, styles: Record<string, string>, curElem: TaskListTask, item: AllTaskListObj, t: Function) => {
    const { calenderCurrentTarget } = this.state;
    const { i18n } = this.props
    const openCalender = Boolean(calenderCurrentTarget);
    return (
      <>
        <Box className={styles.cardHeaderIconBox}>
          <Box className={curElem.attributes.is_task_completed ? styles.dateCompleteConatiner : styles.dateContainer} aria-describedby={curElem.id}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => this.activeDatePicker(event, curElem.id, curElem.attributes.due_date, item.attributes.is_task_list_created_by_current_user, curElem.attributes.is_task_completed)}
            data-testid={"datePicker"}>
            {curElem.attributes.due_date && <Typography style={{ fontFamily: conditionSort(isArabicLang(i18n.language), 'GeezaPro Bold', '') }} className={curElem.attributes.is_task_completed ? styles.cardCapsuleActive : styles.cardCapsule}>
              {this.formatDueDate(curElem.attributes.due_date)}
            </Typography>}
            {!curElem.attributes.due_date && <Typography style={{ fontFamily: conditionSort(isArabicLang(i18n.language), 'GeezaPro Bold', '') }} className={curElem.attributes.is_task_completed ? styles.cardCapsuleActive : styles.cardCapsule}>
              {curElem.attributes.is_task_completed ?
                <img src={greenCalender} alt="calenderLogo" className={styles.calnderImg} />
                :
                <img src={calendarLogo} alt="calenderlogo" />
              }
              {t('dueOnTxt')}
            </Typography>}
          </Box>
          <Popover
            id={this.state.taskCalenderId}
            open={openCalender}
            anchorEl={calenderCurrentTarget}
            onClose={this.handleCloseCalender}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            PaperProps={{
              style: { borderRadius: "10px", boxShadow: "none" }
            }}
            data-testid={"closeCalenderTestID"}
          >
            <Calendar
              minDate={new Date()}
              locale={conditionSort(isArabicLang(i18n.language), 'ar', 'en')}
              onChange={this.handleDateChange}
              value={this.state.selectedDate}
              nextLabel={false}
              next2Label={false}
              prevLabel={false}
              prev2Label={false}
              className={styles.calenderMainContainer}
              navigationLabel={() => this.renderNavigation(styles)}
              data-testid={"calenderTestId"}
            />
          </Popover>
          {
            isUserProjectManager &&
            <ButtonBase disableRipple onClick={() => this.handleViewTask("GenInviteMemberInTask", true, curElem.id, item.id, false, item.attributes.name, isUserProjectManager)} className={styles.cardHeaderIcon}>
              <img src={userAdd} alt="addUSer" />
            </ButtonBase>
          }

          {curElem.attributes.is_task_completed && <Tooltip title={!curElem.attributes.is_current_user_has_assigned_task ? t('taskRoleInfoMsg') : t('taskCompleted')} placement="left">
            <ButtonBase onClick={() => this.handleCompleteTask(curElem.id, curElem.attributes.is_current_user_has_assigned_task)} disableRipple className={styles.cardHeaderIcon}>
              <img src={check} alt="check" />
            </ButtonBase>
          </Tooltip>}

          {!curElem.attributes.is_task_completed && curElem.attributes.is_current_user_has_assigned_task && <ButtonBase className={styles.cardHeaderIcon} onClick={() => this.handleCompleteTask(curElem.id, curElem.attributes.is_current_user_has_assigned_task)} disableRipple data-testid={"completeTaskId"}>
            <CheckCircleOutlineIcon className={styles.completeIcon} />
          </ButtonBase>}

          {!curElem.attributes.is_current_user_has_assigned_task && !isUserProjectManager && !curElem.attributes.is_task_completed &&
            <Tooltip title={t('taskRoleInfoMsg2')} placement="left">
              <ButtonBase className={styles.cardHeaderIcon} disableRipple data-testid={"completeTaskId"}>
                <CheckCircleOutlineIcon className={styles.completeIcon} />
              </ButtonBase>
            </Tooltip>
          }

        </Box>
      </>
    )
  }

  randerTask = (isUserProjectManager: boolean, styles: Record<string, string>, t: Function) => {
    const { i18n } = this.props
    return (
      <>
        {
          this.state.allTaskListData.map((item: AllTaskListObj, index: number) => {
            return (
              <>
                <Grid item xs={12} sm={6} md={4} className={styles.tasklistWrapper}>
                  <Box className={styles.boxContainer}>
                    <Box className={styles.boxHeader}>
                      <Tooltip title={item.attributes.name} placement="top">
                        <Typography style={{ textTransform: "capitalize" }}>{item.attributes.name}</Typography>
                      </Tooltip>
                      <Box className={styles.headerIconBox}>
                        <IconButton className={styles.iconContainer} onClick={() => this.handleViewTask("GenViewTaskListSummury", true, "", item.id, false, item.attributes.name, isUserProjectManager)} data-testid={"viewTaskListTestId"}>
                          <img src={eye} alt="viewTaskList" />
                        </IconButton>
                        <IconButton data-testid="tasklistPrintBtn" disabled={item.attributes.tasks.length == 0} onClick={() => this.activePrintModal(item.attributes.name, item.id)} className={styles.iconContainer}>
                          <img src={printer} alt="printer" />
                        </IconButton>
                        {
                          isUserProjectManager &&
                          <IconButton className={styles.iconContainer} onClick={() => this.handleViewTask("GenCreateTask", true, "", item.id, true, item.attributes.name, isUserProjectManager)} data-testid={"createTaskListTestId"}>
                            <img src={addCircle} alt="addCircle" />
                          </IconButton>
                        }
                      </Box>
                    </Box>
                    {item.attributes.tasks.map((curElem: TaskListTask) => {
                      return (
                        <>
                          <Box className={styles.cardBox}>
                            <Card className={styles.cardContainer}>
                              <Box className={styles.cardHead}>
                                <Tooltip title={curElem.attributes.title} placement="top">
                                  <Typography style={{ fontFamily: conditionSort(isArabicLang(i18n.language), 'GeezaPro Bold', '') }} className={styles.cardType}>{curElem.attributes.title}</Typography>
                                </Tooltip>
                                {this.renderDatePicker(isUserProjectManager, styles, curElem, item, t)}
                              </Box>
                              <CardContent className={styles.cardContent}>
                                <Typography style={{ fontFamily: conditionSort(isArabicLang(i18n.language), 'GeezaPro Bold', ''), wordBreak: "break-word" }} variant="body2" color="textSecondary">
                                  {curElem.attributes.description}
                                </Typography>
                              </CardContent>
                              <Box className={styles.buttonBox}>
                                <ButtonGroup
                                  variant="text"
                                  color="primary"
                                  aria-label="text primary button group"
                                  className={styles.buttonGroup}
                                  size="large"
                                >
                                  <Button className={styles.ButtonBtn} onClick={() => this.handleViewTask("GenEditTask", true, curElem.id, item.id, false, item.attributes.name, isUserProjectManager)} data-testid={"viewTaskId"}><img src={eye_green} /></Button>
                                  {
                                    isUserProjectManager &&
                                    <>
                                      <Button className={styles.ButtonBtn} onClick={() => this.handleViewTask("GenEditTask", true, curElem.id, item.id, true, item.attributes.name, isUserProjectManager)} data-testid={"editTaskId"}><img src={edit} alt="edit" /></Button>
                                      <Button className={styles.ButtonBtn} onClick={() => this.activeDeleteModal(curElem.id, curElem.attributes.title)} data-testid={"deleteModalTestId"}><img src={deleteBin} alt="delete" /></Button>
                                    </>
                                  }
                                </ButtonGroup>
                              </Box>
                            </Card>
                          </Box>
                        </>
                      )
                    })}
                    {
                      item.attributes.tasks.length == 0 && <Box className={styles.taskError}>{t('noTaskValidMsg')}</Box>
                    }
                    <Box className={styles.capsuleContainer}>
                      {this.state.showAllComments && this.state.commentListIndex == index ?
                        <Box className={`${styles.capsule} ${styles.capuseBtn}`} onClick={this.deActivateCommentContainer}>
                          <img src={downArrow} />
                        </Box>
                        :
                        <Box style={{ fontFamily: conditionSort(isArabicLang(i18n.language), 'GeezaPro Bold', '') }} className={styles.capsule} onClick={() => this.activeCommentContainer(item.id, index)} data-testid={"commentSectionTestId"}>
                          {t('viewAllCommentsTxt')}
                        </Box>
                      }
                    </Box>
                  </Box>
                  {this.state.commentListIndex == index &&
                    this.renderCommentContainer(styles)
                  }
                </Grid>
              </>
            )
          })
        }
      </>
    )
  }

  renderCommentContainer = (styles: Record<string, string>) => {
    const { taskListCurrentTarget } = this.state;
    const { t, i18n } = this.props
    const openCalender = Boolean(taskListCurrentTarget);
    return (
      <>
        <Box className={styles.commentContainer} data-testid={"viewCommentSectionTestId"}>
          <Box style={{ fontFamily: conditionSort(isArabicLang(i18n.language), 'GeezaPro Bold', '') }} className={styles.commentHead}>
            <Typography data-testid={"commentsTestId"}>{t('commentsTxt')}</Typography>
          </Box>
          <Box className={styles.commentBoxWrapper}>
            {
              this.state.commentListData.map((item: CommentList, index: number) => {
                return (
                  <>
                    <Box className={styles.commentDetail}>
                      <Box className={styles.commentDetailSection}>
                        <Tooltip title={item.attributes.account.name} placement="top-start">
                          <Typography style={{ fontFamily: conditionSort(isArabicLang(i18n.language), 'GeezaPro Bold', '') }} className={styles.commentAccountName} variant="h6">{item.attributes.account.name}</Typography>
                        </Tooltip>

                        <Tooltip title={item.attributes.task.name} placement="top-start">
                          <Box>{item.attributes.task.name}</Box>
                        </Tooltip>
                      </Box>
                      <Box className={styles.commetTime}>
                        {this.renderDateTime(styles, item.attributes.created_at)}
                      </Box>
                    </Box>
                    <Box className={styles.commentDesc}>
                      {item.attributes.completed_task && <CheckCircleRoundedIcon className={styles.tickMarkIcom} />}
                      <Typography style={{ fontFamily: conditionSort(isArabicLang(i18n.language), 'GeezaPro Bold', ''), lineHeight: '1' }} variant="body2">
                        {item.attributes.comment}
                      </Typography>
                    </Box>
                    <Divider />
                  </>
                )
              })
            }
            <Popover
              open={openCalender}
              anchorEl={taskListCurrentTarget}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box className={styles.selectCommentBox}>
                <Box style={{ fontFamily: conditionSort(isArabicLang(i18n.language), 'GeezaPro Bold', '') }} className={styles.selectCommentHeader} data-testid={"commentHeaderTestId"}>
                  {this.state.taskData.attributes?.tasks.length > 0 ?
                    t('taskSelectionCommentText')
                    :
                    t('emptyCommentValidationMessage')
                  }
                </Box>
                <Box className={styles.selectCommentTask}>
                  {
                    this.state.taskData.attributes?.tasks.map((item: TaskListTask, index: number) => {
                      return (
                        <List component="nav" aria-label="main mailbox folders">
                          <ListItem
                            button
                            selected={this.state.selectedIndex === index}
                            onClick={() => this.handleTaskId(item.id, index)}
                            className={styles.taskList}
                            data-testid={"handleTaskIdTestId"}
                          >
                            <ListItemText primary={item.attributes.title} />
                          </ListItem>
                        </List>
                      )
                    })
                  }
                </Box>
              </Box>
            </Popover>
            <form style={{ margin: "0px" }} onSubmit={this.handleAddComment} data-testid={"addCommentTestId"}>
              <Box>
                <TextField
                  id="createTaskList"
                  variant="outlined"
                  fullWidth
                  inputProps={{ readOnly: Boolean(!this.state.selectedTaskId) }}
                  placeholder={t('addNewCommentPlaceholder')}
                  className={styles.headingInput}
                  onClick={this.handleFocus}
                  onChange={this.addComment}
                  value={this.state.commentData}
                  autoComplete="off"
                  data-testid={"commentInputTestId"}
                />
              </Box>
              <Button data-testid={"commentSubmitBtn"} disabled={this.state.isLoading || this.state.commentData.length == 0} type="submit" className={styles.commentButton} fullWidth>{configJSON.comment}</Button>
            </form>
          </Box>
        </Box>
      </>
    )
  }

  renderCreateTaskList = (styles: Record<string, string>, t: Function) => {
    const { i18n } = this.props
    return (
      <>
        <ButtonBase className={styles.addBoardRage}>
          <Box className={styles.addboardBody} onClick={this.activeCreateTask} data-testid={"createTaskTestId"}>
            <img src={addCircleGreen} alt="createTaskList" />
            <Typography variant="caption">{t('createTaskListTxt')}</Typography>
          </Box>
        </ButtonBase>

        {this.state.activeCreateTask &&
          <form onSubmit={this.handleCreateTaskList} data-testid={"taskListFormTestId"}>
            <Box className={styles.createListContainer}>
              <Typography className={styles.createTitle} data-testid={"createTaskSectionTestId"}>{t('createTaskListTxt')}</Typography>
              <TextField
                id="createTaskList"
                variant="outlined"
                fullWidth
                inputProps={{ maxLength: 40 }}
                placeholder={t('createTaskListPlaceHolder')}
                className={styles.headingInput}
                onChange={this.taskListTitle}
                data-testid={"taskListHeadingTestId"}
                autoComplete="off"
              />
              {this.state.errorNameMessage.type && <Typography style={{ fontFamily: conditionSort(isArabicLang(i18n.language), 'GeezaPro Bold', '') }} className={styles.errorMessage} component="p">{this.state.errorNameMessage.message}</Typography>}
              {this.state.errorBoardMessage.type && <Typography style={{ fontFamily: conditionSort(isArabicLang(i18n.language), 'GeezaPro Bold', '') }} className={styles.errorMessage} component="p">{this.state.errorBoardMessage}</Typography>}
              <Box className={styles.createTasklistButtonContainer}>
                <Button disabled={this.state.isLoading} className={styles.createBtn} type="submit">{t('createBtnTxt')}</Button>
                <Button className={styles.dismissBtn} onClick={this.cancleCreateTask} data-testid={"cancleCreateTaskTestId"}>{t('dismissBtnTxt')}</Button>
              </Box>
            </Box>
          </form>
        }
      </>
    )
  }

  modalConfirmation = (styles: Record<string, string>) => {
    const { t, i18n } = this.props
    return (
      <>
        {this.state.deleteBtnVisible &&
          <>
            <Box className={styles.modalContainer}>
              <Box className={styles.modalImageContainer}>
                <IconButton disableRipple className={styles.iconContainer}>
                  <img src={deleteIcon} />
                </IconButton>
              </Box>
              <DialogContent>

                <DialogContentText id="alert-dialog-description" style={{ fontFamily: conditionSort(isArabicLang(i18n.language), 'GeezaPro Bold', '') }} className={styles.modalDes} data-testid={"deactiveTestID"} component={'div'}
                  dangerouslySetInnerHTML={{ __html: t('taskDeleteConfirmatiomMsg', { selectedTask: this.state.taskName }) }}
                />

              </DialogContent>
              <Box className={styles.buttonContainer}>
                <Button className={`${styles.createBtn} ${styles.modalbtn}`} onClick={this.deleteTask} data-testid={"activateSuccessModalTestId"}>{t('acceptDeleteBtnTxt')}</Button>
                <Button className={`${styles.dismissBtn} ${styles.modalbtn}`} onClick={this.deactiveDeleteModal} data-testid={"deactivateSuccessModalTestId"}>{t('noBtnTxt')}</Button>
              </Box>
            </Box>
          </>
        }

        {this.state.successBtnVisible &&
          <>
            <Box className={styles.modalContainer}>
              <Box className={styles.modalImageContainer}>
                <IconButton disableRipple className={styles.iconContainer}>
                  <img src={deleteIcon} />
                </IconButton>
              </Box>
              <DialogContent>
                <DialogContentText id="alert-dialog-description" style={{ fontFamily: conditionSort(isArabicLang(i18n.language), 'GeezaPro Bold', '') }} className={styles.modalDes} data-testid={"successTestID"}>
                  {t('successDeleteTaskMsg', { selectedTask: this.state.taskName })}
                </DialogContentText>
              </DialogContent>
              <Box className={styles.buttonContainer}>
                <Button className={`${styles.createBtn} ${styles.homeBtn}`} onClick={this.handleHomepage} data-testid={"homeBtnTestId"}>{t('goToHomePageTxt')}</Button>
              </Box>
            </Box>
          </>
        }

        {this.state.printModalVisible && <Box className={styles.modalContainer}>
          <Box className={styles.modalImageContainer}>
            <IconButton disableRipple className={styles.iconContainer}>
              <img src={printIcon} height={80} width={80} />
            </IconButton>
          </Box>
          <DialogContent>

            <DialogContentText id="alert-dialog-description" style={{ fontFamily: conditionSort(isArabicLang(i18n.language), 'GeezaPro Bold', '') }} className={styles.modalDes} data-testid={"deactiveTestID"} component={'div'}
              dangerouslySetInnerHTML={{ __html: t('confirmationPrintPdf', { selectedTask: this.state.taskName }) }}
            />

          </DialogContent>
          <Box className={styles.buttonContainer}>
            <Button className={`${styles.createBtn} ${styles.modalbtn}`} onClick={this.openPdfFile} data-testid={"acceptPrintPDF"}>{t('acceptBtnTxt')}</Button>
            <Button className={`${styles.dismissBtn} ${styles.modalbtn}`} onClick={this.deactivePrintModal} data-testid={"deactivateSuccessModalTestId"}>{t('declineBtnTxt')}</Button>
          </Box>
        </Box>}

      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const styles = this.props.classes;
    const { name, description, is_current_user_project_manager } = this.state.boardData.attributes;
    const { t, i18n } = this.props;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.isLoading} />
        <Box>
          <Typography variant="h3" style={{ fontFamily: conditionSort(isArabicLang(i18n.language), 'GeezaPro Bold', '') }} className={styles.conatinerHeading}>{name}</Typography>
          <Typography style={{ fontFamily: conditionSort(isArabicLang(i18n.language), 'GeezaPro Regular', '') }} variant="h6" component={"span"} className={styles.ContainerSubheading}>{description}</Typography>
        </Box>
        {
          this.state.errorMessage.length > 0 && !is_current_user_project_manager &&
          <Box className={styles.ContainerNotask}>
            <Typography style={{ fontFamily: conditionSort(isArabicLang(i18n.language), 'GeezaPro Bold', '') }} variant="h6" component={"span"}>{t('noTaskListMsg')}</Typography>
          </Box>
        }
        <Grid container spacing={3} className={styles.gridContainer}>
          {
            is_current_user_project_manager &&
            <Grid item>
              {this.renderCreateTaskList(styles, t)}
            </Grid>
          }
          {this.randerTask(is_current_user_project_manager, styles, t)}
        </Grid>
        <Dialog
          PaperProps={{
            style: { borderRadius: "20px" }
          }}
          maxWidth="sm"
          onClose={this.deactiveDeleteModal}
          open={this.state.openDeleteModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={styles.dialogContainer}
          data-testid={"dialogTestId"}
        >
          <>
            <Loader loading={this.state.isLoading} />
            {this.modalConfirmation(styles)}
          </>
        </Dialog>
        {
          this.state.modalStates.isOpen && <TaskAllocator data-testid={"taskSummaryTestId"} navigation={undefined} id={""} modalProps={this.state.modalStates} parentCallback={this.handleCallback} t={t} i18n={i18n} />
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const useStyles = () => createStyles({
  commentAccountName: {
    "whiteSpace": "nowrap",
    "overflow": "hidden",
    "textOverflow": "ellipsis",
    "width": "80%"
  },
  conatinerHeading: {
    paddingBottom: "15px",
    fontSize: "32px",
    fontStyle: "normal",
    fontFamily: "HelveticaNeue Medium",
    fontWeight: 400,
    lineHeight: "1.167"
  },
  ContainerSubheading: {
    marginBottom: "25px",
    fontFamily: "HelveticaNeue Regular",
    fontSize: "20px"
  },
  ContainerNotask: {
    fontFamily: "HelveticaNeue Medium",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 400,
    textAlign: "center"
  },
  gridContainer: {
    paddingTop: "40px"
  },
  boxContainer: {
    position: "relative",
    paddingBottom: "10px",
    border: "1px solid #D4D4D4",
    backgroundColor: "#FAFAFA",
    borderRadius: "4px",
  },
  boxHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 15px",
    backgroundColor: "#67C375",
    color: "#FFFFFF",
    "& p": {
      fontFamily: "HelveticaNeue Bold",
      fontSize: "18px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "55%"
    }
  },
  headerIconBox: {
    display: "flex",
    justifyContent: "space-between"
  },
  iconContainer: {
    padding: "8px",
    color: "#FFF"
  },
  cardBox: {
    width: "100%",
    position: "relative",
    marginBottom: "15px"
  },
  cardContainer: {
    width: "90%",
    margin: "10px auto",
    border: "1px solid rgba(166, 166, 166, 0.50)",
    borderRadius: "10px",
    backgroundColor: "#FFF",
    boxShadow: "unset",
  },
  cardHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 15px",
  },
  cardHeaderIconBox: {
    display: "flex",
    justifyContent: "space-between",
    gap: "12px",
    alignItems: "center",
  },
  cardHeaderIcon: {
    padding: "12px 0px 12px 0px"
  },
  cardType: {
    fontFamily: "HelveticaNeue Medium",
    fontSize: "18px",
    color: "#000000",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "40%",
    textTransform: "capitalize"
  },
  dateContainer: {
    border: "1px solid #FF0000",
    padding: "3px 8px",
    borderRadius: "11px",
    cursor: "pointer"
  },
  dateCompleteConatiner: {
    border: "1px solid #67C375",
    padding: "3px 8px",
    borderRadius: "11px",
    cursor: "pointer"
  },
  cardCapsule: {
    color: "#FF0000",
    fontSize: "10px",
    width: "max-content",
    fontFamily: "HelveticaNeue Medium",
    display: "flex",
    gap: "5px"
  },
  cardCapsuleActive: {
    color: "#67C375",
    fontSize: "10px",
    width: "max-content",
    fontFamily: "HelveticaNeue Medium",
    display: "flex",
    gap: "5px"
  },
  calnderImg: {
    height: "16px"
  },
  cardContent: {
    padding: "5px 16px 0px 16px",
    overflow: "hidden",
    marginBottom: "20px",
    "& p": {
      fontFamily: "HelveticaNeue Medium",
      fontSize: "12px",
      color: "#000000",
      textOverflow: "ellipsis",
      "-webkit-line-clamp": 3,
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
    }
  },
  buttonBox: {
    borderTop: "1px solid #D8D8D8"
  },
  buttonGroup: {
    width: "100%"
  },
  ButtonBtn: {
    width: "100%",
    color: "#67C375",
    borderRight: "1px solid #D8D8D8 !important"
  },
  capsuleContainer: {
    position: "absolute",
    bottom: "-16px",
    left: "-1px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  capsule: {
    width: "120px",
    height: "32px",
    cursor: "pointer",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px",
    border: "1px solid #67C375",
    backgroundColor: "ECFFEF",
    color: "#000000",
    fontFamily: "HelveticaNeue Medium",
    fontSize: "12px",
    borderRadius: "50px"
  },
  capuseBtn: {
    width: "15px !important"
  },
  addBoardRage: {
    width: "200px",
    height: "150px",
    borderRadius: "4px",
    border: "2px dashed #D4D4D4",
    background: "#FDFDFD",
    display: "grid",
    boxSizing: "border-box",
    placeItems: "center",
  },
  addboardBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  createListContainer: {
    width: "283px",
    margin: "30px 0px 0px 30px",
    padding: "20px",
    background: "#EFEFEF",
    borderRadius: "10px",
    border: "1px solid rgba(166, 166, 166, 0.50)",

  },
  buttonContainer: {
    display: "flex",
    gap: "12px",
    direction: "ltr",
    alignItems: "center",
    paddingTop: "15px",
    "@media (min-width: 0px) and (max-width: 450px)": {
      flexDirection: "column"
    }
  },
  createTasklistButtonContainer: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    direction: "ltr",
    paddingTop: "15px"
  },
  createTitle: {
    fontFamily: "HelveticaNeue Bold",
    fontSize: "18px",
  },
  headingInput: {
    backgroundColor: "#FFF",
    borderRadius: "2px",
    marginTop: "10px",
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "lightgray !important"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "lightgray !important"
    },
    "& .MuiOutlinedInput-input": {
      padding: "12.5px 14px"
    }
  },
  createBtn: {
    width: "92px",
    borderRadius: "6px",
    background: "#67C375",
    color: "#FFFFFF",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#67C375"
    }
  },
  modalbtn: {
    width: "150px !important"
  },
  homeBtn: {
    width: "220px !important",
    "& :hover": {
      backgroundColor: "#67C375"
    }
  },
  dismissBtn: {
    width: "92px",
    borderRadius: "6px",
    background: "#ECFFEF",
    border: "1px solid #67C375",
    color: "#67C375",
    textTransform: "capitalize"
  },
  commentContainer: {
    backgroundColor: "#FAFAFA",
    border: "1px solid #D4D4D4",
    padding: "15px",
    marginTop: "3px"
  },
  commentHead: {
    paddingTop: "10px",
    textAlign: "center",
    fontSize: "18px",
    fontFamily: "HelveticaNeue Medium",
  },
  commentDetail: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    paddingTop: "10px",
    "& h6": {
      fontSize: "14px",
      fontFamily: "HelveticaNeue Medium",
    },
    "& body2": {
      fontSize: "12px",
      fontFamily: "HelveticaNeue Medium",
    }
  },
  commentDetailSection: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    width: "57%",
    "& div": {
      fontSize: "10px",
      fontFamily: "HelveticaNeue Medium",
      color: "#67C375",
      padding: "0px 7px",
      backgroundColor: "#ECFFEF",
      border: "1px solid #67C375",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      minWidth: "25px",
      maxWidth: "90px",
      borderRadius: "4px"
    }
  },
  dateBox: {
    color: "#959494",
    fontSize: "12px",
    fontFamily: "HelveticaNeue Medium",
  },
  tickMarkIcom: {
    fontSize: "20px",
    color: "#006827"
  },
  commentDesc: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    color: "#959494",
    fontSize: "12px",
    fontFamily: "HelveticaNeue Medium",
    paddingTop: "10px",
    paddingBottom: "20px",
    wordBreak: "break-word"
  },
  modalImageContainer: {
    padding: "25px 50px",
    marginBottom: "25px",
    border: "1px solid #959494",
    borderRadius: "6px"
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "25px 50px",
    border: "1px solid #979797",
  },
  dialogContainer: {
    borderRadius: "12px !important",
    "& .MuiPaper-rounded": {
      borderRadius: "12px !important"
    },
  },
  modalDes: {
    color: "#000",
    textAlign: "center",
    fontFamily: "HelveticaNeue Medium",
    fontSize: "22px"
  },
  datePickerIn: {
    background: "green"
  },
  calenderMainContainer: {
    borderRadius: "10px",
    border: "1px solid rgba(166, 166, 166, 0.50)",
    "& .react-calendar": {
      width: "300px"
    },
    "& .react-calendar__navigation": {
      background: "#67C375",
      color: "#959494",
      height: "80px",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
    },
    "& .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus, .react-calendar__navigation button:disabled":
    {
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
      backgroundColor: "#67C375",
      color: "white",
    },
    "& .react-calendar__navigation__arrow": {
      display: "none",
    },
    "& .react-calendar__tile--hasActive,.react-calendar__tile--hasActive:enabled:hover, .react-calendar__tile--hasActive:enabled:focus":
    {
      backgroundColor: "#67C375",
      color: "#fff",
    },
    "& .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus":
    {
      backgroundColor: "#67C375",
      color: "#fff",
    },
    "& .react-calendar__month-view__weekdays__weekday abbr": {
      textDecoration: "none",
      color: "grey",
    },
    "& .react-calendar__tile--active": {
      background: "#67C375",
      color: "white",
      borderRadius: "50%",
    },
    "& .react-calendar__navigation button": {
      color: "#fff",
    },
    "& .react-calendar__year-view .react-calendar__tile": {
      padding: "20px 0.5em"
    }
  },
  navigationMainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "10px",
  },
  navigationLabelPara: {
    fontWeight: 700,
    fontSize: "18px",
  },
  navigationLabelHeader: {
    fontSize: "18px",
  },
  errorMessage: {
    color: "red",
    fontFamily: "HelveticaNeue Medium",
    fontSize: "12px"
  },
  taskError: {
    padding: "35px",
    textAlign: "center"
  },
  completeIcon: {
    color: "#D4D4D4",
    fontSize: "large"
  },
  commentButton: {
    display: "none",
    marginTop: "5px",
    background: "#67C375",
    "&.MuiButton-root:hover": {
      backgroundColor: "#67C375"
    }
  },
  selectCommentBox: {
    borderRadius: "4px",
    border: "1px solid rgba(166, 166, 166, 0.50)",
    background: "#FFF",
    width: "250px"
  },
  selectCommentHeader: {
    borderRadius: "4px 4px 0px 0px",
    background: "#67C375",
    padding: "10px",
    fontFamily: "HelveticaNeue Medium",
    color: "#FFF",
    fontSize: "14px",
  },
  selectCommentTask: {
    maxHeight: "200px",
    overflowX: "hidden",
    "& p": {
      padding: "10px",
      fontFamily: "HelveticaNeue Regular",
      fontSize: "14px",
    }
  },
  taskList: {
    "&.MuiListItem-button:hover": {
      background: "#ECFFEF !important"
    },
    "&.MuiListItem-root.Mui-selected": {
      background: "#ECFFEF !important"
    }
  },
  tasklistWrapper: {
    '@media (min-width: 900px)': {
      maxWidth: "340px"
    }
  },
  commentBoxWrapper: {
    maxHeight: "250px",
    overflow: "auto",
    padding: "0 4px",
    "&::-webkit-scrollbar": {
      width: "2px"
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "5px"
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555"
    }
  },
  commetTime: {
    width: "auto",
    maxWidth: "40%"
  }
});

export default withTranslation<TaskList>()(withStyles(useStyles)(TaskList));
export { TaskList }
// Customizable Area End
