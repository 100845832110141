Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.verifyOtpAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.urlUpdateMobile = "/bx_block_customisableuserprofiles/customisableuserprofiles/change_phone_number";
exports.urlGetProfile = "/bx_block_customisableuserprofiles/customisableuserprofiles/get_profile";
exports.urlVerifyOtp = "/account_block/accounts/otp_verification";
exports.urlUpdateProfile = "bx_block_customisableuserprofiles/customisableuserprofiles/update_profile";


exports.profileApiMethodType = "GET";
exports.mobileUpdateApiMethodType = "PUT";

exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";
exports.sendOtp = "Send OTP";
exports.fullNameRegex = /^(?!^\s*[\u0600-\u06FFa-zA-Z,'-]+\s*$)[\u0600-\u06FFa-zA-Z,'-]+(\s[\u0600-\u06FFa-zA-Z,'-]+)*\s*$/
// Customizable Area End