import React from "react";

import {
  // Customizable Area Start
  Box,
  Input,
  InputLabel,
  Typography,
  InputAdornment,
  Dialog,
  Button,
  Fab,
  Select,
  MenuItem,
  Grid,
  withStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider, createStyles } from "@material-ui/core/styles";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  imgProfileAdd,
  verfyMobileIcon,
} from "./assets";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
import codes from "country-calling-code";
import { ConditionalPanel, preventEType, isArabicLang, sortByCountryCode } from "../../../components/src/HelperMethonds";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#f44336",
    },
  },
});
// Customizable Area End

import CustomisableUserProfilesController, {
  Props,
  configJSON,
} from "./CustomisableUserProfilesController.web";

class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDropDown = (containerClass: string, classes: Record<string, string>) => {
    return (
      <Select
        data-test-id="dropdownCode"
        renderValue={(data) => `+${data}`}
        autoWidth
        value={this.state.countryCode}
        onChange={this.handleSelectCountryCode}
        disableUnderline
        className={containerClass}
        disabled={!this.state.isUpdate}
      >
        {sortByCountryCode(codes).map((data: any) => (
          <MenuItem
            value={`${data.countryCodes}`}
            key={data.isoCode2}
          >
            <Box className={classes.optionWrapper}>
              <img
                width={36}
                height={18}
                src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${data.isoCode2}.svg`}
              />
              <Typography style={{ direction: 'ltr' }}>+{data.countryCodes}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    )
  }
  // Customizable Area End

  render() {
    const { classes, t, i18n } = this.props;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <div className={classes.outerBox}>
          <Loader loading={this.state.isLoading} />
          <form onSubmit={this.handleSubmit} className={classes.body}>
            <Box className={classes.profile}>
              <input
                accept="image/*"
                className={classes.inputStyles}
                id="contained-button-file"
                type="file"
                name="userProfile"
                data-test-id="userProfileInput"
                onChange={this.handleInputChange}
              />
              <div className={classes.profileImgOuter}>
                <Fab component="span" className={classes.button}>
                  <img src={this.state.previewURL} alt="P" />
                </Fab>

                <label htmlFor="contained-button-file">
                  <Fab component="span" className={classes.addIcon}>
                    <i>
                      <img src={imgProfileAdd} alt="" />
                    </i>
                  </Fab>
                </label>
              </div>
              {this.state.errors.avatar && (
                <span className={classes.error} style={{color:'#F00',fontWeight:'bold'}}>
                  <ErrorOutlineIcon className="errorIcon" />
                  {this.state.errors.avatar}
                </span>
              )}

              <Typography className={classes.topHeading}>
                {this.state.name}
              </Typography>
              <Typography
                style={{ color: "rgba(27, 27, 27, 0.50)", direction: 'ltr' }}
                className={classes.topHeading}
              >
                +{this.state.country_code} {this.state.mobile_number}
              </Typography>
            </Box>

            <div className={classes.wrapBoxStyle}>
              <Box>
                <InputLabel
                  className={classes.inptLabelTittle}
                  id="service-shop-name"
                >
                  {t('profilelabelTitleText')}
                </InputLabel>
                <Box className={classes.lineStyle} />
              </Box>

              <Box className={classes.boxStyle}>
                <InputLabel
                  className={classes.inptLabelStyle}
                  id="service-shop-name"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {t('namelabelTitleText')}{" "}
                  <span className={classes.validateField}>*</span>
                </InputLabel>
                <Input
                  className={this.conditionSort(isArabicLang(i18n.language), `${classes.mobileStyle} ${classes.mobileStyleArabicAlter}`, classes.mobileStyle)}
                  classes={{ focused: classes.foucsInput }}
                  id="filled-basic"
                  inputProps={{ maxLength: 40 }}
                  onKeyDown={e =>
                    preventEType(e, /^[A-Za-z\s]+$/, true)
                  }
                  onPaste={(e) => this.preventPaste(e, /^[^0-9!@#$%^&*()_+={}[\]:;<>,.?/~`|"'\\-]+$/)}
                  data-test-id="fullNameInput"
                  value={this.state.full_name}
                  name={"full_name"}
                  onChange={this.handleInputChange}
                  disableUnderline={true}
                  style={{
                    borderColor: this.conditionSort(
                      this.state.errors.full_name !== "",
                      "#FF1818",
                      ""
                    ),
                  }}
                  fullWidth={true}
                />
                {this.state.errors.full_name && (
                  <span className={classes.error} style={{color:'#F00',fontWeight:'bold'}}>
                    <ErrorOutlineIcon className="errorIcon" />
                    {this.state.errors.full_name}
                  </span>
                )}
              </Box>

              <Box className={classes.boxStyle}>
                <InputLabel
                  className={classes.inptLabelStyle}
                  id="service-shop-name"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {t('monilelabelTitleText')}
                  <span className={classes.validateField}>*</span>
                </InputLabel>
                <Input
                  id="filled-basic"
                  className={this.conditionSort(isArabicLang(i18n.language), `${classes.mobileStyle} ${classes.mobileStyleArabicAlter}`, classes.mobileStyle)}
                  classes={{ focused: classes.foucsInput }}
                  fullWidth={true}
                  disableUnderline={true}
                  value={this.state.mobile}
                  onChange={this.handleInputChange}
                  name={"mobile"}
                  data-test-id="phoneInput"
                  style={{
                    borderColor: this.conditionSort(
                      this.state.errors.mobile !== "" &&
                      this.state.errors.color === "",
                      "#FF1818",
                      ""
                    ),
                  }}
                  disabled={!this.state.isUpdate}
                  onPaste={(e: any) => this.preventPaste(e, /^\d+$/)}
                  onKeyDown={(e: any) =>
                    preventEType(e, /^[^A-Za-z\W_]+$/)
                  }
                  endAdornment={
                    <InputAdornment position={this.conditionSort(isArabicLang(i18n.language), 'start', 'end')}>
                      <ConditionalPanel conditionArray={[isArabicLang(i18n.language)]}>
                        {this.renderDropDown(`${classes.dropDownRage} ${classes.dropDownRageArabicAlter}`, classes)}
                      </ConditionalPanel>
                      {this.state.isUpdate ? (
                        <Button
                          className={classes.updtBtn}
                          onClick={this.handleSendOtp}
                          type="button"
                          variant="contained"
                          data-test-id="sendOtpBtn"
                        >
                          {t('sendOtpBtnTxt')}
                        </Button>
                      ) : (
                        <div className={classes.verifyIcon}>
                          {this.state.isVerified && (
                            <div className={classes.smallFont}>
                              <img src={verfyMobileIcon} alt="p" />
                              <span>{t('verifiedTxt')}</span>
                            </div>
                          )}
                          <div>
                            <Button
                              onClick={this.handleUpdateStatus}
                              className={classes.updtBtn}
                              type="button"
                              variant="contained"
                              data-test-id="updateBtn"
                            >
                              {t('updatebtnTitle')}
                            </Button>
                          </div>
                        </div>
                      )}
                    </InputAdornment>
                  }
                  startAdornment={
                    <ConditionalPanel conditionArray={[!isArabicLang(i18n.language)]}>
                      {this.renderDropDown(classes.dropDownRage, classes)}
                    </ConditionalPanel>
                  }
                />
                {this.state.errors.mobile && (
                  <span
                    data-test-id="phoneInputError"
                    className={classes.error}
                    style={{
                      color: `${this.state.errors.color}`,
                      fontWeight:'bold'
                    }}
                  >
                    <ErrorOutlineIcon className="errorIcon" />
                    {this.state.errors.mobile}
                  </span>
                )}
                {this.state.isUpdate && this.state.enterOtp && (
                  <div>
                    <div className={classes.opt}>
                      <Typography className={classes.inptLabelStyle}>
                        {t('enterOtpInputTxt')}
                      </Typography>

                      <OtpInput
                        renderInput={(props) => <input {...props} />}
                        data-test-id="otpInput"
                        value={this.state.otpValue}
                        onChange={this.handleChangeOTP}
                        numInputs={5}
                        inputStyle={this.conditionSort(
                          this.state.errors.pin !== "",
                          classes.otpInputError,
                          classes.otpInput
                        )}
                        containerStyle={classes.otpInputContainer}
                        inputType="number"
                      />
                      <button
                        type="button"
                        onClick={this.handleVerfiy}
                        className={classes.verifyBtn}
                        data-test-id="otpVerifyBtn"
                        style={{
                          background: `${this.state.otpValue.length !== 5
                            ? "#B3E1BA"
                            : "#67C375"
                            }`,
                        }}
                        disabled={this.state.otpValue.length !== 5}
                      >
                        {t('verifyNowTxt')}
                      </button>
                    </div>
                    {this.state.errors.pin && (
                      <span className={classes.error} style={{color:'#F00',fontWeight:'bold'}}>
                        <ErrorOutlineIcon className="errorIcon" />
                        {this.state.errors.pin}
                      </span>
                    )}
                  </div>
                )}
              </Box>

              <Box className={classes.boxStyle}>
                <InputLabel
                  className={classes.inptLabelStyle}
                  id="service-shop-name"
                >
                  {t('emaillabelTitleText')}
                </InputLabel>
                <Input
                  className={this.conditionSort(isArabicLang(i18n.language), `${classes.mobileStyle} ${classes.mobileStyleArabicAlter}`, classes.mobileStyle)}
                  classes={{ focused: classes.foucsInput }}
                  id="filled-basic"
                  disableUnderline={true}
                  value={this.state.email}
                  name={"email"}
                  style={{
                    borderColor: this.conditionSort(
                      this.state.errors.email !== "",
                      "#FF1818",
                      ""
                    ),
                  }}
                  data-test-id="emailInput"
                  onChange={this.handleInputChange}
                />
                {this.state.errors.email && (
                  <span className={classes.error} style={{color:'#F00',fontWeight:'bold'}}>
                    <ErrorOutlineIcon className="errorIcon" />
                    {this.state.errors.email}
                  </span>
                )}
              </Box>

              <Box className={classes.boxStyle}>
                <InputLabel
                  className={classes.inptLabelStyle}
                  id="service-shop-name"
                >
                  {t('DestinationlabelTitleText')}
                </InputLabel>
                <Input
                  className={this.conditionSort(isArabicLang(i18n.language), `${classes.mobileStyle} ${classes.mobileStyleArabicAlter}`, classes.mobileStyle)}
                  classes={{ focused: classes.foucsInput }}
                  id="filled-basic"
                  disableUnderline={true}
                  inputProps={{ maxLength: 40 }}
                  data-test-id="designationInput"
                  value={this.state.designation}
                  style={{
                    borderColor: this.conditionSort(
                      this.state.errors.designation !== "",
                      "#FF1818",
                      ""
                    ),
                  }}
                  onChange={this.handleChangeDesignation}
                  name={"designation"}
                />
                {this.state.errors.designation && (
                  <span className={classes.error} style={{color:'#F00',fontWeight:'bold'}}>
                    <ErrorOutlineIcon className="errorIcon" />
                    {this.state.errors.designation}
                  </span>
                )}
              </Box>

              <Box className={classes.boxStyle}>
                <InputLabel
                  className={classes.inptLabelStyle}
                  id="service-shop-name"
                >
                  {t('DepartmentlabelTitleText')}
                </InputLabel>
                <Input
                  className={this.conditionSort(isArabicLang(i18n.language), `${classes.mobileStyle} ${classes.mobileStyleArabicAlter}`, classes.mobileStyle)}
                  classes={{ focused: classes.foucsInput }}
                  id="filled-basic"
                  disableUnderline={true}
                  value={this.state.department}
                  inputProps={{ maxLength: 40 }}
                  name={"department"}
                  data-test-id="departmentInput"
                  style={{
                    borderColor: this.conditionSort(
                      this.state.errors.department !== "",
                      "#FF1818",
                      ""
                    ),
                  }}
                  onChange={this.handleChangeDepartment}
                />
                {this.state.errors.department && (
                  <span className={classes.error} style={{color:'#F00',fontWeight:'bold'}}>
                    <ErrorOutlineIcon className="errorIcon" />
                    {this.state.errors.department}
                  </span>
                )}
              </Box>

              <Box className={classes.btnBox}>
                <Button
                  className={classes.btnStyle}
                  type="submit"
                  data-test-id="submitBtn"
                  style={{
                    background: `${!Object.values(this.state.errors).every(
                      (error) => error === ""
                    ) || this.state.isUpdate
                      ? "#B3E1BA"
                      : "#67C378"
                      }`,
                  }}
                  variant="contained"
                  disabled={
                    !Object.values(this.state.errors).every(
                      (error) => error === ""
                    ) || this.state.isUpdate
                  }
                >
                  <Typography className={classes.sumbitBtnText}>
                    {t('submitbtnTitle')}
                  </Typography>
                </Button>
              </Box>
            </div>
          </form>

          <Dialog fullWidth maxWidth="sm"
            PaperProps={{
              style: { borderRadius: "20px", width: "509px", minHeight: "462px", display: "grid" }
            }}
            data-test-id="modalConatiner" open={this.state.modelOpen} onClose={() => this.handleModal(false)} >
            <Grid
              className={classes.modalContainer}
              container
              justifyContent="center"
              direction="column"
              alignItems="center"
            >
              <CheckCircleIcon className={classes.iconRage} />
              <Typography
                component={"div"}
                dangerouslySetInnerHTML={{ __html: t("awesomeTxt") }}
                className={classes.modalHeader} gutterBottom
              />

              <Typography
                component={"div"}
                dangerouslySetInnerHTML={{ __html: t("profileUpdateInfoTxt") }}
                align="center" className={classes.modalSubText}
              />

              <Link to="/ProjectTaskTracking">
                <Button
                  data-test-id="modalCloseBtn"
                  className={classes.goToBtn}
                  variant="contained"
                >
                  {t('goToDashboardTxt')}
                </Button>
              </Link>
            </Grid>
          </Dialog>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyles = () => createStyles({
  profile: {
    height: "270px",
    display: "flex",
    justifyContent: "center" as const,
    flexDirection: "column" as const,
    alignItems: "center" as const,
  },
  inputStyles: {
    display: "none",
  },
  profileImgOuter: {
    position: "relative" as const,
  },
  lineStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    height: "8px",
  },
  iconRage: {
    color: "#67C375",
    fontSize: "145px",
    "@media (min-width: 900px) and (max-width: 1200px)": {
      fontSize: "126px"
    },
    "@media (min-width: 0px) and (max-width: 900px)": {
      fontSize: "100px"
    }
  },
  goToBtn: {
    borderRadius: "6px",
    marginTop: "35px",
    background: "#67C375",
    height: "48px",
    width: "303px",
    color: "#FFF",
    textTransform: "none" as const,
    fontSize: "20px",
    fontWeight: 400 as 400,
    fontFamily: "HelveticaNeue Medium",
    "&:hover": {
      background: "#67C375",
    },
    "@media (min-width: 900px) and (max-width: 1200px)": {
      width: "280px",
    },
    "@media (min-width: 370px) and (max-width: 900px)": {
      width: "240px"
    },
    "@media (min-width: 0px) and (max-width: 369px)": {
      width: "fit-content"
    }
  },
  modalContainer: {
    padding: "25px 0px",
  },
  modalHeader: {
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 700 as 700,
  },
  modalSubText: {
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    color: "rgba(166, 166, 166, 0.50)",
  },
  addIcon: {
    position: "absolute" as const,
    top: "2px",
    right: "2px",
    display: "flex",
    zIndex: 3,
    flexDirection: "column" as const,
    alignItems: "center" as const,
    width: "36px",
    height: "36px",
    background: "#FFF",
    boxShadow: "none",
    [theme.breakpoints.only("sm")]: {
      width: "33px",
      height: "33px",
    },
    "@media (max-width: 600px)": {
      width: "30px",
      height: "30px",
      top: "1px",
      right: "1px",
    },
    "@media (max-width: 400px)": {
      width: "27px",
      height: "27px",
      top: "-5px",
      left: "56px",
    },
  },
  otpInputContainer: {
    width: "60%",
    justifyContent: "space-around"
  },
  otpInput: {
    width: "18% !important",
    height: "48px",
    borderRadius: "4px",
    border: "1px solid #F2F2F2",
    background: "#FAFAFA",
    fontSize: "24px",
    fontFamily: "HelveticaNeue Medium",
    "&:focus": {
      border: "1px solid black",
      outline: "none" as const,
    },
    [theme.breakpoints.only("md")]: {
      height: "45px",
      fontSize: "20px",
    },
    [theme.breakpoints.only("sm")]: {
      height: "40px",
      fontSize: "18px",
    },
    "@media (max-width: 600px)": {
      height: "30px",
      fontSize: "14px",
    },
    "@media (max-width: 500px)": {
      height: "28px",
      fontSize: "12px",
    },
    "@media (max-width: 390px)": {
      height: "26px",
      fontSize: "10px",
    },
  },
  otpInputError: {
    width: "18% !important",
    height: "56px",
    borderRadius: "4px",
    border: "1px solid #FF1818",
    background: "#FAFAFA",
    fontSize: "24px",
    fontFamily: "HelveticaNeue Medium",
    [theme.breakpoints.only("md")]: {
      height: "45px",
      fontSize: "20px",
    },
    [theme.breakpoints.only("sm")]: {
      height: "40px",
      fontSize: "18px",
    },
    "@media (max-width: 600px)": {
      height: "30px",
      fontSize: "14px",
    },
    "@media (max-width: 500px)": {
      height: "28px",
      fontSize: "12px",
    },
    "@media (max-width: 390px)": {
      height: "26px",
      fontSize: "10px",
    },
  },
  verifyBtn: {
    minWidth: "fit-content",
    height: "48px",
    borderRadius: "5px",
    border: "1px solid #67C375",
    textTransform: "none" as const,
    cursor: "pointer" as const,
    color: "#FFF",
    fontWeight: 500 as 500,
    fontSize: "20px",
    "&:hover": {
      opacity: "0.7",
    },
    [theme.breakpoints.only("sm")]: {
      height: "38px",
      fontSize: "16px",
    },
    "@media (max-width: 600px)": {
      height: "30px",
      fontSize: "14px",
    },
    "@media (max-width: 500px)": {
      height: "28px",
      fontSize: "12px",
    },
    "@media (max-width: 390px)": {
      height: "26px",
      fontSize: "10px",
    },
  },
  opt: {
    display: "flex",
    justifyContent: "space-between" as const,
    width: "100%",
    padding: "4px 4px",
    gap: "8px",
    alignItems: "center" as const
  },
  topHeading: {
    color: "#000",
    textAlign: "center" as const,
    fontSize: "20px",
    fontFamily: "HelveticaNeue Medium",
    fontWeight: "bold" as const,
    lineHeight: "28px",
    textTransform: "capitalize" as const,
    [theme.breakpoints.only("sm")]: {
      fontSize: "18px",
    },
    "@media (max-width: 600px)": {
      fontSize: "14px",
    },
  },
  button: {
    color: "blue[900]",
    width: "132px",
    height: "132px",
    boxShadow: "none",
    zIndex: 2,
    "& img": {
      width: "132px",
      height: "132px",
      borderRadius: "50%",
      objectFit: "fill",
    },
    [theme.breakpoints.only("sm")]: {
      width: "120px",
      height: "120px",
      "& img": {
        width: "120px",
        height: "120px",
      },
    },

    "@media (max-width: 600px)": {
      width: "100px",
      height: "100px",
      "& img": {
        width: "100px",
        height: "100px",
      },
    },
    "@media (max-width: 500px)": {
      width: "90px",
      height: "90px",
      "& img": {
        width: "90px",
        height: "90px",
      },
    },
    "@media (max-width: 400px)": {
      width: "80px",
      height: "80px",
      "& img": {
        width: "80px",
        height: "80px",
      },
    },
  },
  error: {
    display: "flex",
    gap: "8px",
    alignItems: "center" as const,
    minWidth: "300px",
    marginTop: "8px",
    color: "red",
    fontSize: "14px",
    fontFamily: "HelveticaNeue Regular",
    [theme.breakpoints.only("sm")]: {
      fontSize: "13px",
    },
    "@media (max-width: 600px)": {
      fontSize: "12px",
      "& .MuiSvgIcon-root": {
        width: "13px",
        height: "13px",
      },
    },
    "@media (max-width: 500px)": {
      fontSize: "11px",
      gap: "6px",
    },
    "@media (max-width: 400px)": {
      fontSize: "10px",
      gap: "6px",
    },
  },
  foucsInput: {
    border: "1px solid black !important",
  },
  inptLabelTittle: {
    color: "#000",
    fontSize: "26px",
    lineHeight: "28px",
    fontWeight: 500 as 500,
    fontFamily: "HelveticaNeue Medium",
    [theme.breakpoints.only("sm")]: {
      fontSize: "22px",
    },
    "@media (max-width: 600px)": {
      fontSize: "20px",
    },
  },
  inptLabelStyle: {
    display: "flex",
    color: "#000",
    fontStyle: "normal",
    fontSize: "20px",
    fontWeight: 500 as const,
    lineHeight: "28px" /* 140% */,
    whiteSpace: "nowrap" as const,
    fontFamily: "HelveticaNeue Medium",
    [theme.breakpoints.only("md")]: {
      fontSize: "19px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "17px",
    },
    "@media (max-width: 600px)": {
      fontSize: "14px",
    },
  },
  btnBox: {
    display: "flex",
    justifyContent: "center" as const,
    margin: "25px 0",
  },
  boxStyle: {
    display: "flex",
    flexDirection: "column" as const,
    rowGap: "8px",
    "& .Mui-error": {
      margin: "0",
    },
  },
  updtBtn: {
    backgroundColor: "#2283C0",
    color: "#fff",
    height: "46px",
    minWidth: "120px",
    borderRadius: "7px",
    fontSize: "18px",
    fontWeight: 500 as 500,
    textTransform: "none" as const,
    fontFamily: "HelveticaNeue Medium",
    "&:hover": {
      backgroundColor: "#2283C0",
      opacity: "0.7",
    },
    [theme.breakpoints.only("sm")]: {
      height: "38px",
      fontSize: "16px",
      minWidth: "fit-content"
    },
    "@media (max-width: 600px)": {
      height: "30px",
      fontSize: "14px",
      minWidth: "fit-content"
    },
    "@media (max-width: 500px)": {
      height: "28px",
      fontSize: "12px",
      minWidth: "fit-content"
    },
    "@media (max-width: 390px)": {
      height: "26px",
      fontSize: "10px",
      minWidth: "fit-content"
    },
  },
  btnStyle: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "6px",
    padding: "0",
    border: "1px solid #67C375",
    fontFamily: "HelveticaNeue Medium",
    "&:hover": {
      backgroundColor: "#67C378",
      opacity: "0.7",
    },
  },
  wrapBoxStyle: {
    display: "flex",
    flexDirection: "column" as const,
    rowGap: "16px",
  },
  sumbitBtnText: {
    color: "#FFF",
    fontSize: "26px",
    lineHeight: "28px" /* 140% */,
    whiteSpace: "nowrap" as const,
    padding: "18px 28px",
    textTransform: "none" as const,
    fontWeight: 500 as 500,
    [theme.breakpoints.only("sm")]: {
      padding: "16px 26px",
      fontSize: "24px",
    },
    "@media (max-width: 600px)": {
      padding: "14px 24px",
      fontSize: "20px",
    },

    "@media (max-width: 500px)": {
      padding: "12px 22px",
      fontSize: "18px",
    },
    "@media (max-width: 400px)": {
      padding: "10px 20px",
      fontSize: "18px",
    },
  },
  validateField: {
    color: "red",
    padding: "4px",
  },
  verifyIcon: {
    display: "flex",
    alignItems: "center" as const,
    gap: "70px",
    "@media (max-width: 1400px)": {
      gap: "50px",
    },
    "@media (max-width: 1330px)": {
      gap: "50px",
    },
    "@media (max-width: 1270px)": {
      gap: "30px",
    },
    "@media (max-width: 1200px)": {
      gap: "20px",
    },
    "@media (max-width: 430px)": {
      width: "150px",
    },
  },


  outerBox: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center" as const,
    alignItems: "center" as const,
    width: "100%", // Set an appropriate width
  },
  body: {
    position: "absolute" as const,
    top: "80px",
    width: "43%",
    zIndex: 100,
    "@media (max-width: 1200px)": {
      width: "50%",
    },
    "@media (max-width: 1030px)": {
      width: "60%",
    },
    "@media (max-width: 900px)": {
      width: "65%",
    },
    "@media (max-width: 800px)": {
      width: "70%",
    },
    "@media (max-width: 700px)": {
      width: "80%",
    },
    "@media (max-width: 600px)": {
      width: "90%",
      top: "70px",
    },
  },
  smallFont: {
    display: "flex",
    color: "#006827",
    gap: "10px",
    alignItems: "center",
    "& span": {
      fontSize: "14px",
    },
    "@media (max-width: 660px)": {
      gap: "3px",
      "& span": {
        fontSize: "12px",
      },
    },
    "@media (max-width: 450px)": {
      "& span": {
        fontSize: "12px",
      },
      gap: "5px",
    },
    "@media (max-width: 390px)": {
      "& span": {
        fontSize: "10px",
      },
      gap: "4px",
    },
  },
  mobileStyle: {
    display: "flex",
    border: "1px solid #D4D4D4",
    fontSize: "24px",
    borderRadius: "7px",
    fontWeight: 500 as const,
    fontFamily: "HelveticaNeue Medium",
    background: "#FAFAFA",
    padding: "5px 5px 5px 24px",
    boxSizing: "border-box" as const,
    height: "56px",
    [theme.breakpoints.only("sm")]: {
      height: "48px",
      fontSize: "20px",
    },
    "@media (max-width: 600px)": {
      height: "40px",
      fontSize: "18px",
    },
    "@media (max-width: 500px)": {
      height: "36px",
      fontSize: "16px",
    },
    "@media (max-width: 400px)": {
      height: "34px",
      fontSize: "14px",
    },
    "@media (max-width: 350px)": {
      height: "32px",
      fontSize: "12px",
    },
    "& .MuiInputAdornment-positionStart": {
      display: "flex",
      justifyContent: "center",
    },
  },
  mobileStyleArabicAlter: {
    padding: "5px 24px 5px 5px"
  },
  dropDownRage: {
    fontSize: "24px",
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "@media (max-width: 600px)": {
      fontSize: "20px",
    },
    "@media (max-width: 500px)": {
      fontSize: "18px",
    },
    "@media (max-width: 400px)": {
      fontSize: "16px",
    },
    "@media (max-width: 350px)": {
      fontSize: "14px",
    },
  },
  dropDownRageArabicAlter: {
    direction: "ltr",
    marginLeft: '16px'
  },
  optionWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "10px"
  },
  searchBox: {
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 1000px)": {
      justifyContent: "start",
    },
  },
});

const SvgIcons = {
  downIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11C21 16.5228 16.5228 21 11 21Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.46997 9.73999L11 13.26L14.53 9.73999"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  searchIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5 20C5.25329 20 1 15.7467 1 10.5C1 5.25329 5.25329 1 10.5 1C15.7467 1 20 5.25329 20 10.5C20 15.7467 15.7467 20 10.5 20Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21 21L19 19"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  squareDownIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
    >
      <path
        d="M14 23C19 23 21 20.8 21 15.3V8.7C21 3.2 19 1 14 1H8C3 1 1 3.2 1 8.7V15.3C1 20.8 3 23 8 23H14Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.46997 10.5039L11 14.3759L14.53 10.5039"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
};

export default withTranslation<CustomisableUserProfiles>()(withStyles(webStyles)(CustomisableUserProfiles));

export { CustomisableUserProfiles };
// Customizable Area End
