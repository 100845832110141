import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";
import moment from 'moment';
import { getStorageData } from "../../../framework/src/Utilities";
import { isTokenExpired, conditionSort, isArabicLang, handleLoader } from "../../../components/src/HelperMethonds";

interface ModalType {
  type: string;
  isOpen: boolean;
  isEdit: boolean;
  activeTab: number;
  taskId: string;
  boardId: string;
  selectedTask: object;
  taskListId: string;
  taskListName: string;
  isUserManager: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  modalProps: ModalType;
  parentCallback: (isOpen: boolean) => void;
  t: Function;
  i18n: any;
  // Customizable Area End
}
type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  modalStats: any;
  taskMember: any;
  formikGlobalprops: any,
  taskListSummary: any,
  calenderCurrentTarget: HTMLButtonElement | null;
  selectedDate1: Value | Date;
  datePickerVisible: boolean;
  taskInvitationLink: string;
  isTaskLinkCopied: boolean;
  tabStats: number | string;
  isLoading: boolean;
  taskNotificationStatus: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskAllocatorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCreateTaskCallId: string = "";
  apiGetTaskCallId: string = "";
  apiUpdateTaskCallId: string = "";
  apiGetAssignMemberCallId: string = "";
  apiTaskListSummaryCallId: string = "";
  apiTaskInvitationCallId: string = "";
  apiAssignTaskMemberCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      modalStats: this.props.modalProps,
      taskMember: [],
      formikGlobalprops: {},
      taskListSummary: [],
      calenderCurrentTarget: null,
      selectedDate1: new Date(),
      datePickerVisible: false,
      taskInvitationLink: '',
      isTaskLinkCopied: false,
      tabStats: 0,
      isLoading: false,
      taskNotificationStatus: true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    moment.locale('en')
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    this.handleResponseForCreateTask(from, message)
    this.handleResponseForUpdateTask(from, message)
    this.handleAssignMemberResponse(from, message)
    this.handleTaskDataResponse(from, message)
    this.handleTaskListSummary(from, message)
    this.handleResponseForTaskInvitationLink(from, message)
    this.handleResponseForAssignTask(from, message)
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events  


  // Customizable Area Start  
  async componentDidMount() {
    const { type } = this.props.modalProps
    if (type === "GenCreateTask") {
      this.getAssignMembersData();
    }
    else if (type === "GenEditTask") {
      this.getAssignMembersData();
      this.GetTaskData();
    }
    else if (type === "GenViewTaskListSummury") {
      this.getTaskListSummary(0);
    } else if (this.state.modalStats.type === "GenInviteMemberInTask") {
      this.getAssignMembersData();
      this.GetTaskData();
      this.getTaskInvitationLink()
    }
  }

  handleNotificationSwitch = (event: any) => {
    this.setState({ taskNotificationStatus: event.target.checked })
  }

  openPdfFile = (taskId: string) => {
    window.open(`${require('../../../framework/src/config').baseURL}/${configJSON.taskPdfEndPoint}/${taskId}?format=pdf&language=${this.props.i18n.language}`, '_blank');
  }

  formatDueDate = (inputDate: string) => {
    if (!inputDate) return "";
    let enDate = moment(inputDate).locale('en');
    const formattedDateEn = enDate.format('DD MMM, YYYY');
    const arabicDate = `${enDate.format("DD")} ${moment(inputDate).locale('ar').format("MMM")} ${enDate.format("YYYY")}`
    let formattedDate = conditionSort(isArabicLang(this.props.i18n.language), arabicDate, formattedDateEn)
    return formattedDate;
  };

  sendInfoToTasklist = (data: any) => {
    const msg = new Message(getName(MessageEnum.ConnectTOTasklist))
    msg.addData(getName(MessageEnum.ShouldUpdate), data)
    this.send(msg)
  }

  handleCopyTaskLink = () => {
    navigator.clipboard.writeText(this.state.taskInvitationLink)
    this.setState({ isTaskLinkCopied: true })
  }

  processErrorArray = (data: any) => {
    const errorMappings: { [key: string]: { field: string; errorField: string } } = {
      assign_member: { field: 'assign_member', errorField: 'assign_member' },
      description: { field: 'description', errorField: 'description' },
      title: { field: 'tittle', errorField: 'tittle' }
    };

    if (data) {
      Object.keys(data).forEach((key) => {
        if (key in errorMappings) {
          const { field, errorField } = errorMappings[key];
          const errorMessage = data[key][0]; // Assuming there is only one error message         
          this.state.formikGlobalprops.setFieldTouched(field);
          this.state.formikGlobalprops.setFieldError(errorField, errorMessage);
        }
      });
    }
  };

  handleTaskDataResponse = (from: string, message: Message) => {
    if (this.apiGetTaskCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      let apiResponse: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.data) {
        this.setState({ modalStats: { ...this.state.modalStats, selectedTask: apiResponse.data }, selectedDate1: apiResponse.data.attributes.due_date, taskNotificationStatus: apiResponse.data.attributes.whatsapp_notification })
      } else {
        this.processErrorArray(apiResponse.errors)
      }
    }
  }

  handleSubmitForm = (formikProps: any, formikHandleSubmit: any) => {
    this.setState({ formikGlobalprops: formikProps })
    formikHandleSubmit()
  }

  handleResponseForCreateTask = (from: string, message: Message) => {
    if (this.apiCreateTaskCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      handleLoader(this, false)
      if (apiResponse.data) {
        this.handleModalAppearance('', false)
        this.sendInfoToTasklist({ refresh: true })
      } else {
        this.processErrorArray(apiResponse.errors)
        isTokenExpired(apiResponse)
      }
    }
  }

  handleAssignMemberResponse = (from: string, message: Message) => {
    if (this.apiGetAssignMemberCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.data) {
        this.setState({ taskMember: apiResponse.data })
      } else {
        this.processErrorArray(apiResponse.errors)
        isTokenExpired(apiResponse)
      }
    }
  }

  handleTaskListSummary = (from: string, message: Message) => {
    if (this.apiTaskListSummaryCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.data) {
        this.setState({ taskListSummary: [] })
        let processedArray = this.flattenAssignMembers(apiResponse.data)
        this.setState({
          taskListSummary: processedArray, modalStats: {
            ...this.state.modalStats,
            activeTab: this.state.tabStats
          }
        })
      } else {
        this.processErrorArray(apiResponse.errors)
        isTokenExpired(apiResponse)
      }
    }
  }

  handleResponseForUpdateTask = (from: string, message: Message) => {
    if (this.apiUpdateTaskCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.data) {
        this.sendInfoToTasklist({ refresh: true })
        if (this.state.modalStats.type == 'GenInviteMemberInTask') return;
        this.handleModalAppearance('', false)
      } else {
        this.processErrorArray(apiResponse.errors)
        isTokenExpired(apiResponse)
      }
    }
  }

  handleResponseForTaskInvitationLink = (from: string, message: Message) => {
    if (this.apiTaskInvitationCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.task_link) {
        this.setState({ taskInvitationLink: apiResponse.task_link, modalStats: { ...this.state.modalStats, } })
      } else {
        isTokenExpired(apiResponse)
      }
    }
  }

  handleResponseForAssignTask = (from: string, message: Message) => {
    if (this.apiAssignTaskMemberCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.message) {
        this.handleModalAppearance('', false)
      } else {
        isTokenExpired(apiResponse)
      }
    }
  }

  conditionSort = (condition: any, trueResult: any, falseResult: any) => {
    return condition ? trueResult : falseResult;
  }

  handleCloseCalender1 = () => {
    this.setState({ calenderCurrentTarget: null });
  };

  handleDateChange1 = (value: any) => {
    const updatedSelectedTask = { ...this.state.modalStats.selectedTask };
    const date = new Date(value)
    const day = date.getDate().toString().padStart(2, '0');
    const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
    const year = date.getFullYear();
    const formattedDate = `${day} ${month}, ${year}`;
    updatedSelectedTask.attributes.due_date = formattedDate;
    this.setState({
      selectedDate1: value,
      datePickerVisible: false,
      calenderCurrentTarget: null,
      modalStats: { ...this.state.modalStats, selectedTask: updatedSelectedTask }
    },
    )
  };

  activeDatePicker = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    this.setState({
      datePickerVisible: !this.state.datePickerVisible,
      calenderCurrentTarget: event.currentTarget
    });
  };

  handleAssignMembers = (e: any, values: any, newValue: any, setFieldValue: any, setFieldError: any) => {
    const isDuplicate = values.assign_member_array.some(
      (item: any) =>
        item.account_id == parseInt(newValue.account_id) &&
        item.full_name == newValue.full_name
    );
    if (isDuplicate) {
      return;
    } else {
      e.preventDefault();
      setFieldValue('assign_member', '');
      setFieldValue('assign_member_array', [...values.assign_member_array, newValue]);
    }
  };

  handleDelete = (index: number, setFieldValue: any, values: any) => {
    let tempArray = values.assign_member_array
    tempArray.splice(index, 1)
    setFieldValue('assign_member_array', tempArray)
    if (this.state.modalStats.type == "GenInviteMemberInTask") {
      this.updateTask(values, true)
    }
  }

  handleModalAppearance = (type: string, isOpen: boolean) => {
    this.props.parentCallback(isOpen);
    this.setState({ modalStats: { ...this.state.modalStats, type, isOpen, isEdit: false }, isTaskLinkCopied: false, taskInvitationLink: '' });
  }

  ConditionalPanel = (userProps: {
    children: React.ReactNode;
    conditionArray: any;
  }) => {
    const { children, conditionArray } = userProps;
    const condition = conditionArray.every((state: any) => state);
    return condition ? <>{children}</> : null;
  };

  handleActiveTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.getTaskListSummary(newValue);
    this.setState({ tabStats: newValue })
  };

  flattenAssignMembers = (data: any) => data.flatMap((task: any) => {
    const assign_members = task.attributes.assign_members;
    if (assign_members.length == 0) {
      return { ...task, attributes: { ...task.attributes, assign_member: false } };
    }
    return assign_members.map((assign_member: any) => ({ ...task, attributes: { ...task.attributes, assign_member } }));
  }).map(({ attributes: { assign_members, ...attributes }, ...task }: any) => ({ ...task, attributes }));

  getTaskInvitationLink = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiTaskInvitationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/tasks/${this.props.modalProps.taskId}/task_invitation_link`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  assignTaskToBoardMember = async (dataObject: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };

    const accountIds = dataObject.assign_member_array.map((data: any) => data.account_id)

    let httpBody = {
      "data": {
        "attributes": {
          "assign_members": accountIds
        }
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAssignTaskMemberCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/tasks/${this.props.modalProps.taskId}/invite_members_to_task?language=${this.props.i18n.language}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createTask = async (dataObject: any) => {
    handleLoader(this, true)
    const header = {
      "token": await getStorageData("authToken")
    };
    const AssignMemberIds = dataObject.assign_member_array.map((item: any) => item.account_id);

    let formdata = new FormData();
    formdata.append("task[task_list_id]", this.state.modalStats.taskListId);
    formdata.append("task[title]", dataObject.tittle.trim());
    formdata.append("task[description]", dataObject.description.trim());
    AssignMemberIds.forEach((memberId: any) => {
      formdata.append("task[assign_members][]", memberId);
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateTaskCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/tasks?language=${this.props.i18n.language}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateTask = async (dataObject: any, typeDeleteAssignMember?: boolean) => {
    const header = {
      "token": await getStorageData("authToken")
    };
    let dueDate: any = this.state.selectedDate1;
    const AssignMemberIds = dataObject.assign_member_array.map((item: any) => item.account_id);
    let formdata = new FormData();
    formdata.append("task[task_list_id]", this.state.modalStats.taskListId);

    if (!typeDeleteAssignMember) {
      formdata.append("task[title]", dataObject.tittle.trim());
      formdata.append("task[description]", dataObject.description.trim());
      formdata.append("task[whatsapp_notification]", `${this.state.taskNotificationStatus}`);
      if (dueDate) {
        formdata.append("task[due_date]", moment(dueDate).format("DD-MM-YYYY"));
      }
    }
    if (AssignMemberIds.length) {
      AssignMemberIds.forEach((memberId: any) => {
        formdata.append("task[assign_members][]", memberId);
      });
    }
    else {
      formdata.append("task[assign_members][]", "");
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateTaskCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/tasks/${this.state.modalStats.taskId}?language=${this.props.i18n.language}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PATCH'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAssignMembersData = async () => {
    const header = {
      "token": await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAssignMemberCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_tasks/tasks/get_board_members/${this.state.modalStats.boardId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  GetTaskData = async () => {
    const header = {
      "token": await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetTaskCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_tasks/tasks/${this.state.modalStats.taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleEdit = () => {
    this.setState({
      modalStats: {
        ...this.state.modalStats,
        isEdit: true,
      }
    })
  }

  getTaskListSummary = async (newValue: any) => {
    const tabLabels = ["created", "ongoing", "overdue", "completed"];
    const selectedTabLabel = tabLabels[newValue];

    const header = {
      "token": await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiTaskListSummaryCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_tasks/task_lists/${this.state.modalStats.taskListId}/task_list_summary?type=${selectedTabLabel}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  // Customizable Area End
}
